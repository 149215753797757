import { Component } from '@angular/core';
import {
  ActivatedRoute,
  Router,
  Event as NavigationEvent,
  NavigationEnd,
} from '@angular/router';
import { Location } from '@angular/common';
import markerSDK from '@marker.io/browser';
import { UserService } from '../../_services/user.service';
import { filter } from 'rxjs';


@Component({
  selector: 'app-auth0-error-component',
  templateUrl: './auth0-error-component.html',
  styleUrls: ['./auth0-error-component.scss'],
})
export class Auth0ErrorComponent {
  showAuth0ErrorDetails: boolean = true;
  trackingId: any;
  auth0ErrorDescription: any;
  auth0ErrorTitle: any;
  isDialogOpen: any;
  currentRoute1: any;

  constructor(
    public route: ActivatedRoute, 
    public location: Location,
    private userService: UserService,
    public router: Router,

    ) {}

  ngOnInit() {
    // 
    // 
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.currentRoute1 = event.url;
        // 
        console.log('currentRoute1: ', this.currentRoute1);
      });
    // 
    // 
    this.route.queryParams.subscribe((params) => {
      this.trackingId = params.tracking;
      this.auth0ErrorDescription = params.error_description;
      this.auth0ErrorTitle = params.error;
      console.log(
        this.trackingId,
        this.auth0ErrorDescription,
        this.auth0ErrorTitle
      );
      // 
      console.log('Inside auth0 error.....');
      this.userService.closeDialog$.subscribe(dialogState => {
        this.isDialogOpen = dialogState.isOpen;
      });
      console.log('Inside auth0 error.....',this.isDialogOpen); 
      if (this.isDialogOpen) {
        console.log('dialog should be closed now.............');
        
        // Call the userService's setCloseDialog method to close the dialog
        this.userService.setCloseDialog({
          isOpen: false, // Set isOpen to false to indicate closing the dialog
          token: null, 
        });
      }

      // 
    });
  }

  toggleVisibilityErrorDetails = () => {
    console.log('hide');
    this.showAuth0ErrorDetails = !this.showAuth0ErrorDetails;
    !this.showAuth0ErrorDetails
      ? (document.getElementById('auth0-error-detail')!.style.visibility =
          'hidden')
      : (document.getElementById('auth0-error-detail')!.style.visibility =
          'visible');
  };

  backClicked = () => {
    this.location.back();
  };

  /*** marker io trigger */
  triggerMarkerIo = async () => {
    console.log('marker io triggered');
    const widget = await markerSDK.loadWidget({
      project: '6540dd1bc497eb385cc3b459',
    });
    widget.capture('fullscreen');
  };
}
