import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { Globals } from '../_globals/endpoints.global';
import { environment } from 'src/environments/environment';
import { catchError, filter, interval, of, switchMap, takeUntil, throwError, timeoutWith } from 'rxjs';

interface ConversationStatusApiResponse {
  conversation_uid: string;
  conversation_status: string;
  conversation_reply: string;
}
@Injectable({
  providedIn: 'root',
})
export class JssOutputService {
  constructor(private globals: Globals, private http: HttpClient) {}

  //  to get column headers
  getBatchSchema(batch_id: any): Observable<any> {
    return this.http.get('/api/data-explorer/batch/' + batch_id + '/schema');
  }

  // get output data
  batchDetails(
    payload: any,
    batch_id: any,
    page: any,
    size: number = 100
  ): Observable<any> {
    return this.http.post(
      '/api/data-explorer/batch/' + batch_id + '/data',
      payload,
      {
        params: {
          page: page,
          page_size: size,
        },
      }
    );
  }

  // get fill rates for all columns
  getFillRates(batch_id: any): Observable<any> {
    return this.http.get(
      '/api/data-explorer/batch/' + batch_id + '/data-quality'
    );
  }

  /*********** new version **************************** */

  /***** get data table headers******** */

  getDataTableHeaders(org_id: any, batch_id: any): Observable<any> {
    const dataTableHeaderEndpoint = this.globals.urlJoinWithTwoParam(
      'jss_output',
      'data_table_headers',
      org_id,
      batch_id
    );
    return this.http.get(dataTableHeaderEndpoint);
  }

  /******* get data table content ************ */

  getDataTableContent(org_id: any, batch_id: any, obj: any): Observable<any> {
    const dataTableContentEndpoint = this.globals.urlJoinWithTwoParam(
      'jss_output',
      'data_table_content',
      org_id,
      batch_id
    );
    const options = {
      params: new HttpParams()
        .set('page', obj.page)
        .set('page_size', obj.page_size)
        .set('conversation_uid', obj.conversation_uid),
    };
    const reqBody = {
      sort: obj.sort,
      column_filter: obj.column_filter,
    };
    return this.http.post(dataTableContentEndpoint, reqBody, options);
  }

  /**************** rename task ******** */

  renameTask = (org_id: any, batch_id: any, task_name: string) => {
    const renameTaskEndpoint = this.globals.urlJoinWithParam(
      'jss_output',
      'rename_task',
      org_id
    );
    let obj = {
      batch_id: batch_id,
      task_name: task_name,
    };
    return this.http.patch(renameTaskEndpoint, obj);
  };

  /********* get batch details  **************************/

  getBatchDetails = (org_id: any, batch_id: any): Observable<any> => {
    const getBatchDetailsEndpoint = this.globals.urlJoinWithTwoParam(
      'jss_output',
      'get_batch_detail',
      org_id,
      batch_id
    );
    return this.http.get(getBatchDetailsEndpoint);
  };

  /**************** get input data table ***************** */
  getInputDataTable = (
    org_id: any,
    batch_id: any,
    obj: any
  ): Observable<any> => {
    const inputDataTableEndpoint = this.globals.urlJoinWithTwoParam(
      'jss_output',
      'input_data_table',
      org_id,
      batch_id
    );
    const options = {
      params: new HttpParams()
        .set('page', obj.page)
        .set('page_size', obj.page_size),
    };
    return this.http.get(inputDataTableEndpoint, options);
  };

  /************* generate output file  */
  generateOutputFile = (org_id: any, batch_id: any) => {
    const generateOutputFileEndpoint = this.globals.urlJoinWithTwoParam(
      'jss_output',
      'generate_output_file',
      org_id,
      batch_id
    );
    return this.http.post(generateOutputFileEndpoint, {});
  };
  // function component
  getDatasetFunctionResult(org_id: any, batch_id: any, currentActiveCategory: any, activeFunctionName: any, activeObj: any): Observable<any> {
    console.log('inside the service...');
    
    const datasetFunctionEndpoint = this.globals.urlJoinWithParam(
      'function',
      'dataset_function',
      org_id
    );
    const reqBody = {
      batch_id: batch_id,
	    function_category: currentActiveCategory,
	    function_name: activeFunctionName,
	    function_attributes: activeObj

    };
    console.log('reqBody: ', reqBody);
    
    return this.http.post(datasetFunctionEndpoint, reqBody);
  }

  fetchConversationStatus = (org_id: any, conversation_uid: any): Observable<any> => {
    console.log('fetchConversationStatus');

    const getConversationStatusEndpoint = this.globals.urlJoinWithTwoParam(
      'function',
      'conversations_status',
      org_id,
      conversation_uid
    );
    return this.http.get(getConversationStatusEndpoint);
  };
  // polling function to get the conversation status
  pollConversationStatus(org_id: any, conversation_uid: string, pollInterval: number , pollTimeout: number): Observable<ConversationStatusApiResponse> {
    console.log('pollConversationStatus');
    
    return this.startPolling(org_id, conversation_uid, pollInterval, pollTimeout);
  }
  private startPolling(org_id: string, conversation_uid: string, pollInterval: number, pollTimeout: number): Observable<ConversationStatusApiResponse> {
    console.log('startPolling');

    return interval(pollInterval).pipe(
      switchMap(() => this.fetchConversationStatus(org_id, conversation_uid)),
      filter(response => response.conversation_status !== 'in_progress'),
      timeoutWith(pollTimeout, throwError(new Error('Polling timed out'))),
      takeUntil(of(true)),
      catchError(this.handleError)
    );
  }
  private handleError(error: any) {
    console.error('API error:', error);
    return throwError(error);
  }
  // polling function end
  // get all the functions batch column list
  getBatchFunctionColumnList = (
    org_id: any,
    batch_id: any, datasetCategory: string, functionName: string
  ): Observable<any> => {
    const functionBatchColumnListEndpoint = this.globals.urlJoinWithTwoParam(
      'function',
      'function_batch_column_list',
      org_id,
      batch_id
    );
    const options = {
      params: new HttpParams()
        .set('function_category', datasetCategory)
        .set('function_name', functionName),
    };
    return this.http.get(functionBatchColumnListEndpoint, options);
  };
  // end
  // get all the functions batch column list
  getFunctionQueryConversationList = (
    org_id: any,
    batch_id: any,
  ): Observable<any> => {
    const functionQueryConversationEndpoint = this.globals.urlJoinWithTwoParam(
      'function',
      'function_query_conversation_list',
      org_id,
      batch_id
    );
    
    return this.http.get(functionQueryConversationEndpoint);
  };
  // end
  /*** function component end */
}
