import { ActivatedRoute, Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { AccountSettingsService } from 'src/app/_services/account-settings.service';
import { HttpErrorResponse } from '@angular/common/http';
import { UserService } from 'src/app/_services/user.service';
import { ConfirmationDialogComponent } from 'src/app/_dialogs/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.scss'],
})
export class AccountSettingsComponent implements OnInit {
  orgList: any[] = [];
  selectedOrg: any;
  selectedOrgCode: any;
  hasOrgReadPerm: boolean;
  hasTeamReadPerm: boolean;

  constructor(
    public dialog: MatDialog,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private accountSettingsService: AccountSettingsService,
    private router: Router
  ) {
    let msgBox = this.router.getCurrentNavigation()?.extras.state?.showPopup;
    if (msgBox) {
      this.openWorkspaceAddedDialog(msgBox);
    }
  }
  selectedIndex: number = 0;

  ngOnInit(): void {
    this.selectedIndex = this.activatedRoute.snapshot.queryParams.to;
    /**get memberships */
    this.getMemberships();
  }
  /**
   * Checks whether the current user has read permission for organizations.
   * @returns True if the user has read permission for organizations; otherwise, false.
   */
  get hasOrganizationReadPermission(): boolean {
    return this.userService.hasPermission('organisation:read');
  }
  /**
   * Checks whether the current user has read permission for team members.
   * @returns True if the user has read permission for team members; otherwise, false.
   */
  get hasTeamMembersReadPermission(): boolean {
    return this.userService.hasPermission('member:read');
  }
  /**
   * Retrieves and sets the organization memberships for the logged-in user.
   */
  getMemberships = () => {
    this.accountSettingsService.listMemberships().subscribe({
      next: (resp: any) => {
        this.orgList = resp.result;
        // Find the selected organization based on the stored organization code
        this.selectedOrg = this.orgList.find(
          (item) =>
            item.organisation_code ===
            localStorage.getItem('currentOrganizationCode')
        );
        // Set the selected organization code
        this.selectedOrgCode = this.selectedOrg
          ?.organisation_code as HTMLSelectElement;
        this.getPermissions();
      },
      error: (error: HttpErrorResponse) => {
        // console.log(error);
      },
    });
  };
  /**
   * Handles the change event when selecting a different user (organization) from the dropdown.
   * Clears previous user details from local storage, saves the new organization code, and calls
   * the permissions API to refresh the page with the current organization code.
   *
   * @param org - The selected organization from the dropdown.
   */
  onChangeOfUser = (ev: any) => {
    console.log(ev);

    //Onchange of user, clear the  previous user's details
    localStorage.removeItem('currentOrganizationCode');
    localStorage.removeItem('currentUserRole');
    localStorage.removeItem('permissions');
    //read value from selected dropdown, save new  organisation_code to LS
    this.selectedOrgCode = ev.value.organisation_code;
    localStorage.setItem('currentOrganizationCode', this.selectedOrgCode);
    //Call permissions API  to refresh the page with current Organization code
    this.getPermissions('userAccountChanged');
  };

  /**
   * Retrieves permissions for the selected organization code from the account settings service.
   * Saves the obtained permissions and the current user role to local storage and triggers a
   * page reload to reflect the changes.
   */
  getPermissions = (event?: string) => {
    console.log('permissions');
    this.accountSettingsService.getPermissions(this.selectedOrgCode).subscribe({
      next: (resp: any) => {
        localStorage.setItem('permissions', resp.permissions);
        localStorage.setItem('currentUserRole', resp.role);
        if (event == 'userAccountChanged') {
          window.location.reload();
        }
      },
      error: (error: HttpErrorResponse) => {
        // console.log(error);
      },
    });
  };
  /**
   * Opens a confirmation dialog with a message about the addition of a workspace to the user's account.
   * The dialog displays information about switching between workspaces using the dropdown.
   *
   * @param msgBox - The name or identifier of the workspace being added.
   */
  openWorkspaceAddedDialog(msgBox: any) {
    this.dialog.open(ConfirmationDialogComponent, {
      minWidth: '300px',
      data: {
        content: `Workspace ${msgBox} has been added to your account. You can switch between workspaces from the dropdown`,
        from: 'invite',
      },
    });
  }
}
