<div class="top-nav-wrapper">
  <div class="top-nav-content" fxLayout="row" fxLayoutAlign="space-between center">
    <!-- Logo -->
    <div class="top-nav-logo-link">
      <a tabindex="-1" [routerLink]="isNewVersion ? '/dashboard-v1' : '/dashboard'" (click)="redirectToHomepage()">
        <img src="../../../../assets/images/dataX.ai-black.svg" />
      </a>
    </div>
    <ul fxLayout="row" fxLayoutAlign="start center" fxLayoutGap.md="20px" fxLayoutGap.gt-md="45px">
      <!-- nav list -->
      <!-- home -->
      <li [routerLinkActive]="['active-item']">
        <a fxLayout="column" fxLayoutAlign="space-between center">
          <div class="menu-wrapper" fxLayoutGap="2px" fxLayout="row" fxLayoutAlign="space-between center">
            <div class="" fxLayoutAlign="center center" routerLinkActive="active" #home="routerLinkActive">
              <img src="../../../../assets/images/header-svg/dashboard.svg" />
            </div>
            <strong class="menu-text" [routerLink]="isNewVersion ? '/dashboard-v1' : '/dashboard'"  routerLinkActive="active-text">Dashboard</strong>
          </div>
        </a>
      </li>
      <!-- Account Settings-->
      <li [routerLinkActive]="['active-item']">
        <a fxLayout="column" fxLayoutAlign="space-between center">
          <div class="menu-wrapper" fxLayoutGap="2px" fxLayout="row" fxLayoutAlign="space-between center">
            <div class="" fxLayoutAlign="center center" routerLinkActive="active" #accountSettings="routerLinkActive">
              <img src="../../../../assets/images/header-svg/Settings.svg" />
            </div>
            <strong class="menu-text" routerLink="/account-settings" routerLinkActive="active-text">Manage
              Accounts</strong>
          </div>
        </a>
      </li>
      <!-- My Profile -->

      <li [routerLinkActive]="['active-item']">
        <a fxLayout="column" fxLayoutAlign="space-between center">
          <div class="menu-wrapper" fxLayoutGap="2px" fxLayout="row" fxLayoutAlign="space-between center">
            <div class="" fxLayoutAlign="center center" routerLinkActive="active" #accountSettings="routerLinkActive">
              <img src="../../../../assets/images/User.svg" />
            </div>
            <strong class="menu-text" routerLink="/my-profile" routerLinkActive="active-text">My Profile</strong>
          </div>
        </a>
      </li>
      <!-- help -->
      <!-- <li [routerLinkActive]="['active-item']">
        <a fxLayout="column" fxLayoutAlign="space-between center"
          href="https://help.datax.ai/digital-transformation-v3/secrets/N2Y5aGRuY2hybmplY2xqb3" target="_blank">
          <div class="menu-wrapper" fxLayoutGap="2px" fxLayout="row" fxLayoutAlign="space-between center">
            <div class="" fxLayoutAlign="center center" routerLinkActive="active" #accountSettings="routerLinkActive">
              <img src="../../../../assets/images/header-svg/Call Chat Rounded.svg" />
            </div>
            <strong class="menu-text" routerLinkActive="active-text">Need Help?</strong>
          </div>
        </a>
      </li> -->
      <!-- Notifications-->
      <!-- <li (click)="openNotificationsDialog()">
        <a fxLayout="column" fxLayoutAlign="space-between center" matTooltip="View and manage notifications"
          matTooltipPosition="above">
          <div class="menu-wrapper" fxLayout="row" fxLayoutAlign="space-between center">
            <div class="menu-title" fxLayoutAlign="center center" matBadge="{{unreadNotificationCount}}"
              [matBadgeHidden]="unreadNotificationCount == 0" matBadgePosition="above" matBadgeColor="accent">
              <img [src]="
              currentUrl=='/notifications'
              ? '../../../../assets/images/notification-active.svg'
                : '../../../../assets/images/notification.svg'
                " />
            </div>
            <span class="menu-text" [ngClass]="{'notifications-active': currentUrl == '/notifications'}"
              routerLinkActive="active-text">Notifications</span>
          </div>
        </a>
      </li> -->
    </ul>

    <div fxLayout="row" class="notification-container">
      <!-- Action Button -->
      <div class="menu-drop" fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="space-between center"
        [style.cursor]="emailVerify ? 'pointer' : 'default'">
        <div fxLayout="row">
          <img src="../../../../assets/images/header-svg/question-circle.svg" [matMenuTriggerFor]="helpMenu" />
          <mat-menu #helpMenu="matMenu" xPosition="before">
            <button mat-menu-item (click)="openHelpCenter()">Help center</button>
            <button mat-menu-item (click)="triggerMarkerIo()"><span fxlayout="row" fxLayoutAlign="space-between center"
                fxLayoutGap="60px"><span>Report an issue</span> <span *ngIf="osName == 'Macintosh'"
                  class="command">⌘+U</span> <span *ngIf="osName != 'Macintosh'" class="command">Alt+U</span></span>
            </button>
          </mat-menu>
        </div>
        <img *ngIf="inviteCount > 0" src="../../../../assets/images/User Active.svg"
          (click)="emailVerify ? openNotificationsDialog('invite') : null"
          [style.cursor]="emailVerify ? 'pointer' : 'none'" />
        <img *ngIf="inviteCount == 0" src="../../../../assets/images/header-svg/User.svg"
          (click)="emailVerify ? openNotificationsDialog('invite') : null"
          [style.cursor]="emailVerify ? 'pointer' : 'default'" />
        <img *ngIf="inboxCount > 0" src="../../../../assets/images/header-svg/Inbox Active.svg"
          (click)="emailVerify ? openNotificationsDialog('inbox') : null"
          [style.cursor]="emailVerify ? 'pointer' : 'default'" />
        <img *ngIf="inboxCount == 0" src="../../../../assets/images/header-svg/Inbox.svg"
          (click)="emailVerify ? openNotificationsDialog('inbox') : null"
          [style.cursor]="emailVerify ? 'pointer' : 'default'" />
        <img *ngIf="notificationCount > 0" src="../../../../assets/images/header-svg/Bell Active.svg"
          (click)="emailVerify ? openNotificationsDialog('notification') : null"
          [style.cursor]="emailVerify ? 'pointer' : 'default'" />
        <img *ngIf="notificationCount == 0" src="../../../../assets/images/header-svg/Bell.svg"
          (click)="emailVerify ? openNotificationsDialog('notification') : null"
          [style.cursor]="emailVerify ? 'pointer' : 'default'" />
      </div>
      <!-- <img src="../../../../assets/images/header-svg/question-circle.svg" (click)="triggerMarkerIo()" /> -->
      <div [matMenuTriggerFor]="menu" class="user-stamp-wrap" fxLayout="row" fxLayoutAlign="space-between center"
        (click)="onClickOfMatMenu()">
        <div fxLayout="row" class="menu-notification" fxLayoutGap="5px" fxLayoutAlign="center center">
          <!-- User Icon -->

          <div class="avatar" id="user-avatar" fxLayout="column" fxLayoutAlign="center center"
            style="background-color: rgb(36, 165, 206)">
            <span>{{ userInitials }}</span>
          </div>
          <mat-icon>expand_more</mat-icon>
        </div>
      </div>
    </div>

    <!-- user-menu -->
    <mat-menu #menu="matMenu" [overlapTrigger]="false" [xPosition]="'before'" [yPosition]="'below'">
      <div style="min-width:272px">
        <div class="user-details" *ngFor="let org of orgList">
          <div *ngIf="orgList.length" (click)="onChangeOfUser(org)" class="list" fxLayoutGap="20px" [ngStyle]="{
            color:
              org.organisation_code == currentUserOrgCode
                ? '#1B263B'
                : '#94A3B8'
          }">
            <span fxFlex="90%" style="word-wrap: break-word" matTooltipPosition="above"
              matTooltip={{org.organisation_name}}>{{ org.organisation_name | truncate:23 }}
            </span>

            <span fxFlex="10%" *ngIf="org.organisation_code == currentUserOrgCode">
              <mat-icon>check</mat-icon>
            </span>
          </div>
        </div>
        <div *ngIf="orgList.length" class="menu-dropdown">
          <span mat-menu-item (click)="logout()">Logout</span>
        </div>
        <mat-spinner style="margin:auto; margin-top: 10px;" mode="indeterminate" [diameter]="26"
          *ngIf="!orgList.length"></mat-spinner>
      </div>
    </mat-menu>
  </div>
</div>
