import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Globals } from '../_globals/endpoints.global';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  getNotificationCountFlag = new BehaviorSubject<boolean>(true);
  constructor(
    private globals: Globals,
    private http: HttpClient,
    private router: Router
  ) {}

  getUpdatedNotificationCount = (message: boolean) => {
    this.getNotificationCountFlag.next(message);
  };

  /*** get notification list */
  getNotifications(type: string, page: number, size: number, org_code: any) {
    // console.log(type);
    if (type === 'inbox' || type === 'invite') {
      var options = {
        params: new HttpParams()
          .set('type', type)
          .set('page', page)
          .set('page_size', size),
      };
    } else {
      var options = {
        params: new HttpParams()
          .set('type', type)
          .set('page', page)
          .set('page_size', size)
          .set('org_code', org_code),
      };
    }

    const notificationsListEndpoint = this.globals.urlJoin('messages', 'list');
    return this.http.get(notificationsListEndpoint, options);
  }

  getNotificationsCount = (orgCode: any) => {
    const notificationsCountEndpoint = this.globals.urlJoinWithParam(
      'messages',
      'count',
      orgCode
    );
    return this.http.get(notificationsCountEndpoint);
  };

  /*** mark all as read */
  markAllasRead = (type: any): Observable<any> => {
    const markAllasReadEndpoint = this.globals.urlJoin(
      'messages',
      'mark_all_as_read'
    );
    return this.http.post(markAllasReadEndpoint, { type: type });
  };

  /*** mark as read */
  markAsRead = (id: any): Observable<any> => {
    const markAsReadEndpoint = this.globals.urlJoinWithParam(
      'messages',
      'mark_as_read',
      id
    );
    return this.http.post(markAsReadEndpoint, id);
  };

  /*** mark as read */
  markAsUnRead = (id: any): Observable<any> => {
    const markAsUnReadEndpoint = this.globals.urlJoinWithParam(
      'messages',
      'mark_as_unread',
      id
    );
    return this.http.post(markAsUnReadEndpoint, id);
  };

  actionResponse: any;

  /*** accept/reject new add request */
  actionButton = (action: any, type: any): Observable<any> => {
    if (action.method === 'PUT') {
      return this.http.put(action.redirect_uri, '');
    }
    if (action.method === 'GET') {
      return this.http.get(action.redirect_uri);
    }
    // Adding a default return statement or throwing an error
    throw new Error(`Unsupported method: ${action.method}`);
  };

  /*** send reminder to supplier/distributor to sign up  */
  sendReminder = (email: any): Observable<any> => {
    const sendReminderEndpoint = this.globals.urlJoin(
      'notifications',
      'send_reminder'
    );
    return this.http.post(sendReminderEndpoint, { email: email });
  };
}
