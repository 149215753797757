<div class="page-wrapper" fxLayout="column" fxLayoutAlign="center center">
  <!-- center card -->
  <div
    class="user-account-center"
    fxLayout="column"
    fxLayoutAlign="start center"
  >
    <div class="datax-logo" fxLayout="row" fxLayoutAlign="center center">
      <img
        class="logo-img"
        src="../../../../assets/images/dataX.ai-black.svg"
      />
    </div>
    <div
      class="user-account-card"
      fxLayout="column"
      fxLayoutAlign="center space-between"
      fxLayoutGap="20px"
    >
      <form class="user-form" [formGroup]="signUpForm" (ngSubmit)="onSubmit()">
        <div fxLayout="column">
          <span class="field-label-required">Email (Official)</span>
          <mat-form-field appearance="outline">
            <span fxLayout="row" fxLayoutGap="5px">
              <img
                [src]="
                  signUpFormControl.email.value
                    ? '../../../../assets/images/MailRead.svg'
                    : '../../../../assets/images/mail.svg'
                "
                height="18px" width="18px"
              />
              <input
                matInput
                formControlName="email"
                (focusout)="handleFocusOutAndResize($event)"
                [readonly]="emailId"
                placeholder="example@abc.com"
                required
              />
            </span>

            <mat-error *ngIf="signUpFormControl.email.errors?.['required']"
              >Email is a required field</mat-error
            >
            <mat-error *ngIf="signUpFormControl.email.errors?.['pattern']"
              >Please enter a valid email.</mat-error
            >
            <mat-error *ngIf="signUpFormControl.email.errors?.['maxlength']"
              >Please ensure that the email is within 150 characters.</mat-error
            >
          </mat-form-field>
        </div>

        <!-- enter password -->
        <div fxLayout="column">
          <span fxLayout="row" fxLayoutGap="3px" fxLayoutAlign="start center"
            ><span class="field-label-required">Create Password</span>
          </span>
          <mat-form-field appearance="outline" class="password-form-field">
            <span fxLayout="row" fxLayoutGap="5px">
              <img
                class="lock-icon"
                [src]="
                  signUpFormControl.password.value
                    ? '../../../../assets/images/lock-active.svg'
                    : '../../../../assets/images/lock.svg'
                "
              />
              <input
                placeholder="Password"
                matInput
                formControlName="password"
                [type]="showPassword1 ? 'text' : 'password'"
              />

              <img
                src="../../../../assets/images/eye.svg"
                (click)="password1()"
                *ngIf="signUpFormControl.password.value != '' && !showPassword1"
              />
              <img
                src="../../../../assets/images/eye-closed.svg"
                (click)="password1()"
                *ngIf="showPassword1"
              />
            </span>

            <mat-error *ngIf="signUpFormControl.password.errors?.['required']"
              >Password is a required field</mat-error
            >
            <mat-error *ngIf="signUpFormControl.password.errors?.['maxlength']"
              >Please ensure that the password is within 35 characters.
            </mat-error>
          </mat-form-field>
          <div class="password-validation-text" fxLayout-="column">
            <span
              [ngClass]="imageDependentText('password', 'minlength')"
              fxLayout="row"
              fxLayoutAlign="start center"
              fxLayoutGap="4px"
            >
              <img
                *ngIf="!signUpForm.get('password')?.value"
                src="../../../../assets/images/tick.svg"
              /><img
                *ngIf="
                  !signUpForm.get('password')?.hasError('minlength') &&
                  signUpForm.get('password')?.value
                "
                src="../../../.././assets/images/success-tick.svg"
              /><img
                *ngIf="signUpForm.get('password')?.hasError('minlength')"
                src="../../../.././assets/images/danger-tick.svg"
              />
              <span>Use at least eight characters.</span>
            </span>

            <span
              [ngClass]="imageDependentText('password', 'invalidPasswordChar')"
              fxLayout="row"
              fxLayoutGap="4px"
              fxLayoutAlign="start center"
              ><img
                src="../../../../assets/images/tick.svg"
                *ngIf="!signUpForm.get('password')?.value"
              /><img
                *ngIf="
                  !signUpForm
                    .get('password')
                    ?.hasError('invalidPasswordChar') &&
                  signUpForm.get('password')?.value
                "
                src="../../../.././assets/images/success-tick.svg"
              />
              <img
                *ngIf="
                  signUpForm?.get('password')?.hasError('invalidPasswordChar')
                "
                src="../../../../assets/images/danger-tick.svg"
              />
              <span>A mix of upper case and lower case letters.</span>
            </span>

            <span
              [ngClass]="imageDependentText('password', 'invalidPasswordNum')"
              fxLayout="row"
              fxLayoutGap="4px"
              fxLayoutAlign="start center"
              ><img
                src="../../../../assets/images/tick.svg"
                *ngIf="!signUpForm.get('password')?.value"
              /><img
                *ngIf="
                  !signUpForm.get('password')?.hasError('invalidPasswordNum') &&
                  signUpForm.get('password')?.value
                "
                src="../../../.././assets/images/success-tick.svg"
              /><img
                *ngIf="
                  signUpForm?.get('password')?.hasError('invalidPasswordNum')
                "
                src="../../../.././assets/images/danger-tick.svg"
              />
              <span>One or more numbers.</span>
            </span>

            <span
              [ngClass]="
                imageDependentText('password', 'invalidPasswordSpecialChar')
              "
              fxLayout="row"
              fxLayoutGap="4px"
              fxLayoutAlign="start start"
              class="password-validation-specialchar"
              ><img
                src="../../../../assets/images/tick.svg"
                *ngIf="!signUpForm.get('password')?.value"
              /><img
                *ngIf="
                  !signUpForm
                    .get('password')
                    ?.hasError('invalidPasswordSpecialChar') &&
                  signUpForm.get('password')?.value
                "
                src="../../../.././assets/images/success-tick.svg"
              /><img
                *ngIf="
                  signUpForm
                    ?.get('password')
                    ?.hasError('invalidPasswordSpecialChar')
                "
                src="../../../.././assets/images/danger-tick.svg"
              />
              <span
                >At least one of the following special characters
                !"%&'()*+,-./:;=#>?@[$\]^_`{{ "{|}" }}&lt;~</span
              >
            </span>
          </div>
        </div>

        <!-- re-enter password -->
        <div class="re-enter-pwd-field" fxLayout="column">
          <span fxLayout="row" fxLayoutGap="3px" fxLayoutAlign="start center"
            ><span class="field-label-required">Re-Enter Password</span>
          </span>
          <mat-form-field appearance="outline">
            <span fxLayout="row" fxLayoutGap="5px">
              <img
                [src]="
                  signUpFormControl.confirm_password.value
                    ? '../../../../assets/images/lock-active.svg'
                    : '../../../../assets/images/lock.svg'
                "
                class="lock-icon"
              />
              <input
                matInput
                formControlName="confirm_password"
                placeholder="Password"
                [type]="showPassword2 ? 'text' : 'password'"
              />
              <img
                src="../../../../assets/images/eye.svg"
                (click)="password2()"
                *ngIf="
                  signUpFormControl.confirm_password.value != '' &&
                  !showPassword2
                "
              />
              <img
                src="../../../../assets/images/eye-closed.svg"
                (click)="password2()"
                *ngIf="showPassword2"
              />
            </span>

            <mat-error
              *ngIf="signUpFormControl.confirm_password.errors?.['required']"
              >Please re-enter password to proceed</mat-error
            >
            <mat-error
              *ngIf="signUpFormControl.confirm_password.errors?.['passwordMismatch']"
              >Please enter correct password.
            </mat-error>
          </mat-form-field>
        </div>

        <!-- Signup Agreement -->
        <div class="agreement-checkbox-container">
          <mat-checkbox #confirmed class="sign-up-btn">
            <span class="message">By signing up, you agree to the </span>
            <a
              href="/static/terms-of-service.pdf"
              target="_blank"
              class="app-primary-link"
              >Terms of Service</a
            >
          </mat-checkbox>
        </div>

        <button
          mat-raised-button
          [ngClass]="{
            'disabled-btn': signUpForm.invalid || !confirmed?.checked,
            'btn-enabled': signUpForm.valid && confirmed?.checked
        }"
          type="submit"
          [disabled]="signUpForm.invalid || !confirmed?.checked"
        >
          Sign up
        </button>
      </form>
    </div>
    <div class="sign-in-link-wrapper">
      <span>Already have an account? </span>
      <span class="app-primary-link" (click)="goToSigninPage()">Sign in</span>
    </div>
  </div>
</div>
