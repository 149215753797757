import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { AuthGuard } from './_guards/auth.guard';
import { SignupComponent } from './_components/user-access/signup/signup.component';
import { LoadingComponent } from './_components/loading/loading.component';
import { HomeComponent } from './_components/dashboard/home/home.component';
import { AccountSettingsComponent } from './_components/account-settings/account-settings.component';
import { HeaderRowsComponent } from './_components/dashboard/header-rows/header-rows.component';
import { UploadInputFileComponent } from './_components/dashboard/upload-input-file/upload-input-file.component';
import { UploadProgressComponent } from './_components/dashboard/upload-progress/upload-progress.component';
import { ViewOutputNewComponent } from './_components/dashboard/view-output-new/view-output-new.component';
import { ViewOutputComponent } from './_components/dashboard/view-output/view-output.component';
import { MyProfileComponent } from './_components/my-profile/my-profile.component';
import { InterimOutputComponent } from './_components/dashboard/interim-output/interim-output.component';
import { InviteRequestComponent } from './_components/account-settings/invite-request/invite-request.component';
import { NotificationsComponent } from './_components/notifications/notifications.component';
import { InboxComponent } from './_components/inbox/inbox.component';
import { ShowInvitesComponent } from './_components/show-invites/show-invites.component';
import { Auth0ErrorComponent } from './_components/auth0-error/auth0-error-component';
import { ErrorRouteGuardGuard } from './_guards/error-route-guard.guard';
import { ErrorRouteDeactivateGuard } from './_guards/error-route-deactivate.guard';
import { HomeUpdateV1Component } from './_components/dashboard/home-update-v1/home-update-v1.component';

const routes: Routes = [
  {
    path: 'account-settings',
    component: AccountSettingsComponent,
    title: 'Account Settings',
    canActivate: [AuthGuard],
  },
  {
    path: 'loading',
    component: LoadingComponent,
  },
  {
    path: 'signup',
    component: SignupComponent,
    title: 'Sign up',
  }, 
  {
    path: 'dashboard',
    component: HomeComponent,
    title: 'Dashboard',
    canActivate: [AuthGuard],
  }
  ,
  {
    path: 'dashboard-v1',
    component: HomeUpdateV1Component,
    title: 'Dashboard-v1',
    canActivate: [AuthGuard],
  },
  {
    path: 'dashboard/upload-input-file',
    component: UploadInputFileComponent,
    title: 'Upload Input File',
    canActivate: [AuthGuard],
  },
  {
    path: 'select-header',
    component: HeaderRowsComponent,
    title: 'Select header',
    canActivate: [AuthGuard],
  },
  {
    path: 'dashboard/upload-progress',
    component: UploadProgressComponent,
    title: 'Progress',
    canActivate: [AuthGuard],
  },
  {
    path: 'dashboard/view-output',
    component: ViewOutputComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'dashboard/view-output-new',
    component: ViewOutputNewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'dashboard/interim-output',
    component: InterimOutputComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'account-settings',
    component: AccountSettingsComponent,
    title: 'Settings',
    canActivate: [AuthGuard],
  },

  {
    path: 'my-profile',
    component: MyProfileComponent,
    title: 'My Profile',
    canActivate: [AuthGuard],
  },
  {
    path: 'invite-request',
    component: InviteRequestComponent,
    title: 'Invite Request',
  },
  {
    path: 'all-notifications',
    component: NotificationsComponent,
    title: 'Notifications',
    canActivate: [AuthGuard],
  },
  {
    path: 'dashboard/inbox',
    component: InboxComponent,
    title: 'Inbox',
    canActivate: [AuthGuard],
  },
  {
    path: 'all-invites',
    component: ShowInvitesComponent,
    title: 'Invites',
    canActivate: [AuthGuard],
  },
  {
    path: 'error',
    component: Auth0ErrorComponent,
    title: 'Error',
    canActivate: [ErrorRouteGuardGuard],
    canDeactivate: [ErrorRouteDeactivateGuard]
  },
  {
    path: '**',
    redirectTo: 'dashboard',
  },
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
