<app-top-nav></app-top-nav>
<div class="page-container" style="position: relative;">
  <div style="margin: 24px 60px 0px 60px">

    <!-- Inline actions -->
    <div fxLayout="row" fxLayoutAlign="space-between center" class=" container" *ngIf="taskName">
      <div class="title" fxLayoutGap="2px" fxLayout="row" fxLayoutAlign="start center">
        <!-- task name-->
        <span *ngIf="taskName && !renameTask" class="task-name-display" matTooltipPosition="above"
          [matTooltip]="taskName" [matTooltipDisabled]="taskName.length <= 20">{{
            taskName | midTruncate : 10 : 10
          }}</span>
        <!-- task rename field -->
        <div fxLayout="column" style="max-width: 100%; ">
          <!-- task edit field -->
          <div class="rename-task-field" style="min-width: 300px;" *ngIf="renameTask" fxLayout="row"
            fxLayoutAlign="space-between center">
            <div fxLayout="row" class="field-error" *ngIf="errorType" (mouseenter)="showTaskRelatedError = true"
              (mouseleave)="showTaskRelatedError = false">
              <img src="../../../../assets/images/outputDataview/error-circle.svg" />
            </div>
            <div contenteditable fxFlex="70" class="text-input" max="20" (input)="setNewTaskName($event)"
              (keydown)="onTaskNameChange($event.target.innerHTML, $event)" [textContent]="taskNameValue">
            </div>
            <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="space-between center" style="margin-left: 6px">
              <div class="text-count">
                ({{taskNameValue?.length || 0}}/{{maxCharCountTaskName}})
              </div>
              <div class="action-btns" fxLayout="row">
                <button (click)="saveTaskName()" mat-button mat-icon-button
                  *ngIf="taskNameValue.length >= 3 && !errorType">
                  <mat-icon svgIcon="Check"></mat-icon>
                </button>
                <button mat-button mat-icon-button *ngIf="taskNameValue.length < 3  || errorType">
                  <mat-icon svgIcon="checkDisabled"></mat-icon>
                </button>
                <button mat-button mat-icon-button [disabled]="taskNameValue.length == 0">
                  <mat-icon (click)="exitTaskEdit()" svgIcon="Close"></mat-icon>
                </button>
              </div>
            </div>
          </div>
          <div class="task-name-error" *ngIf="showTaskRelatedError">
            <span>{{tasknameErrorMessageMapping[errorType]}}</span>
          </div>
        </div>
        <!-- task related actions menu -->
        <img *ngIf="!renameTask && taskName"
          [src]="t.menuOpen ? '../../../../assets/images/outputDataview/close-menu.svg': '../../../../assets/images/outputDataview/open-menu.svg'"
          style="cursor: pointer" [matMenuTriggerFor]="taskMenu" #t="matMenuTrigger" />

        <mat-menu #taskMenu="matMenu" class="task-menu">
          <button mat-menu-item (click)="renameTask = true" [disabled]="isSampleTask">Rename</button>
          <button mat-menu-item (click)="downloadInputFile()" [disabled]="!inputFileDownloadUrl">Download Input
            file</button>
          <button mat-menu-item (click)="openBatchDetailsDialog()" [disabled]="!batchDetails">Details</button>
          <button mat-menu-item (click)="openRecipeSummaryDialog()" [disabled]="isSampleTask">Recipe Summary</button>
        </mat-menu>
      </div>

    </div>

    <!-- tabs -->
    <div class="custom-tabs" fxLayout="row">
      <div class="input-data" [ngClass]="{'activeTab': selectedCustomTab == 'inputData'}" fxlayout="row"
        fxLayoutAlign="center center" (click)="switchTab('inputData')">
        <span>Input Data</span>
      </div>
      <div class="spanner-one"></div>
      <div class="output-data" [ngClass]="{'activeTab': selectedCustomTab == 'outputData'}" fxlayout="row"
        fxLayoutAlign="center center" (click)="switchTab('outputData')">
        <span>Output Data
        </span>
      </div>
      <div class="spanner-two" fxLayoutAlign="end center">
        <div class="generate-file-btn" matTooltipPosition="above"
          matTooltip="To generate output file please clear all filters"
          [matTooltipDisabled]="isObjectEmpty(this.sortObject) && isObjectEmpty(this.filterObject)">
          <button mat-flat-button color="primary"
            [disabled]="!isObjectEmpty(this.sortObject) || !isObjectEmpty(this.filterObject)"
            (click)="generateOutputFile()">
            <!-- [disabled]="jssOutputService.isSampleBatch" -->
            Generate output file
          </button>
        </div>
      </div>
    </div>

    <div class="filters-table-wrapper">
      <!-- filter section -->
      <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="space-between center" class="filter-section"
        *ngIf="selectedCustomTab == 'outputData'">
        <!-- 1. filters left -->
        <div [style]="!selectedColumns?.length ? 'cursor: pointer;' : ''" fxLayout="row"
          fxLayoutAlign="space-between center" style="width: 100%">

          <!-- function search -->
          <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">

            <!-- Ayushman -->
            <div style="height: 42px;padding: 12px 16px;"
              class="appear-out-for-function query-wrapper" fxLayout="row" fxLayoutAlign="space-between center">
              <div fxLayout="row" fxLayoutAlign="start center" style="width: 100%;">
                <!-- <img *ngIf="!selectedColumns?.length" class="search-img" src="../../../../assets/images/search.svg"
                alt="" /> -->
                <span fxFlex="3" class="inactive-fx-icon" style="margin-right: 7px;">fx</span>
                <!-- ========================= -->
                <form fxFlex="90" class="dataset-form">
                  <ng-container>
                    <span style="justify-content: start;
                    align-items: center;
                    display: flex;">
                      <input type="text"
                      [ngStyle]="{'opacity': disableAllInputs ? '0.5' : '1'}"
                      placeholder="Type function or query here"
                  [formControl]="datasetControl"
                  [matAutocomplete]="datasetAuto"
                  (click)="editCategoryInput()"
                  [ngClass]="{'example-input': !isCategorySelected,'after-dataset-selection-input-style': isCategorySelected, 'function-params-reset-style': isFunctionParamsCleared}"
                  (input)="adjustWidth($event, 'category')"
                  (keydown.enter)="sendInputValueAsNormalQuery()"
                  #categoryOption>
                    </span>
                  <mat-autocomplete #datasetAuto="matAutocomplete" (optionSelected)="onCategorySelection($event, categoryOption)">
                    <mat-option fxLayout="column" fxLayoutAlgin="start start" *ngFor="let street of dataSetList | async" [value]="street">
                      <span class="option-title">{{street.title}}</span><br>
                      <span class="option-sub-title">{{street.sub_title}}</span>
                    </mat-option>
                    <div style="padding: 0px 16px;">
                      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px" style="border-top: 1px solid #DFE0E2;padding: 6px 0px;">
                        <div class="accept-option" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
                          <span class="option-tab-span">Enter</span>
                          <span class="option-help-text">to accept.</span>
                        </div>
                        <div class="navigate-option" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
                          <span class="option-arrow-span">
                            <mat-icon style="width: 10.76px;" svgIcon="autocompleteOptionArrowUpKey"></mat-icon>
                          </span>
                          <span class="option-arrow-span">
                            <mat-icon style="width: 10.76px;" svgIcon="autocompleteOptionArrowDownKey"></mat-icon>
                          </span>
                          <span class="option-help-text">to navigate.</span>
                        </div>
                      </div>
                    </div>
                  </mat-autocomplete>
                  </ng-container>
                  <ng-container>
                    <!-- <span style="padding: 6px 4px;" *ngFor="let item of selectedCategory">
                      <span style="font-family: 'IBM Plex Sans';
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 21px;">{{item}}</span>
                    </span> -->
                    <!-- <span style="padding: 0px 4px;
                    display: flex;
                    justify-content: center;
                    align-items: center;" *ngFor="let function of selectedFunctions">
                      <span style="font-family: 'IBM Plex Sans';
                      color: var(--neutral-100, #0C0E13);
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 21px;
                      background-color: #d0dafa;
                      padding: 2px 4px;
                      border-radius: 4px;
                      ">{{function}}</span>
                    </span> -->
                    <!-- ===== function option 
                  [readonly]="isFunctionOptionSelected"
                      
                      ===== -->
                      <span style="justify-content: center;
                      align-items: center;
                      display: flex; margin-left: -9px;">
                        <input *ngIf="showFunctionInput" type="text"
                        placeholder="function"
                        [ngStyle]="{'opacity': disableAllInputs ? '0.5' : '1'}"
                      [formControl]="functionControl"
                      (click)="editFunctionInput()"
                      [matAutocomplete]="functionAuto"
                      [ngClass]="{'function-name-input': !isFunctionOptionSelected, 'selected-function-name-box': isFunctionOptionSelected}"
                      
                      (input)="adjustWidth($event, 'function')"
                      #functionField>
                      </span>
               <mat-autocomplete #functionAuto="matAutocomplete" (optionSelected)="onFunctionSelection($event, functionField)">
                 <mat-option *ngFor="let option of functionList | async" [value]="option">
                   <span class="option-title">{{option.function_title}}</span><br>
                   <span class="option-sub-title">{{option.function_sub_title}}</span>
                 </mat-option>
                 <div style="padding: 0px 16px;">
                  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px" style="border-top: 1px solid #DFE0E2;padding: 6px 0px;">
                    <div class="accept-option" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
                      <span class="option-tab-span">Enter</span>
                      <span class="option-help-text">to accept.</span>
                    </div>
                    <div class="navigate-option" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
                      <span class="option-arrow-span">
                        <mat-icon style="width: 10.76px;" svgIcon="autocompleteOptionArrowUpKey"></mat-icon>
                      </span>
                      <span class="option-arrow-span">
                        <mat-icon style="width: 10.76px;" svgIcon="autocompleteOptionArrowDownKey"></mat-icon>
                      </span>
                      <span class="option-help-text">to navigate.</span>
                    </div>
                  </div>
                </div>
               </mat-autocomplete>
               
                  <!-- ============================== -->
                    <!-- <span *ngIf="createdColumnName.length!=0">
                      <ng-container *ngFor="let newColumn of createdColumnName; let i = index">
                        <span style="position: relative;padding: 5px 4px;" class="newly-created-column-name">
                          <span>{{newColumn}}</span> &nbsp;&nbsp;
                          <span style="position: absolute;
                          top: 7px;
                          right: 1px;"><mat-icon (click)="removeColumn(i)" style="height: 15px;width: 15px;" svgIcon="cancel-this-column"></mat-icon></span>
                        </span> 
                      </ng-container>
                    </span> -->
                    <!-- Create structure starts here -->
                    <span *ngIf="showFunctionInput && showCreateStructure" class="columns">
                      <ng-container *ngFor="let column of columns; let i = index">
                        <span style="position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding-top: 1px;margin-right: 4px;">
                          <input [ngModelOptions]="{standalone: true}"
                          style="padding: 0px 4px;"

                                [ngStyle]="{'opacity': disableAllInputs ? '0.5' : '1'}"
                                 [ngClass]="{'column-name-input': column.isEditing, 'not-editing': !column.isEditing}"
                                 placeholder="New Column"
                                 matInput
                                 [(ngModel)]="column.name"
                                 [readonly]="!column.isEditing"
                                 (click)="editColumn(i)"
                                 (keydown.enter)="saveThisAsAColumnName($event,column.name)"
                                 [style.width]="column.isEditing ? '94px' : 'calc(' + column.name.length + 'ch + 19px)'"
                                 [disabled]="disableAllInputs"

                          />
                          <button 
                          [ngStyle]="{'opacity': disableAllInputs ? '0.5' : '1'}"
                          [disabled]="disableAllInputs"  style="position: absolute;
                          right: -4px;
                          top: 0.5px;
                          opacity: 1;" *ngIf="!column.isEditing" class="close-button" (click)="removeColumn(i)">
                          <mat-icon style="height: 15px;
                          width: 15px;" svgIcon="cancel-this-column"></mat-icon>
                        </button>
                        </span>
                      </ng-container>
                      <span style="justify-content: center;
                      align-items: center;
                      display: flex; padding: 0px 4px 0px 0px;">
                        <button 
                        [ngStyle]="{'opacity': disableAllInputs ? '0.5' : '1'}"
                        type="button" [ngClass]="{'disabled-new-column-button': !canAddColumn(), 'coulmn-add-icon': canAddColumn()}" *ngIf="columns.length!=5"  (click)="addColumn()" [disabled]="!canAddColumn() || disableAllInputs">
                          <mat-icon style="width: 13px;"  svgIcon="plusIcon"></mat-icon>
                        </button>
                      </span>
                    </span>
                    <!-- Create structure ends here -->
                    <!-- remove structure starts here -->
                     <!-- ***************************************************************** -->
                    <span *ngIf="showFunctionInput && showRemoveStructure" class="columns">
                      <ng-container *ngFor="let structure of structures; let i = index">
                        <span style="justify-content: center;position: relative;padding: 0px 4px 0px 0px;
                          align-items: center;
                          display: flex;">
                            <input type="text"
                                  style="padding: 0px 4px;height: 16px;"
                                  placeholder="Column Name"
                                  [ngStyle]="{'opacity': disableAllInputs ? '0.5' : '1'}"
                                  [disabled]="disableAllInputs"
                                  [formControl]="structure.functionColumnControl"
                                  (click)="checkValueChange($event, structure, i, functionField)"
                                  [matAutocomplete]="functionAuto"
                                  class="function-column-name-input"
                                  (input)="checkValueChange($event, structure, i, functionField)"
                                  (keydown.enter)="preventDefaultActionOnEnter($event)"
                                  (keyup.enter)="preventDefaultActionOnEnter($event)"

                                  #functionField>
                                  <button style="position: absolute;
                          right: 1px;
                          top: 0px;" [ngStyle]="{'opacity': disableAllInputs ? '0.5' : '1'}"
                          [disabled]="disableAllInputs" class="close-button" (click)="removeRemovableColumn(i)">
                          <mat-icon style="height: 15px;
                          width: 15px;" svgIcon="cancel-this-column"></mat-icon>
                        </button>
                          </span>
                          <mat-autocomplete #functionAuto="matAutocomplete" (optionSelected)="checkSelection($event, structure, functionField)">
                            <mat-option style="align-items: center;
                            line-height: 26px !important;
                            height: 30px !important;
                            padding: 1px 16px !important;color: var(--neutral-100, #0C0E13);
font-family: 'IBM Plex Sans';
font-size: 12px;
font-style: normal;
font-weight: 400;
" *ngFor="let columnOption of filteredFunctionList | async" [value]="columnOption.column_name">
                              <span class="option-title">{{columnOption.column_index}}</span>.<span class="option-title">{{columnOption.column_name}}</span>
                              <!-- <span class="option-sub-title">{{option.function_sub_title}}</span> -->
                            </mat-option>
                            <div style="padding: 0px 16px;">
                              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px" style="border-top: 1px solid #DFE0E2;padding: 6px 0px;">
                                <div class="accept-option" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
                                  <span class="option-tab-span">Enter</span>
                                  <span class="option-help-text">to accept.</span>
                                </div>
                                <div class="navigate-option" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
                                  <span class="option-arrow-span">
                                    <mat-icon style="width: 10.76px;" svgIcon="autocompleteOptionArrowUpKey"></mat-icon>
                                  </span>
                                  <span class="option-arrow-span">
                                    <mat-icon style="width: 10.76px;" svgIcon="autocompleteOptionArrowDownKey"></mat-icon>
                                  </span>
                                  <span class="option-help-text">to navigate.</span>
                                </div>
                              </div>
                            </div>
                          </mat-autocomplete>   
                      </ng-container>
                      <span style="justify-content: center;
                        align-items: center;
                        display: flex; padding: 0px 4px 0px 0px;">
                          <button  [ngStyle]="{'opacity': disableAllInputs ? '0.5' : '1'}"
                          [disabled]="disableAllInputs"
                          type="button" class="coulmn-add-icon" (click)="addStructure()">
                            <mat-icon style="width: 13px;"  svgIcon="plusIcon"></mat-icon>
                          </button>
                        </span>
                    </span>
                    
                     <!-- ***************************************************************** -->
                    <!-- <span *ngIf="showFunctionInput && showRemoveStructure" class="columns">
                      <ng-container *ngFor="let removableColumn of removableColumns; let i = index">
                        <span style="position: relative;padding: 5px 4px;">
                          <input [ngModelOptions]="{standalone: true}"
                                 [ngClass]="{'column-name-input': removableColumn.isEditing, 'not-editing': !removableColumn.isEditing}"
                                 placeholder="Removable Column"
                                 matInput
                                 [(ngModel)]="removableColumn.name"
                                 [readonly]="!removableColumn.isEditing"
                                 (click)="editRemovableColumn(i)"
                                 (keydown.enter)="saveThisAsARemovableColumnName($event,removableColumn.name)"
                                 [style.width]="removableColumn.isEditing ? '123px' : 'calc(' + removableColumn.name.length + 'ch + 19px)'"

                          />
                          <button style="position: absolute;
                          right: 1px;
                          top: 9px;" *ngIf="!removableColumn.isEditing" class="close-button" (click)="removeRemovableColumn(i)">
                          <mat-icon style="height: 15px;
                          width: 15px;" svgIcon="cancel-this-column"></mat-icon>
                        </button>
                        </span>
                      </ng-container>
                      <span style="justify-content: center;
                      align-items: center;
                      display: flex; padding: 0px 4px 0px 0px;">
                        <button type="button" [ngClass]="{'disabled-new-column-button': !canAddARemovableColumn(), 'coulmn-add-icon': canAddARemovableColumn()}" *ngIf="removableColumns.length!=5"  (click)="addRemovableColumn()" [disabled]="!canAddARemovableColumn()">
                          <mat-icon style="width: 13px;"  svgIcon="plusIcon"></mat-icon>
                        </button>
                      </span>
                    </span> -->
                    <!-- remove structure ends here -->
                    <!-- Merge structure starts here -->
                    <span *ngIf="showFunctionInput && showMergeStructure" class="columns" style="padding:0px 0px 0px 2px;">
                      <!-- New column start  -->
                      <span style="position: relative;padding: 2px 2px 0px 2px;display: flex;">
                        <input [ngModelOptions]="{standalone: true}"
                              style="padding: 0px 4px;"
                              [ngStyle]="{'opacity': disableAllInputs ? '0.5' : '1'}"
                              [disabled]="disableAllInputs"
                              [ngClass]="{'column-name-input': mergableNewColumn.isEditing, 'not-editing': !mergableNewColumn.isEditing}"
                              placeholder="New Column"
                              matInput
                              [(ngModel)]="mergableNewColumn.name"
                              [readonly]="!mergableNewColumn.isEditing"
                              (click)="editMergableNewColumn()"
                              (input)="checkAllParamsForMerge()"
                              (keydown.enter)="saveThisAsMergableNewColumn($event,mergableNewColumn.name)"
                              [style.width]="mergableNewColumn.isEditing ? '80px' : 'calc(' + mergableNewColumn.name.length + 'ch)'"

                        />
                      </span>
                      <!-- New column ends  -->
                      <!-- Delimiter start -->
                      <span style="position: relative;padding: 2px 2px 0px 2px;display: flex;">
                        <input [ngModelOptions]="{standalone: true}"
                          style="padding: 0px 4px;"
                          [ngStyle]="{'opacity': disableAllInputs ? '0.5' : '1'}"
                          [disabled]="disableAllInputs"
                          [ngClass]="{'column-name-input': mergableDelimiter.isEditing, 'not-editing': !mergableDelimiter.isEditing}"
                          placeholder="Delimiter"
                          matInput
                          [(ngModel)]="mergableDelimiter.name"
                          [readonly]="!mergableDelimiter.isEditing"
                          (click)="editMergableDelimiter($event)"
                          (input)="checkAllParamsForMerge()"
                          (keydown.enter)="saveThisAsMergableDelimiter($event,mergableDelimiter.name)"
                          maxlength="1"
                          [style.width]="mergableDelimiter.isEditing ? '57px' : 'calc(' + (mergableDelimiter.name.length + 1) + 'ch)'"

                        />
                      </span>
                      <!-- Delimiter ends -->
                      <!-- ***************************************************************** -->
                      <span style="display: flex;padding: 1px 2px 0px 2px;
                      justify-content: center;
                      align-items: center;">
                        <ng-container *ngFor="let structure of outputHeaderStructuresForMerge; let i = index">
                          <span style="justify-content: center;position: relative;padding:0px 4px 0px 0px;
                            align-items: center;
                            display: flex;">
                              <input type="text"
                              style="padding: 0px 4px;height: 15px;"

                                    placeholder="Output Header"
                                    [ngStyle]="{'opacity': disableAllInputs ? '0.5' : '1'}"
                                    [disabled]="disableAllInputs"
                                    [formControl]="structure.outputHeaderControlForMerge"
                                    (click)="editFunctionInput(structure)"
                                    [matAutocomplete]="mergeAuto"
                                    class="function-column-name-input"
                                    (input)="checkValueChangeForMerge($event, structure, i)"
                                    (click)="checkValueChangeForMerge($event, structure, i)"
                                    (keyup.enter)="preventDefaultActionOnEnter($event)"
                                    (keydown.enter)="preventDefaultActionOnEnter($event)"
                                    #outputHeaderMergeField>
                                    <button style="position: absolute;
                            right: 1px;
                            top: 0px;" [ngStyle]="{'opacity': disableAllInputs ? '0.5' : '1'}"
                          [disabled]="disableAllInputs" class="close-button" (click)="removableOutputHeadersForMerge(i)">
                            <mat-icon style="height: 15px;
                            width: 15px;" svgIcon="cancel-this-column"></mat-icon>
                          </button>
                            </span>
                            <mat-autocomplete #mergeAuto="matAutocomplete" (optionSelected)="checkSelectionForMerge($event, structure, outputHeaderMergeField)">
                              <mat-option style="align-items: center;
                              line-height: 26px !important;
                              height: 30px !important;
                              padding: 1px 16px !important;color: var(--neutral-100, #0C0E13);
  font-family: 'IBM Plex Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  " *ngFor="let columnOption of filteredOutputHeaderListForMerge | async" [value]="columnOption.column_name">
                                <span class="option-title">{{columnOption.column_index}}</span>.<span class="option-title">{{columnOption.column_name}}</span>
                                <!-- <span class="option-sub-title">{{option.function_sub_title}}</span> -->
                              </mat-option>
                              <div style="padding: 0px 16px;">
                                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px" style="border-top: 1px solid #DFE0E2;padding: 6px 0px;">
                                  <div class="accept-option" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
                                    <span class="option-tab-span">Enter</span>
                                    <span class="option-help-text">to accept.</span>
                                  </div>
                                  <div class="navigate-option" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
                                    <span class="option-arrow-span">
                                      <mat-icon style="width: 10.76px;" svgIcon="autocompleteOptionArrowUpKey"></mat-icon>
                                    </span>
                                    <span class="option-arrow-span">
                                      <mat-icon style="width: 10.76px;" svgIcon="autocompleteOptionArrowDownKey"></mat-icon>
                                    </span>
                                    <span class="option-help-text">to navigate.</span>
                                  </div>
                                </div>
                              </div>
                            </mat-autocomplete>   
                        </ng-container>
                        <span style="justify-content: center;
                          align-items: center;
                          display: flex; padding: 0px 4px 0px 0px;">
                            <button [ngStyle]="{'opacity': disableAllInputs ? '0.5' : '1'}"
                            [disabled]="disableAllInputs"  
                            type="button" class="coulmn-add-icon" (click)="addOutputHeaderStructureForMerge()">
                              <mat-icon style="width: 13px;"  svgIcon="plusIcon"></mat-icon>
                            </button>
                          </span>
                      </span>
                    
                     <!-- ***************************************************************** -->


                      <!-- <ng-container *ngFor="let outputHeader of mergableOutputHeader; let i = index">
                        <span style="position: relative;padding: 5px 4px;">
                          <input [ngModelOptions]="{standalone: true}"
                                 [ngClass]="{'column-name-input': outputHeader.isEditing, 'not-editing': !outputHeader.isEditing}"
                                 placeholder="Output header"
                                 matInput
                                 [(ngModel)]="outputHeader.name"
                                 [readonly]="!outputHeader.isEditing"
                                 (click)="editMergableOutputHeader(i)"
                                 (keydown.enter)="saveThisAsMergableOutputHeader($event,outputHeader.name)"
                                 [style.width]="outputHeader.isEditing ? '94px' : 'calc(' + outputHeader.name.length + 'ch + 19px)'"

                          />
                          <button style="position: absolute;
                          right: 1px;
                          top: 9px;" *ngIf="!outputHeader.isEditing" class="close-button" (click)="removeMergableOutputHeader(i)">
                          <mat-icon style="height: 15px;
                          width: 15px;" svgIcon="cancel-this-column"></mat-icon>
                        </button>
                        </span>
                      </ng-container>
                      <span style="justify-content: center;
                      align-items: center;
                      display: flex; padding: 0px 4px 0px 0px;">
                        <button type="button" [ngClass]="{'disabled-new-column-button': !canAddMergableOutputHeader(), 'coulmn-add-icon': canAddMergableOutputHeader()}" *ngIf="removableColumns.length!=5"  (click)="addMergableOutputHeader()" [disabled]="!canAddMergableOutputHeader()">
                          <mat-icon style="width: 13px;"  svgIcon="plusIcon"></mat-icon>
                        </button>
                      </span> -->
                      <!-- ===== -->
                    </span>
                    <!-- Merge structure ends here -->
                    <!-- Rename structure start -->
                    <span *ngIf="showFunctionInput && showRenameStructure" class="columns">
                      <!-- old column created by user for rename start  -->
                      <!-- ***************************************************************** -->
                    <span style="padding: 0px 1px 0px 0px;" *ngIf="showFunctionInput && showRenameStructure" class="columns">
                      <ng-container>
                        <span style="justify-content: center;position: relative;padding: 0px 0px 0px 0px;
                          align-items: center;
                          display: flex;">
                            <input type="text"
                            style="padding: 0px 4px;"

                                  placeholder="Output Column"
                                  [ngStyle]="{'opacity': disableAllInputs ? '0.5' : '1'}"
                                  [formControl]="outputColumnControlForRename"
                                  [matAutocomplete]="functionRenameAuto"
                                  class="function-column-name-input"
                                  (input)="checkRenameOutputColumnValueChange($event)"
                                  (click)="checkRenameOutputColumnValueChange($event)"
                                  (keyup.enter)="preventDefaultActionOnEnter($event)"
                                  (keydown.enter)="preventDefaultActionOnEnter($event)"
                                  #functionField>
                                  <!-- <button style="position: absolute;
                          right: 1px;
                          top: 9px;" class="close-button" (click)="removeRemovableColumn(i)">
                          <mat-icon style="height: 15px;
                          width: 15px;" svgIcon="cancel-this-column"></mat-icon>
                        </button> -->
                          </span>
                          <mat-autocomplete #functionRenameAuto="matAutocomplete" (optionSelected)="checkOutputColumnSelectionForRename($event,functionField)">
                            <mat-option style="align-items: center;
                            line-height: 26px !important;
                            height: 30px !important;
                            padding: 1px 16px !important;color: var(--neutral-100, #0C0E13);
font-family: 'IBM Plex Sans';
font-size: 12px;
font-style: normal;
font-weight: 400;
" *ngFor="let columnOption of outputColumnListForRename | async" [value]="columnOption.column_name">
                              <span class="option-title">{{columnOption.column_index}}</span>.<span class="option-title">{{columnOption.column_name}}</span>
                              <!-- <span class="option-sub-title">{{option.function_sub_title}}</span> -->
                            </mat-option>
                            <div style="padding: 0px 16px;">
                              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px" style="border-top: 1px solid #DFE0E2;padding: 6px 0px;">
                                <div class="accept-option" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
                                  <span class="option-tab-span">Enter</span>
                                  <span class="option-help-text">to accept.</span>
                                </div>
                                <div class="navigate-option" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
                                  <span class="option-arrow-span">
                                    <mat-icon style="width: 10.76px;" svgIcon="autocompleteOptionArrowUpKey"></mat-icon>
                                  </span>
                                  <span class="option-arrow-span">
                                    <mat-icon style="width: 10.76px;" svgIcon="autocompleteOptionArrowDownKey"></mat-icon>
                                  </span>
                                  <span class="option-help-text">to navigate.</span>
                                </div>
                              </div>
                            </div>
                          </mat-autocomplete>   
                      </ng-container>
                      <!-- <span style="justify-content: center;
                        align-items: center;
                        display: flex; padding: 0px 4px 0px 0px;">
                          <button 
                          type="button" class="coulmn-add-icon" (click)="addStructure()">
                            <mat-icon style="width: 13px;"  svgIcon="plusIcon"></mat-icon>
                          </button>
                        </span> -->
                    </span>
                    
                     <!-- ***************************************************************** -->
                      <!-- <span style="position: relative;padding: 5px 4px;">
                        <input [ngModelOptions]="{standalone: true}"
                               [ngClass]="{'column-name-input': oldColumnCreatedByUser.isEditing, 'not-editing': !oldColumnCreatedByUser.isEditing}"
                               placeholder="Column Name"
                               matInput
                               [(ngModel)]="oldColumnCreatedByUser.name"
                               [readonly]="!oldColumnCreatedByUser.isEditing"
                               (click)="editOldColumnNameCreatedByUser()"
                               (keydown.enter)="saveThisAsOldColumnNameCreatedByUser($event,oldColumnCreatedByUser.name)"
                               [style.width]="oldColumnCreatedByUser.isEditing ? '90px' : 'calc(' + oldColumnCreatedByUser.name.length + 'ch)'"

                        />
                      </span> -->

                      <!-- old column created by user for rename ends  -->
                      <!-- New column for rename start  -->
                      <span style="position: relative;padding: 0px 4px 3px 4px;">
                        <input [ngModelOptions]="{standalone: true}"
                              [ngStyle]="{'opacity': disableAllInputs ? '0.5' : '1'}"
                              [disabled]="disableAllInputs"
                              [ngClass]="{'column-name-input': newColumnNameForRename.isEditing, 'not-editing': !newColumnNameForRename.isEditing}"
                              placeholder="New Column"
                              matInput
                              [(ngModel)]="newColumnNameForRename.name"
                              [readonly]="!newColumnNameForRename.isEditing"
                              (input)="checkForEmptyValueForRename()"
                              (click)="editNewColumnNameForRename()"
                              (keydown.enter)="saveThisAsNewColumnNameForRename($event,newColumnNameForRename.name)"
                              [style.width]="newColumnNameForRename.isEditing ? '80px' : 'calc(' + newColumnNameForRename.name.length + 'ch)'"

                        />
                      </span>
                      <!-- New column for rename ends  -->
                    </span>
                    <!-- Rename structure end -->


                  </ng-container>
                </form>
                <span fxFlex="7" style="justify-content: right;
                align-items: center;
                display: flex;
                width: 12%;">
                  <span class="animate__animated animate__slideInRight animate__faster" *ngIf="showFunctionRequestLoadingState && !showSuccessStateIcons" style="justify-content: center;align-items: center;display: flex;margin-right: 5px;">
                    <div class="function-submit-loader"></div>
                  </span>
                  <span class="animate__animated animate__slideInRight animate__faster" *ngIf="showSuccessStateIcons" style="justify-content: center;align-items: center;display: flex;margin-right: 5px;">
                    <mat-icon svgIcon="checkCircle"></mat-icon>
                  </span>
                  <span class="animate__animated animate__slideInRight animate__faster" *ngIf="showErrorStateIcons" style="justify-content: center;align-items: center;display: flex;margin-right: 5px;">
                    <mat-icon svgIcon="functionClose"></mat-icon>
                  </span>
                  <span class="animate__animated animate__slideInRight animate__faster" (click)="sendFunctionQuery()" *ngIf="enableFunctionSubmitIcon" style="justify-content: center;align-items: center;display: flex;">
                    <mat-icon style="height: 16px; width: 16px;" svgIcon="functionSubmitIcon"></mat-icon>
                  </span>
                  <span class="animate__animated animate__slideInRight animate__faster" *ngIf="showBlureSubmitIcon" style="justify-content: center;align-items: center;display: flex;">
                    <mat-icon style="height: 16px; width: 16px;" svgIcon="functionBluredSubmitIcon"></mat-icon>
                  </span>
                  
                  <span class="animate__animated animate__slideInRight animate__faster" *ngIf="showFunctionRequestLoadingState && !showSuccessStateIcons" style="justify-content: center;align-items: center;display: flex;">
                    <mat-icon style="height: 16px; width: 16px;" svgIcon="maximizeBlured"></mat-icon>
                  </span>
                  <span  *ngIf="showFunctionMaximizeIcon" style="justify-content: center;align-items: center;display: flex;margin-left: 6px;">
                    <mat-icon (click)="showFunctionQuerySidePanelView()" style="height: 16px; width: 16px;" svgIcon="functionMaximize"></mat-icon>
                  </span>
                </span>
                <!-- ========================= -->
                
              </div>
              <!-- <div style="padding: 5px">
                <img src="../../../../assets/images/filled-close.svg" (click)="clearSearchFilter($event)"
                   class="clear-search" />
              </div> -->
            </div>
            <!-- Ayushman -->


            <!--  -->
            <!--- Icons -->

            <div matTooltip="Custom edit" matTooltipPosition="above" matTooltipShowDelay="500" class="appear-out"
              fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px" style="padding: 10.5px 12px;">
              <mat-icon svgIcon="custom-edit-icon"></mat-icon>
            </div>
            <div matTooltip="Manual mapping" matTooltipPosition="above" matTooltipShowDelay="500" class="appear-out"
              fxLayout="row" fxLayoutAlign="center center" style="padding: 9px 12px;">
              <mat-icon svgIcon="manual-mapping-icon"></mat-icon>
            </div>
            <div matTooltip="Version history" matTooltipPosition="above" matTooltipShowDelay="500" class="appear-out"
              fxLayout="row" fxLayoutAlign="center center" style="padding: 9px 12px;" (click)="openVersionHistory()">
              <mat-icon svgIcon="version-history-icon"></mat-icon>
            </div>
          </div>

          <div fxLayout="row" class="data-table-actions" fxLayoutGap="10px">
            <button (click)="resetSortForAllColumns()" *ngIf="!isObjectEmpty(sortObject)" mat-stroked-button
              color="primary">
              Reset sort
            </button>
            <button (click)="resetFilterForAllColumns()" *ngIf="!isObjectEmpty(filterObject)" mat-stroked-button
              color="primary">
              Clear all filters
            </button>
          </div>
        </div>
      </div>

      <!--- input data table -->
      <app-input-data-table [inputDataTableContent]="inputDataTableContent"
        (inputTablePaginationEvent)="onInputTablePaginationEvent($event)"
        (inputTableGoToPageEvent)="goToPageInputTable($event)"
        *ngIf="selectedCustomTab == 'inputData' && !isInputDataTableLoading"></app-input-data-table>

      <!-- input data table skeleton -->
      <!-- <app-input-data-table-skeleton-loader *ngIf="selectedCustomTab == 'inputData' && isInputDataTableLoading">
      </app-input-data-table-skeleton-loader> -->

      <!-- output data table -->
      <div class="data-table" *ngIf="selectedCustomTab == 'outputData'">
        <div fxLayout="row" class="sticky-header" #scrollOne *ngIf="(!isHeaderLoading && inputHeaders?.length > 0)">
          <div fxLayout="column" class="index-column">
            <div class="header-type" matTooltipPosition="above" matTooltip="Input Header"
              matTooltipClass="dark-bg-tooltip">IH</div>
            <div class="header-type" matTooltipPosition="above" matTooltip="Output Header"
              matTooltipClass="dark-bg-tooltip">OH</div>
            <div class="header-type"></div>
          </div>
          <div *ngFor="let header of inputHeaders; let headerIndex = index" class="each-header-col" fxLayout="column"
            style="flex-grow: 1; flex-basis:0">
            <ng-container>
              <!-- header line 1-->
              <div matTooltipPosition="above" [matTooltip]="
                     header['input_columns']|joinStrings:header['delimiter']" contentOverflowTooltip
                #c="isContentOverflowing" [matTooltipDisabled]="!c.isContentOverflowing"
                class="cells truncate data-table-header-1"
                [ngStyle]="{'width.px': inputHeaders.length <= totalColsInAScreen? 'auto' : 200}">
                {{
                    header['input_columns']|joinStrings:header['delimiter']
                  }}
              </div>
              <!-- header line 2-->
              <div class="cells truncate data-table-header-2" fxLayout="row" fxLayoutAlign="space-between center"
                [ngStyle]="{'width.px': inputHeaders.length <= totalColsInAScreen? 'auto' : 200}">
                <div class="label" matTooltipPosition="above" [matTooltip]="
                header['output_column']" contentOverflowTooltip #d="isContentOverflowing"
                  [matTooltipDisabled]="!d.isContentOverflowing" matTooltipClass="dark-bg-tooltip">{{
                    header.output_column 
                  }}</div>

                <div *ngIf="header['data_quality']?.toString() == '0' || header['data_quality']" class="fill-rate"
                  [ngClass]="{
                    'fill-red': (header['data_quality'] > -1 && header['data_quality'] < 50),
                    'fill-yellow': header['data_quality'] >= 50 && header['data_quality'] < 100,
                    'fill-green': header['data_quality'] >= 100
                  }" matTooltipPosition="above" matTooltip="Fill Rate" matTooltipClass="dark-bg-tooltip">
                  {{ header['data_quality'].toFixed(0) + '%' }}
                </div>

              </div>
              <!-- header line 3-->
              <div class="cells truncate data-table-header-3" [id]="'div'+header['column_index']" fxFlex
                fxLayoutAlign="space-between center"
                [ngStyle]="{'width.px': inputHeaders.length <= totalColsInAScreen? 'auto' : 200}">
                <span>{{
                    header['column_index'] 
                  }}</span>
                <div class="sort-icon-wrapper" [ngClass]="{
                  'sort-icon-bg': header['column_index'] == sortActiveForColumnIndex,
                }">
                  <img *ngIf="header['data_quality']" [id]="header['column_index']" #sortButton
                    (click)=" sortAndFilterAppliedFor(header, $event); "
                    src="../../../../assets/images/outputDataview/sort.svg" matTooltipPosition="above"
                    matTooltip="Sort & Filter" matTooltipClass="dark-bg-tooltip" />
                </div>

              </div>
            </ng-container>
          </div>
        </div>
        <!-- body -->
        <div class="data-table-body" #scrollTwo (mouseenter)="updateCurrentElement('scrollTwo')"
          [ngStyle]="{'max-height.px': screenHeight>900 ? 525: 350}" (scroll)="updateScroll($event)" fxLayout="row"
          *ngIf="(!isTableContentLoading && dataTableRows?.length > 0)">
          <div class="row-cell-index-wrapper" fxLayout="column">
            <div *ngFor="let row of dataTableRows; let i=index" [id]="row.row_no" class="row-cell-index">
              {{row.row_no}}
            </div>
          </div>
          <div class="data-row" fxLayout="column">
            <div class="row-cell-wrapper" *ngFor="let row of dataTableRows; let i=index" fxLayout="row">
              <ng-container *ngFor="let header of inputHeaders">
                <!-- (mouseover)="changeStyle($event)"
                  (mouseout)="changeStyle($event)" -->
                <div class="row-cell truncate" [attr.data-column-index]="header.column_index" matTooltipPosition="above"
                  [matTooltip]="row[header.output_column]" contentOverflowTooltip #e="isContentOverflowing"
                  [matTooltipDisabled]="!e.isContentOverflowing" [attr.data-row-index]="row.row_no"
                  (click)="editCell($event)"
                  [ngStyle]="{'flex-grow': inputHeaders.length <= totalColsInAScreen? 1 : 0}">
                  {{row[header.output_column]}}
                </div>
              </ng-container>
            </div>
          </div>
        </div>

        <!-- sort and filter menu for headers -->
        <!-- style="left: 200px; top: 50px;" -->
        <!-- [ngStyle]="{'left': leftOffset+ 'px', 'top': topOffset+ 'px'}" -->
        <div class="sort-and-filter-div" id="sortFilterMenu"
          [ngStyle]="{'left': leftOffset+ 'px', 'top': topOffset+ 'px', 'max-height.px': screenHeight<800 ? 200: auto}"
          *ngIf="sortActive" #sortFilterMenu>
          <div class="sort-part" fxLayout="column" fxLayoutGap="10px">
            <span>Sort by <span style="font-size: 12px; font-weight: 300;">(view only)</span></span>
            <mat-radio-group name="opList" fxLayout="column" class="radio-btns" fxLayoutGap="8px"
              [value]="chosenSortOrder ? chosenSortOrder: defaultSortValue">
              <mat-radio-button [value]=option.value (change)="getSortingOrder($event)"
                *ngFor="let option of sortOptions">{{option.display}}
              </mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="filter-part" fxLayout="column" fxLayoutGap="10px">
            <span>Filter <span style="font-size: 12px; font-weight: 300;">(view only)</span></span>
            <form [formGroup]="filterForm" #filterFormTemplate>
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-select name="status" panelClass="top-offset-mat-select" id="sortFilterSelect"
                  placeholder="Select a condition" formControlName="selectACondition"
                  (selectionChange)="onChangeOfCondition($event)" disableOptionCentering
                  (click)="$event.stopPropagation()">
                  <mat-option (click)="$event.stopPropagation()" *ngFor="let condition of conditions"
                    [value]="condition.value">
                    {{ condition.label }}
                  </mat-option>
                </mat-select>
                <mat-icon matSuffix svgIcon="DownArrow" class="selection-arrow-icon"></mat-icon>
              </mat-form-field>
              <mat-form-field appearance="outline" style="width: 100%"
                *ngIf="filterForm.controls['selectACondition'].value == 'contains' || filterForm.controls['selectACondition'].value == 'does not contain'">
                <input matInput placeholder="" value="" formControlName="text"
                  [attr.required]="filterForm.controls['selectACondition'].value == 'contains' || filterForm.controls['selectACondition'].value == 'does not contain'">
              </mat-form-field>
            </form>
            <div fxLayout="row" fxLayoutAlign="space-between center"
              *ngIf="filterForm.controls['selectACondition'].value">
              <button fxFlex="48" (click)="clearColumnFilter()" mat-stroked-button color="primary">
                Clear
              </button>
              <button fxFlex="48" [disabled]="!filterForm.valid" (click)="applyColumnFilter()" mat-flat-button
                color="primary">
                Apply
              </button>
            </div>
          </div>
        </div>
        <!-- </ng-template> -->
      </div>

      <!-- output data table skeleton -->
      <data-table-skeleton-loader [isInputDataTableLoading]="isInputDataTableLoading"
        [isHeaderLoading]="isHeaderLoading" [isTableContentLoading]="isTableContentLoading">
      </data-table-skeleton-loader>

      <!-- no results -->
      <div
        *ngIf="dataTableRows && dataTableRows.length == 0 && selectedCustomTab == 'outputData' && !isBusy || inputDataTableRows && inputDataTableRows.length == 0 && selectedCustomTab == 'inputData' && !isInputDataTableLoading"
        class="no-data-available" fxLayout="row" fxLayoutAlign="center center">
        <span> No Results Found </span>
      </div>

      <!-- error occured -->
      <div
        *ngIf="(!dataTableRows && !isBusy && selectedCustomTab == 'outputData') || (!inputDataTableRows && !isInputDataTableLoading && selectedCustomTab == 'inputData')"
        class="no-data-available" fxLayout="row" fxLayoutAlign="center center">
        <span> Some error occured. Please try again later. </span>
      </div>


      <!--2. pagination right-->
      <div class="pagination-wrapper" fxLayoutAlign="end center"
        *ngIf="selectedCustomTab == 'outputData' && !isHeaderLoading && !isTableContentLoading && dataTableRows && dataTableRows.length > 0">
        <div class=" page-wrapper" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="4px">
          <span>Page: </span>
          <div class="page-number" fxLayout="row" fxLayoutAlign="center center" contenteditable="true"
            (input)="currentPageValue = $event.target.textContent" [textContent]="currentPageValue"
            (keyup.enter)="goToPage($event)" (blur)="goToPage($event)">
          </div>
        </div>
        <mat-paginator class="data-table-paginator" [length]="dataTableTotalRows" [pageSize]="dataTableSize"
          [pageIndex]="dataTablePage - 1" [pageSizeOptions]="[5, 10, 20, 25, 100]" (page)="onPaginationEvent($event)"
          [showFirstLastButtons]="true">
        </mat-paginator>
      </div>
    </div>

    <!-- Version History    -->
    <app-version-history [ngStyle]="showVersionHistory?{'display': 'block'}: {'display': 'none'} ">
    </app-version-history>
    <!-- function and query conversation view | [ngStyle]="showFunctionQuerySidePanel?{'display': 'block'}: {'display': 'none'} "  ***************************************************************-->
    <div id="flexibleDivForFunctionQueryView"  [ngStyle]="showFunctionQuerySidePanel?{'display': 'block'}: {'display': 'none'} " 
    style="height: 100vh;width: 29.21875vw;min-width: 29.21875vw;position: fixed;top: 70px;right: 0;background-color: #ffffff;z-index: 999; display: block;">
    <div class="flexible-div-content-container" fxLayout="column" style="height: 90.5vh;
          margin: 24px 24px 24px 24px;">
      <div class="title-and-action" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="flexible-div-title">Functions and query</div>
        <div class="flexible-div-action" fxLayout="row" fxLayoutAlign="end center">
          <div fxLayout="row" fxLayoutAlign="center center" style="cursor: pointer;">
            <img style="width: 24px;
                  height: 24px;" id="resizeButton"
              src="../../../../assets/images/flexible-div/double_alt_arrow_right.svg" alt="rightArrow"
              srcset="../../../../assets/images/flexible-div/double_alt_arrow_right.svg">
          </div>
          <div fxLayout="row" fxLayoutAlign="center center" style="cursor: pointer;">
            <img (click)="showFunctionQuerySidePanelView()" style="width: 24px;
                  height: 24px;" src="../../../../assets/images/flexible-div/x.svg" alt="crossArrow"
              srcset="../../../../assets/images/flexible-div/x.svg">
          </div>
        </div>
      </div>
      <div class="flexible-div-subtitle" style="position: relative;" fxLayout="row" fxLayoutAlign="start start"
        fxLayoutGap="5px">
        <div class="flexible-div-subtitle-text">Functions</div>
        <div style="cursor: pointer; position: relative;" class="flexible-div-subtitle-ask"
          (click)="showFunctionQueryViewQuestionIconInfo()">
          <img *ngIf="enableFunctionQueryViewQuestionIcon" src="../../../../assets/images/flexible-div/question_circle.svg"
            alt="questionIcon" srcset="../../../../assets/images/flexible-div/question_circle_primary.svg">
          <img *ngIf="!enableFunctionQueryViewQuestionIcon" src="../../../../assets/images/flexible-div/question_circle.svg"
            alt="questionIcon" srcset="../../../../assets/images/flexible-div/question_circle.svg">
          <div *ngIf="enableFunctionQueryViewQuestionIcon" class="flexible-div-subtitle-ask-icon-info-tale" style="height: 10px;
                  width: 10px;
                  padding: 12px;
                  background: #f8fafd;
                  position: absolute;
                  top: 26px;
                  left: -7px;
                  rotate: 45deg;
                  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px -1px rgba(0, 0, 0, 0.10);"></div>
        </div>
        <!-- pop-up  -->
        <div *ngIf="enableFunctionQueryViewQuestionIcon" class="flexible-div-subtitle-ask-icon-info" style="height: auto;
              z-index: 9;
              width: 100%;
              padding: 12px;
              border-radius: 6px;
              background: #F8FAFD;
              position: absolute;
              top: 36px;
              box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px -1px rgba(0, 0, 0, 0.10);" fxLayout="column"
          fxLayoutGap="10px">
          <div style="width: 100%;" fxLayout="column">
            <!-- <div>See the current version at the top, followed by all previous versions.</div> -->
            <div>
              <ul style="padding-left: 24px !important;">
                <li>Shows the list of functions and query performed in this batch</li>
                <li>Details of the function /query error can be found by clicking on details.</li>
              </ul>
            </div>
            <div class="dont-show-this-div" fxLayout="row" fxLayoutAlign="space-between center">
              <div fxLayout="row" fxLayoutAlign="start center">
                <mat-checkbox color="primary" class="got-it-checkbox">Don’t show this again</mat-checkbox>
              </div>
              <div>
                <button (click)="showFunctionQueryViewQuestionIconInfo()" type="button" style="height: 32px;cursor: pointer;
                      padding: 8px 16px;border: none;
                      border-radius: 6px;
                      background: var(--Primary-600, #1042F1);color: var(--Gray-white, var(--Surface-50, #FFF));font-family: 'IBM Plex Sans';
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 500;
                      line-height: 15px;">Got it</button>
              </div>
            </div>
          </div>
        </div>
  
        <!--  -->
      </div>
      <div class="history-section" fxLayout="column" style="overflow-y: auto;padding-bottom: 20px;">
        <!-- <div class="current-version-label" fxLayout="row" fxLayoutAlign="start center" style="color: var(--Gray-900, #1B263B);
  
                font-family: 'IBM Plex Sans';
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 21px;">History</div> -->
                <div *ngFor="let conversationEntry of functionQueryConversationList | functionQueryListSorting:'created_at':'desc'; let i = index" fxLayout="column" style="border-radius: 6px;
                border: 1px solid var(--Gray-200, #E2E8F0);margin-bottom: 8px;">
  <div class="current-version-list-item" fxLayout="column" style="border-radius: 6px;
              background: var(--Surface-50, #FFF);padding: 10px 12px;" fxLayoutGap="6px">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div class="current-verison-list-item-text" style="color: var(--Gray-900, #1B263B);
                  font-family: 'IBM Plex Sans';
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 21px;" [appHighlightFunctionKey]="conversationEntry.conversation">
        {{conversationEntry.conversation}}
      </div>
      <div class="menu-button" fxLayout="column" fxLayoutAlign="start start">
        <mat-icon *ngIf="conversationEntry.conversation_status == 'completed'" svgIcon="checkCircle"></mat-icon>
        <mat-icon *ngIf="conversationEntry.conversation_status == 'error'" svgIcon="functionClose"></mat-icon>
        <!-- <button *ngIf="conversationEntry.conversation_status == 'completed'" mat-icon-button aria-label="conversation status icon">
          <mat-icon *ngIf="conversationEntry.conversation_status == 'completed'" svgIcon="checkCircle"></mat-icon>
        </button>
        <button *ngIf="conversationEntry.conversation_status == 'error'" mat-icon-button aria-label="conversation status icon">
          <mat-icon *ngIf="conversationEntry.conversation_status == 'error'" svgIcon="functionClose"></mat-icon>
        </button> -->
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div class="current-version-list-item-subtext" style="color: var(--Gray-500, #506480);
                  font-family: 'IBM Plex Sans';
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 18px;">
        {{conversationEntry.created_at}}
      </div>
      <div class="current-version-list-item-label" fxLayout="row" fxLayoutAlign="end center">
        <span class="affected-count-show" style="color: var(--neutral-100, #0C0E13);
                font-family: 'IBM Plex Sans';
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 18px; cursor: pointer;"
                *ngIf="visibleReplyIndex !== i"
              (click)="toggleReplyVisibility(i)">
          {{conversationEntry.rows_affected_count}} <span style="font-weight: 400;">rows affected/details</span>
        </span>
        <span class="hide-detail-class" style="color: var(--neutral-60, #323C4E);
                font-family: 'IBM Plex Sans';
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
                text-decoration-line: underline; cursor: pointer;"
              *ngIf="visibleReplyIndex === i"
              (click)="toggleReplyVisibility(i)">
          Hide details
        </span>
      </div>
    </div>
  </div>
  <div *ngIf="visibleReplyIndex === i" [ngClass]="{
          'successReplyStyle': conversationEntry.conversation_status === 'completed',
          'errorReplyStyle': conversationEntry.conversation_status === 'error',
        }" fxLayout="row" fxLayoutAlign="start center">
    {{conversationEntry.reply}}
  </div>
</div>

        
        
      </div>
      <!-- function search box start  -->
      <div class="function-search-box-container">
         <!-- ============  duplicate function search ========-->
          <!-- <div fxLayout="row" fxLayoutAlign="end center" style="padding: 12px 16px;
          border-radius: 8px;
          border: 1px solid var(--surface-50, #DFE0E2);">
            <div fxFlex="90" class="textarea-container">
              <textarea #autoAdjustTextArea rows="1"
              placeholder="Click to add a function or a query"
            [(ngModel)]="textareaContent"
            (input)="adjustTextareaHeight()"
            class="auto-resize-textarea">
              </textarea>
            </div>
            <div fxFlex="10">
              <span class="animate__animated animate__slideInRight animate__faster" style="justify-content: end;align-items: center;display: flex;cursor: pointer;">
                <mat-icon style="height: 16px; width: 16px;" svgIcon="functionBluredSubmitIcon"></mat-icon>
              </span>
            </div>
          </div> -->
         <!-- =============================================== -->
      </div>
      <!-- function search box end -->
      <!-- ========= version history section ends ============= -->
  
  
      <!--  -->
    </div>
    <!-- ==== -->
    <!-- <div class="action-to-be-taken" fxLayout="row" style="position: fixed;
            bottom: 24px;width: 30vw;">
            <div style="display: flex;width: 100%;
            border: 1px solid red;">
              <button mat-flat-button style="flex: 1; width: 50%;" color="primary">Save</button>
              <button mat-flat-button style="flex: 1; width: 50%;">Cancel</button>
            </div>
          </div> -->
    <!-- ==== -->
    <div id="resizableHandleForFunctionQueryView"></div>
  </div>
  
    <!--  function and query conversation view end **************************************************************************************************** -->
  </div>
