import { Injectable } from '@angular/core';
import {
  FormGroup,
  ValidatorFn,
  AbstractControl,
  FormControl,
} from '@angular/forms';
// import { isValidNumberForRegion } from 'libphonenumber-js'
import { phone } from 'phone';

@Injectable({
  providedIn: 'root',
})
export class ValidationService {
  constructor() {}

  /** password pattern validator */
  patternValidator(): ValidatorFn {
    return (control: AbstractControl): any => {
      if (!control.value) {
        return null;
      }
      const regex = new RegExp(
        '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@,()+/:;<=>_`{|}$%^&*-]).{8,}$'
      );
      const valid = regex.test(control.value);
      return valid ? null : { invalidPassword: true };
    };
  }
  //regex for atleast one uppercase and lowercase
  patternValidatorChar(): ValidatorFn {
    return (control: AbstractControl): any => {
      if (!control.value) {
        return null;
      }
      const regex = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])');
      const valid = regex.test(control.value);
      return valid ? null : { invalidPasswordChar: true };
    };
  }

  //regex for only numbers
  patternValidatorNum(): ValidatorFn {
    return (control: AbstractControl): any => {
      if (!control.value) {
        return null;
      }
      const regex = new RegExp('^(?=.*?[0-9])');
      const valid = regex.test(control.value);
      return valid ? null : { invalidPasswordNum: true };
    };
  }

  //regex for only special characters
  patternValidatorSpecialChar(): ValidatorFn {
    return (control: AbstractControl): any => {
      if (!control.value) {
        return null;
      }

      const regex = /[\{\}!\\"%&'()*+,-./:;=#>?@[$\]^_`|<>~]/;

      let valid = regex.test(control.value);
      // if(!valid){
      //   const pattern = /\\{\}/;
      //    valid = pattern.test(control.value);

      // }
      return valid ? null : { invalidPasswordSpecialChar: true };
    };
  }

  // ^(?!.* )(?=.*\d)(?=.*[A-Z]).{8,15}$
  /** match password validator */
  matchPassword(password: string, confirmPassword: string) {
    return (formGroup: FormGroup) => {
      const passwordControl = formGroup.controls[password];
      const confirmPasswordControl = formGroup.controls[confirmPassword];
      if (!passwordControl || !confirmPasswordControl) {
        return null;
      }
      if (
        confirmPasswordControl.errors &&
        !confirmPasswordControl.errors['passwordMismatch']
      ) {
        return null;
      }
      if (passwordControl.value !== confirmPasswordControl.value) {
        confirmPasswordControl.setErrors({ passwordMismatch: true });
      } else {
        confirmPasswordControl.setErrors(null);
      }
    };
  }

  // no white space validator
  noWhitespaceValidator(): ValidatorFn {
    return (control: AbstractControl): any => {
      if (!control.value) {
        return null;
      }
      const isWhitespace =
        ((control && control.value && control.value.toString()) || '').trim()
          .length === 0;
      const isValid = !isWhitespace;
      return isValid ? null : { whitespace: true };
    };
  }
  // US number validator
  numberValidation(): ValidatorFn {
    return (control: AbstractControl): any => {
      if (!control.value) {
        return null;
      } else {
        // const isValid = isValidNumberForRegion(control.value, 'US');
        const isValid = phone(control.value, { country: 'USA' }).isValid;
        return isValid ? null : { invalidNumber: true };
      }
    };
  }
  
  // ======
  TaskNamePatternValidation(): ValidatorFn {
    return (control: AbstractControl) => {
      if (!control.value) {
        return null; // Consider empty value as valid
      }

      const value = control.value;
      const firstCharacter = value.charAt(0);
      const lastCharacter = value.charAt(value.length - 1);

      // Regular expression to allow letters, digits, spaces, and hyphens, but not starting with a digit, special character, or space
      const allowNumbersRegex = /^[A-Za-z0-9\s-]*$/;
      const startsWithInvalidCharacter = /^[0-9\s\W]/.test(firstCharacter);
      const endsWithSpecialCharacter = /[\W\s]$/.test(lastCharacter);

      if (!allowNumbersRegex.test(value)) {
        return { invalidCharacters: true };
      } else if (startsWithInvalidCharacter || /^\s/.test(value)) {
        return { invalidStart: true };
      } else if (endsWithSpecialCharacter) {
        return { invalidEnd: true };
      }

      return null; // If all conditions pass, consider the value valid
    };
  }
  // ======

  minLengthWithoutWhitespace(minLength: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value && control.value.replace(/\s/g, '').length < minLength) {
        return { 'minLengthWithoutWhitespace': { requiredLength: minLength, actualLength: control.value.length } };
      }
      return null;
    };
  }
}
