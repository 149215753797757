import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  HostListener,
  inject,
  NgZone,
} from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { JssOutputService } from 'src/app/_services/jss-output.service';
import { LoadingService } from 'src/app/_services/loading.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { SnackbarService } from 'src/app/_services/snackbar.service';
import { BatchDetailsComponent } from 'src/app/_dialogs/batch-details/batch-details.component';
import { MatDialog } from '@angular/material/dialog';
import { jsonData, tableData } from './sample-input-header-data';
import { RecipeSummaryComponent } from 'src/app/_dialogs/recipe-summary/recipe-summary/recipe-summary.component';
import { AppConfigService } from 'src/app/_services/app-config.service';
// function component
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { interval, map, Observable, startWith, Subject, switchMap, takeWhile } from 'rxjs';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

interface CategoryModel {
  id: number;
  title: string;
  sub_title: string;
}

interface Functions {
  id: number;
  function_title: string;
  function_sub_title: string;
}

interface FunctionColumns {
  column_index: string;
  column_name: string;
}

interface Column {
  name: string;
  isEditing: boolean;
}

// 

@Component({
  selector: 'app-view-output',
  templateUrl: './view-output-new.component.html',
  styleUrls: ['./view-output-new.component.scss'],
})
export class ViewOutputNewComponent implements OnInit {
  // function-query
  @ViewChild('autoAdjustTextArea') autoAdjustTextArea: ElementRef;
  textareaContent: string = '';
  private resizeObserver: ResizeObserver;

  flexibleDivForFunctionQueryView: HTMLElement | null;
  resizableHandleForFunctionQueryView: HTMLElement | null;
  resizeButton: HTMLElement | null;
  initialWidth: any;
  startX: number;
  maxWidth: any;
  newWidth: any;
  // 
  allColumns: Array<any> = []; //column headers
  allFilterColumns: Array<any> = []; //separate list of columns for mat options
  // Alphabetical labels for the new header
  alphabeticalLabels: string[] = [];

  page: number = 1;
  currentPageValue: number;
  size: number = 100;
  dataTablePage: number = 1;
  dataTableSize: number = 100;
  dataTableTotalRows: number;
  totalItems: number;
  total_pages: number;
  activeOrgID: string | null;
  batchID: string;
  disableSave: boolean = false;
  taskName: string;
  inputHeaders: any;
  dataTableRows: any[];
  // selectedCustomTab: string = 'inputData';
  selectedCustomTab: string = 'outputData';
  showVersionHistory: boolean = false;
  totalColsInAScreen: number;
  sortActive: boolean = false;
  sortActiveForColumn: string;
  sortActiveForColumnIndex: string;
  sortObject: any = {};
  filterObject: any = {};
  dataFilterSelectValue: any;
  leftOffset: any;
  topOffset: any;
  screenInnerWidth: number;
  screenHeight: number;
  filterForm = new FormGroup({
    selectACondition: new FormControl(''),
    text: new FormControl(''),
  });
  isBusy: boolean = false;
  isHeaderLoading: boolean;
  isTableContentLoading: boolean;
  sortActiveOnElement: any;
  sortedColumnList: any = {};
  chosenSortOrder: any;
  renameTask: boolean = false;
  taskNameValue: string;
  batchDetails: any;
  isInputDataTableLoading: boolean;
  inputDataTableContent: any[];
  inputDataTableRows: any[];
  inputDataTablePage: number = 1;
  inputDataTableSize: number = 100;
  inputFileDownloadUrl: any;
  sortOptions: any[] = [
    { display: 'Default', value: '', checked: true },
    { display: 'Sort ascending', value: 'asc', checked: false },
    { display: 'Sort descending', value: 'desc', checked: false },
  ];
  defaultSortValue = '';
  currentElement: any;
  isNewVersion: boolean;
  maxCharCountTaskName: number = 100;
  minCharCountTaskName: number = 3;
  isSampleTask: boolean;
  errorType: any;
  tasknameErrorMessageMapping: any = {
    atleast_3_characters_required:
      'Batch name needs to have at least 3 characters',
    max_limit_exceeded: 'Batch name cannot exceed 100 characters',
    no_special_charcters_in_beginning:
      "Batch name can't start with a number or a special character or space",
    only_underscore_allowed_in_between:
      "Batch name can't have any special characters except for '_'",
  };
  showTaskRelatedError: boolean = false;

  // =========
  // *********** Ayushman
  separatorKeysCodes: number[] = [ENTER, COMMA];
  fruitCtrl = new FormControl('');
  filteredFruits: Observable<string[]>;
  fruits: string[] = ['Lemon'];
  allFruits: string[] = ['Apple', 'Lemon', 'Lime', 'Orange', 'Strawberry'];
  pollInterval = 5000; // 5 seconds interval
  pollTimeout = 60000; // 1 min timeout

  @ViewChild('functionField') functionField: ElementRef<HTMLInputElement>;

  @ViewChild('fruitInput') fruitInput: ElementRef<HTMLInputElement>;
  announcer = inject(LiveAnnouncer);
  // ------
  datasetControl = new FormControl('');
  functionControl = new FormControl('');
  outputColumnControlForRename = new FormControl('');


  // categories: string[] = ['Champs-Élysées', 'Lombard CategoryModel', 'Abbey Road', 'Fifth Avenue'];
  // 
  categories: CategoryModel[]= [
    {
      id: 1,
      title: "dataset",
      sub_title: "Create, Remove, Rename, Merge.",
    },
    {
      id: 2,
      title: "normalize",
      sub_title: "Prefix, Suffix, Split, Round, Trim, Replace, Convert, Update",
    },
    {
      id: 3,
      title: "classifier",
      sub_title: "Classify",
    },
    {
      id: 4,
      title: "retrieve",
      sub_title: "Enrichment",
    },
    {
      id: 5,
      title: "custom",
      sub_title: "User defined",
    }
  ];

  functions: Functions[]= [
    {
      id: 1,
      function_title: "Create",
      function_sub_title: "Create new column(s)",
    },
    {
      id: 2,
      function_title: "Remove",
      function_sub_title: "Remove column(s)",
    },
    {
      id: 3,
      function_title: "Rename",
      function_sub_title: "Rename column(s)",
    },
    {
      id: 4,
      function_title: "Merge",
      function_sub_title: "Merge column(s)",
    }
  ];

  functionsColumns: FunctionColumns[]= [
    {
      "column_index": "A",
      "column_name": "MANUFACTURER_PID"
    },
    {
      "column_index": "B",
      "column_name": "Mfg Product Number"
    },
    {
      "column_index": "C",
      "column_name": "Sub_Category1"
    },
    {
      "column_index": "D",
      "column_name": "Website Photo Links"
    },
    {
      "column_index": "E",
      "column_name": "Responsible Party"
    },
    {
      "column_index": "F",
      "column_name": "DESCRIPTION_LONG"
    },
    {
      "column_index": "G",
      "column_name": "INTERNATIONAL_PID (edp)"
    }
  ];
  
  // 
  dataSetList: Observable<any[]>;
  functionList: Observable<any[]>;
  filteredFunctionList: Observable<any[]>;
  outputColumnListForRename: Observable<any[]>;
  filteredOutputHeaderListForMerge: Observable<any[]>;


  selectedCategory: string[] = [];
  isStyleApplied: boolean = false;
  isFunctionOptionSelected: boolean = false;
  selectedFunctions: string[] = [];
  showCreateStructure: boolean = false;
  columns: Column[] = [];
  removableColumns: Column[] = [];
  mergableOutputHeader: Column[] = [];
  // 
  mergableNewColumn: Column = {name: '', isEditing: true};
  mergableDelimiter: Column = {name: '', isEditing: true};
  // 
  oldColumnCreatedByUser: Column = {name: '', isEditing: true};
  newColumnNameForRename: Column = {name: '', isEditing: true};


  // 
  createdColumnName: any = [];
  isCategorySelected: boolean = false;
  showFunctionInput: boolean = false;
  showRemoveStructure: boolean = false;
  showMergeStructure: boolean = false;
  showRenameStructure: boolean;
  normalQueryText: string = '';
  enableFunctionSubmitIcon: boolean = false;
  activeFunctionValue: any;
  newlyCreatedColumns: string[] = [];
  disableAllInputs: boolean = false;
  showFunctionRequestLoadingState: boolean = false;
  activeConversationUID: any;
  showSuccessStateIcons: boolean = false;
  showErrorStateIcons: boolean = false;
  structures: any[] = [];
  outputHeaderStructuresForMerge: any[] = [];
  functionAutoRefs: any[] = []; // Array to hold matAutocomplete references
  removableOutputColumns: any[] = [];
  removableOutputHeadersListForMerge: any[] = [];
  functionBatchColumnsList: any = [];
  functionQueryConversationList: any = [];

  visibleReplies: boolean[] = [];
  visibleReplyIndex: number | null = null;
  showHideDetailsText: boolean = true;
  showRowAffectedText: boolean = false;
  showFunctionQuerySidePanel: boolean = false;
  enableFunctionQueryViewQuestionIcon: boolean = false;
  showFunctionMaximizeIcon: boolean = true;
  showBlureSubmitIcon: boolean = false;
  isFunctionParamsCleared: boolean = false;
  // *********** end


  constructor(
    private jssOutputService: JssOutputService,
    private loader: LoadingService,
    private activatedRoute: ActivatedRoute,
    private snackbar: SnackbarService,
    public dialog: MatDialog,
    public appConfig: AppConfigService,
    public router: Router,
    private ngZone: NgZone
  ) {}

  // function-query 
  ngAfterViewInit() {
    this.setupResizeObserver();
  }
  ngOnDestroy() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }
  private setupResizeObserver() {
    this.ngZone.runOutsideAngular(() => {
      this.resizeObserver = new ResizeObserver(() => {
        this.adjustTextareaHeight();
      });
      this.resizeObserver.observe(this.autoAdjustTextArea.nativeElement);
    });
  }
  // 

  ngOnInit(): void {
    // function-query 
    this.openOverlayForFunctionQuery();

    // =====
    this.initializeDatasetList();
    // 
    // this.dataSetList = this.datasetControl.valueChanges.pipe(
    //   startWith(''),
    //   map(value => this._filter(value || ''))
    // );

    this.functionList = this.functionControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter_function(value || ''))
    );

    this.outputColumnListForRename = this.outputColumnControlForRename.valueChanges.pipe(
      startWith(''),
      map(value => this._filter_columns(value || ''))
    );
    
    // =====

    this.isNewVersion = this.appConfig.getConfig().is_new_version;
    this.screenInnerWidth = window.innerWidth;
    this.screenHeight = screen.height;
    this.isBusy = true;
    this.currentPageValue = this.page;
    // this.loader.setLoading(true);
    this.activeOrgID = localStorage.getItem('currentOrganizationCode');
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.batchID = params['batch_id'];
    });

    // get batch details
    this.getBatchDetails(this.activeOrgID, this.batchID);
    // output data headers
    this.getDataTableHeaders(this.activeOrgID, this.batchID);
    // input data
    this.getInputTableData(this.activeOrgID, this.batchID);

    // this.dataTableRows = tableData.output;
    // this.inputHeaders = jsonData.mapping;
    // this.isBusy = false;
    // this.isHeaderLoading = false;
    // this.isTableContentLoading = false;
    /***
     * get screen width to determine no of cols we can have
     * this is to have flexible width as well as truncate overflowing text
     * */
    let eachColSize = 200;
    this.totalColsInAScreen = Math.round(
      (this.screenInnerWidth - 200) / eachColSize
    );
    /***
     * set required text field validation for contain/does not contain filter in data table
     */
    this.filterForm.get('selectACondition')!.valueChanges.subscribe((val) => {
      if (
        this.filterForm.get('selectACondition')!.value == 'contains' ||
        this.filterForm.get('selectACondition')!.value == 'does not contain'
      ) {
        // for setting validations
        this.filterForm.get('text')!.setValidators(Validators.required);
      } else {
        // for clearing validations
        this.filterForm.get('text')!.clearValidators();
      }
      this.filterForm.get('text')!.updateValueAndValidity();
    });
    // 
    // this.getFunctionBatchColumnsListData();
    this.getFunctionQueryConversationList();

    // 
  }
  // function component 
  adjustTextareaHeight(): void {
    this.ngZone.runOutsideAngular(() => {
      requestAnimationFrame(() => {
        const textarea: HTMLTextAreaElement = this.autoAdjustTextArea.nativeElement;
        textarea.style.height = 'auto';
        textarea.style.height = textarea.scrollHeight + 'px';
      });
    });
  }
  // function-query
  // closeVersionHistory = () => {
  //   document.getElementById('flexibleDiv')!.style.display = 'none';
  // };
  openOverlayForFunctionQuery = () => {
    this.flexibleDivForFunctionQueryView = document.getElementById('flexibleDivForFunctionQueryView');
    this.resizableHandleForFunctionQueryView = document.getElementById('resizableHandleForFunctionQueryView');
    this.resizeButton = document.getElementById('resizeButton');

    if (this.flexibleDivForFunctionQueryView && this.resizableHandleForFunctionQueryView && this.resizeButton) {
      this.resizableHandleForFunctionQueryView.addEventListener('mousedown', this.startResizeFunctionQueryView);
      this.resizeButton.addEventListener('click', this.resizeToFivePercentFunctionQueryView);
    }
  };

  startResizeFunctionQueryView = (event: MouseEvent) => {
    event.preventDefault();
    if (this.flexibleDivForFunctionQueryView) {
      this.initialWidth = this.flexibleDivForFunctionQueryView.offsetWidth;
      this.startX = event.clientX;
      this.maxWidth = window.innerWidth - this.startX + this.initialWidth;
    }
    window.addEventListener('mousemove', this.resizeFunctionQueryView);
    window.addEventListener('mouseup', this.stopResizeFunctionQueryView);
  };
  resizeToFivePercentFunctionQueryView = () => {
    if (this.flexibleDivForFunctionQueryView) {
      this.flexibleDivForFunctionQueryView.style.minWidth = 'auto';
      this.flexibleDivForFunctionQueryView.style.width = '60px';
    }
  };
  resizeFunctionQueryView = (event: MouseEvent) => {
    this.newWidth = this.initialWidth - (event.clientX - this.startX);
    this.newWidth = Math.min(this.newWidth, this.maxWidth);
    if (this.flexibleDivForFunctionQueryView) {
      this.flexibleDivForFunctionQueryView.style.width = this.newWidth + 'px';
    }
  };
  stopResizeFunctionQueryView = () => {
    window.removeEventListener('mousemove', this.resizeFunctionQueryView);
    window.removeEventListener('mouseup', this.stopResizeFunctionQueryView);
  };
  // ============= function-query end
    // *********** Ayushman **************
    showFunctionQueryViewQuestionIconInfo = () => {
      this.enableFunctionQueryViewQuestionIcon = !this.enableFunctionQueryViewQuestionIcon;
    };
    showFunctionQuerySidePanelView = () => {
      this.getFunctionQueryConversationList();
      this.showFunctionQuerySidePanel =!this.showFunctionQuerySidePanel;
    }
    toggleReplyVisibility(index: number) {
      if (this.visibleReplyIndex === index) {
        this.visibleReplyIndex = null;
      } else {
        this.visibleReplyIndex = index;
      }
    }
    // 
    showSuccessReply(index: number) {
      this.visibleReplies[index] = true;
    }
  
    showErrorReply(index: number) {
      this.visibleReplies[index] = true;
    }
    // 
    getFunctionBatchColumnsListData = (datasetCategory: string, functionName: string) => {
      this.jssOutputService.getBatchFunctionColumnList(this.activeOrgID, this.batchID, datasetCategory, functionName).subscribe({
        next: (resp) => {
          // console.log('check column list: ', resp);
          this.functionBatchColumnsList = resp.output_columns;
          // console.log('functionBatchColumnsList: ', this.functionBatchColumnsList);
          
        },
        error: (error) => {
          // console.log('error: ', error);
        }
      });
    }

    getFunctionQueryConversationList = () => {
      this.jssOutputService.getFunctionQueryConversationList(this.activeOrgID, this.batchID).subscribe({
        next: (resp) => {
          // console.log('check column list: ', resp);
          this.functionQueryConversationList = resp.conversations_list;
          console.log('functionQueryConversationList: ', this.functionQueryConversationList);
          
        },
        error: (error) => {
          // console.log('error: ', error);
        }
      });
    }
  // initializeDatasetList = () => {
  //   this.dataSetList = this.datasetControl.valueChanges.pipe(
  //     startWith(''),
  //     map(value => this._filter(value || ''))
  //   );
  // }
  // private _filter(value: string): any[] {
  //   const filterValue = this._normalizeValue(value);
  //   return this.categories.filter(option => this._normalizeValue(option.title).includes(filterValue));
  // }

  private _filter(value: any): CategoryModel[] {
    //console.log('value: ', value);
    // If value is an object, extract the title property
    let filterValue: string;
    if (typeof value === 'object' && value !== null && 'title' in value) {
      filterValue = this._normalizeValue(value.title);
    } else {
      filterValue = this._normalizeValue(value);
    }
  
    return this.categories.filter(option => this._normalizeValue(option.title).includes(filterValue));
  }
  // need to change the autocomplete options list

  private _filter_function(value: any): Functions[] {
    // //console.log('test functions: ', this._normalizeValue(value));
    //console.log('value: ', value);
    
    // 
    let filterValue: string;
    if (typeof value === 'object' && value !== null && 'function_title' in value) {
      filterValue = this._normalizeValue(value.function_title);
      //console.log('1');
      
    } else {
      filterValue = this._normalizeValue(value);
      //console.log('2');

    }
    // 
    //console.log(this.functions.filter(option => this._normalizeValue(option.function_title).includes(filterValue)));
    
    // const filterValue = this._normalizeValue(value);
    return this.functions.filter(option => this._normalizeValue(option.function_title).includes(filterValue));
}


  
  
private _normalizeValue(value: any): string {
  // //console.log('3');

  if (typeof value !== 'string') {
    console.error('Expected string but received:', value);
    return '';
  }
  // //console.log('4');

  return value.toLowerCase().replace(/\s/g, '');
}



  
  onCategorySelection(event: any, inputElement: HTMLInputElement) {
    this.disableAllInputs = false;
    this.isFunctionParamsCleared = false;

    const selectedCategoryOption = event.option.value.title;
    //console.log('selectedCategoryOption: ', selectedCategoryOption);
  
    // Using a patchValue to ensure the form control value change is detected
    this.datasetControl.patchValue(selectedCategoryOption);
    this.isCategorySelected = true;
    this.isFunctionOptionSelected = false;
  
    if (inputElement) {
      inputElement.value = selectedCategoryOption;
      const width = `calc(${inputElement.value.length}ch + 0px)`;
      inputElement.style.width = inputElement.value.length === 0 ? '4px' : width;
    } else {
      console.error('inputElement is undefined for category');
    }
  
    
  
    switch (selectedCategoryOption) {
      case 'dataset':
        this.showFunctionInput = true;
        this.functionControl.reset();
        this.functionControl.setValue('');

        this.columns = [];
        this.removableColumns = [];
        this.mergableOutputHeader = [];
        this.mergableNewColumn.name = '';
        this.mergableNewColumn.isEditing = true;
        this.oldColumnCreatedByUser.name = '';
        this.oldColumnCreatedByUser.isEditing = true;
        this.newColumnNameForRename.name = '';
        this.newColumnNameForRename.isEditing = true;
        this.showCreateStructure = false;
        this.showRemoveStructure = false;
        this.showRenameStructure = false;
        this.showMergeStructure = false;
        // 
        this.showBlureSubmitIcon = true;
        this.showFunctionMaximizeIcon = false;
        this.enableFunctionSubmitIcon = false;
        this.showFunctionRequestLoadingState = false;
        this.showSuccessStateIcons = false;
        this.showErrorStateIcons = false;
        // 
        break;
      case 'normalize':
      case 'classifier':
      case 'retrieve':
      case 'custom':
        this.showFunctionInput = false;
        break;
      default:
        this.showFunctionInput = false;
        break;
    }
  
    // Reinitialize the observable pipeline to ensure it listens to value changes
    this.initializeDatasetList();
  }
  
  initializeDatasetList = () => {
    this.dataSetList = this.datasetControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || ''))
    );
  }

  // sendFunctionQuery = () => {
  //   this.disableAllInputs = true;
  //   this.showFunctionRequestLoadingState = true;
  //   this.datasetControl.disable();
  //   this.functionControl.disable();

  //   //console.log('activeFunctionValue: ', this.activeFunctionValue);
  //   //console.log('created columns: ', this.columns);
  //   switch (this.activeFunctionValue) {
  //     case 'Create':
  //       // 
  //       this.newlyCreatedColumns = this.columns.map(col => col.name);
  //       //console.log('newlyCreatedColumns: ', this.newlyCreatedColumns);
  //       let createFunctionObj = {
  //         new_columns: this.newlyCreatedColumns
  //       }
  //       this.jssOutputService.getDatasetFunctionResult(this.activeOrgID, this.batchID, this.activeFunctionValue, createFunctionObj).subscribe(
  //         {
  //           next: (resp) => {
  //             //console.log('create function resp: ', resp);
  //             this.activeConversationUID = resp.conversation_uid;
  //               // polling function will come here 
  //               this.pollingFunction();
  //               // 
              
  //           },
  //           error: (error: HttpErrorResponse) => {
  //             this.snackbar.showSnackbar(error.error.detail, '', 'error');
  //           },
  //         }
  //       );
  //       // 
  //       break;
    
  //     default:
  //       break;
  //   }

  // }

  // 
  // pollingFunction = () => {
  //   interval(5000) // 5000 milliseconds = 5 seconds interval
  //     .pipe(
  //       switchMap(() => this.jssOutputService.fetchConversationStatus(this.activeOrgID, this.activeConversationUID)),
  //       takeWhile(response => response.conversation_status === 'in_progress', true) // Continue polling while status is 'in_progress'
  //     )
  //     .subscribe({
  //       next: response => {
  //         //console.log('polling response: ', response);
  //         //console.log('Conversation status:', response.conversation_status);
  //         // Optionally, handle response here
  //         this.showFunctionRequestLoadingState = false;
  //         this.showSuccessStateIcons = true;
  //         this.showErrorStateIcons = false;

  //         // Continue polling if needed
  //       },
  //       error: error => {
  //         this.showSuccessStateIcons = false;
  //         this.showErrorStateIcons = true;
  //         this.showFunctionRequestLoadingState = false;


  //         console.error('Polling error:', error);
  //       }
  //     });
  // }

  checkingEmptyColumnName(array: any): boolean {
    // Check if any column has an empty name
    return array.every((column: any) => column.name.trim().length > 0);
  }

  disableAllInputsWhileExecutionIsInProgress = () => {
    // 

    this.disableAllInputs = true;
    this.datasetControl.disable();
    this.functionControl.disable();



    // 
  }

  sendFunctionQuery = () => {
    this.showFunctionRequestLoadingState = true;
    this.enableFunctionSubmitIcon = false;
    // 
    // this.disableAllInputs = true;
    // this.datasetControl.disable();
    // this.functionControl.disable();
    this.disableFormControls();

    // 
  
    //console.log('activeFunctionValue: ', this.activeFunctionValue);
    //console.log('created columns: ', this.columns);
    
    switch (this.activeFunctionValue) {
      case 'Create':
        this.newlyCreatedColumns = this.columns.filter(item => item.name!=='').map(item => item.name);
        let createFunctionObj = {
          new_columns: this.newlyCreatedColumns
        };

        // 
        let currentActiveCategoryForCreate = 'dataset';
        let currentActiveFunctionForCreate = 'create';
            this.jssOutputService.getDatasetFunctionResult(this.activeOrgID, this.batchID, currentActiveCategoryForCreate, currentActiveFunctionForCreate, createFunctionObj).subscribe({
              next: (resp) => {
                this.activeConversationUID = resp.conversation_uid;
                this.pollingFunction().subscribe({
                  next: (pollingResp) => {
                    this.getFunctionQueryConversationList();
                    this.enableFormControls();
                    this.resetFunctionParams();
                    this.getBatchDetails(this.activeOrgID, this.batchID);
                    this.getDataTableHeaders(this.activeOrgID, this.batchID);
                    this.getInputTableData(this.activeOrgID, this.batchID); 
                    // 
                    this.showFunctionMaximizeIcon = true;
                    this.enableFunctionSubmitIcon = false;
                    this.showBlureSubmitIcon = false;
                    this.showFunctionRequestLoadingState = false;
                    this.showSuccessStateIcons = false;
                    this.showErrorStateIcons = false;
                    // 
                  },
                  error: (pollingError) => {
                    this.enableFormControls();
                    this.getFunctionQueryConversationList();
                    this.showFunctionMaximizeIcon = true;
                    this.showBlureSubmitIcon = true;
                    this.showFunctionRequestLoadingState = false;
                    this.enableFunctionSubmitIcon = false;
                    this.showErrorStateIcons = false;
                    this.showSuccessStateIcons = false;
                    this.snackbar.showSnackbar(`${pollingError.message}: ${pollingError.statusText}`, '', 'error');
                  }
                });
              },
              error: (error: HttpErrorResponse) => {
                // 
                this.showFunctionMaximizeIcon = true;
                this.showBlureSubmitIcon = true;
                this.showFunctionRequestLoadingState = false;
                this.showSuccessStateIcons = false;
                this.showErrorStateIcons = false;
                this.enableFunctionSubmitIcon = false;
                this.getFunctionQueryConversationList();
                this.snackbar.showSnackbar(error.error.detail, '', 'error');
                this.enableFormControls(); // Reset form state on error
                // 
              },
            });
        // 

       
        break;
      
      case 'Remove':
        // =======================
        let removeFunctionObj = {
          output_columns: this.removableOutputColumns
        };
        // console.log('removableOutputColumns: ', this.removableOutputColumns);

        // if (this.removableOutputColumns.length==1 && this.removableOutputColumns[0]=='') {
        //   console.log('only one and it is empty');
        //   // 
        //   this.showBlureSubmitIcon = true;
        //   this.enableFunctionSubmitIcon = false;
        //   this.showFunctionRequestLoadingState = false;
        //   this.showFunctionMaximizeIcon = false;
        //   this.showSuccessStateIcons = false;
        //   this.showErrorStateIcons = false;
        //   this.snackbar.showSnackbar(`You are trying to remove an empty column`, '', 'error');

        //   // 
        //   return;
        // }
        // console.log('test this step');
        
        
        let currentActiveCategoryForRemove = 'dataset';
          let currentActiveFunctionForRemove = 'remove';
  
        this.jssOutputService.getDatasetFunctionResult(this.activeOrgID, this.batchID, currentActiveCategoryForRemove, currentActiveFunctionForRemove, removeFunctionObj).subscribe({
          next: (resp) => {
            // this.getFunctionQueryConversationList();
            this.activeConversationUID = resp.conversation_uid;
            this.pollingFunction().subscribe({
              next: (pollingResp) => {
                this.enableFormControls();
                this.getFunctionQueryConversationList();

                
                this.resetFunctionParams();
                this.getBatchDetails(this.activeOrgID, this.batchID);
                this.getDataTableHeaders(this.activeOrgID, this.batchID);
                this.getInputTableData(this.activeOrgID, this.batchID); 

                this.getFunctionBatchColumnsListData('dataset', 'remove');

                // 
                this.showFunctionMaximizeIcon = true;
                this.enableFunctionSubmitIcon = false;
                this.showBlureSubmitIcon = false;
                this.showFunctionRequestLoadingState = false;
                this.showSuccessStateIcons = false;
                this.showErrorStateIcons = false;
                // 
              },
              error: (pollingError) => {
                this.enableFormControls();
                this.getFunctionQueryConversationList();
                this.showFunctionMaximizeIcon = true;
                this.showBlureSubmitIcon = true;
                this.showFunctionRequestLoadingState = false;
                this.enableFunctionSubmitIcon = false;
                this.showErrorStateIcons = false;
                this.showSuccessStateIcons = false;
                this.snackbar.showSnackbar(`${pollingError.message}: ${pollingError.statusText}`, '', 'error');

              }
            });
          },
          error: (error: HttpErrorResponse) => {
            // 
            this.showFunctionMaximizeIcon = true;
            this.showBlureSubmitIcon = true;
            this.showFunctionRequestLoadingState = false;
            this.showSuccessStateIcons = false;
            this.showErrorStateIcons = false;
            this.enableFunctionSubmitIcon = false;
            this.getFunctionQueryConversationList();
            this.snackbar.showSnackbar(error.error.detail, '', 'error');
            this.enableFormControls(); // Reset form state on error
            // 

          },
        });
        // =======================
        break;
      case 'Rename':  
        // =======================
        let renameFunctionObj = {
          output_column: this.outputColumnControlForRename.value,
		      new_column: this.newColumnNameForRename.name
        };

        let currentActiveCategoryForRename = 'dataset';
        let currentActiveFunctionForRename = 'rename';
  
        this.jssOutputService.getDatasetFunctionResult(this.activeOrgID, this.batchID, currentActiveCategoryForRename, currentActiveFunctionForRename, renameFunctionObj).subscribe({
          next: (resp) => {
            this.getFunctionQueryConversationList();
            this.activeConversationUID = resp.conversation_uid;
            this.pollingFunction().subscribe({
              next: (pollingResp) => {
                this.enableFormControls();
                this.getFunctionQueryConversationList();

                
                this.resetFunctionParams();
                this.getBatchDetails(this.activeOrgID, this.batchID);
                this.getDataTableHeaders(this.activeOrgID, this.batchID);
                this.getInputTableData(this.activeOrgID, this.batchID); 
                this.getFunctionBatchColumnsListData('dataset', 'rename');

                // 
                this.showFunctionMaximizeIcon = true;
                this.enableFunctionSubmitIcon = false;
                this.showBlureSubmitIcon = false;
                this.showFunctionRequestLoadingState = false;
                this.showSuccessStateIcons = false;
                this.showErrorStateIcons = false;
                // 
              },
              error: (pollingError) => {
                this.enableFormControls();
                this.getFunctionQueryConversationList();
                this.showFunctionMaximizeIcon = true;
                this.showBlureSubmitIcon = true;
                this.showFunctionRequestLoadingState = false;
                this.enableFunctionSubmitIcon = false;
                this.showErrorStateIcons = false;
                this.showSuccessStateIcons = false;
                this.snackbar.showSnackbar(`${pollingError.message}: ${pollingError.statusText}`, '', 'error');

              }
            });
          },
          error: (error: HttpErrorResponse) => {
            // 
            this.showFunctionMaximizeIcon = true;
            this.showBlureSubmitIcon = true;
            this.showFunctionRequestLoadingState = false;
            this.showSuccessStateIcons = false;
            this.showErrorStateIcons = false;
            this.enableFunctionSubmitIcon = false;
            this.getFunctionQueryConversationList();
            this.snackbar.showSnackbar(error.error.detail, '', 'error');
            this.enableFormControls(); // Reset form state on error
            // 

          },
        });
        // =======================

        break;
      case 'Merge': 
        // =======================
        
        let mergeFunctionObj = {
          output_columns: this.removableOutputHeadersListForMerge,
          new_column: this.mergableNewColumn.name,
          delimiter: this.mergableDelimiter.name

        };

        let currentActiveCategoryForMerge = 'dataset';
        let currentActiveFunctionForMerge = 'merge';
  
        this.jssOutputService.getDatasetFunctionResult(this.activeOrgID, this.batchID, currentActiveCategoryForMerge, currentActiveFunctionForMerge, mergeFunctionObj).subscribe({
          next: (resp) => {
            this.activeConversationUID = resp.conversation_uid;
            this.pollingFunction().subscribe({
              next: (pollingResp) => {
                this.getFunctionQueryConversationList();
                this.enableFormControls();
                
                this.resetFunctionParams();
                this.getFunctionQueryConversationList();
                this.getBatchDetails(this.activeOrgID, this.batchID);
                this.getDataTableHeaders(this.activeOrgID, this.batchID);
                this.getInputTableData(this.activeOrgID, this.batchID); 
                this.getFunctionBatchColumnsListData('dataset', 'merge');

                // 
                this.showFunctionMaximizeIcon = true;
                this.enableFunctionSubmitIcon = false;
                this.showBlureSubmitIcon = false;
                this.showFunctionRequestLoadingState = false;
                this.showSuccessStateIcons = false;
                this.showErrorStateIcons = false;
                // 
              },
              error: (pollingError) => {
                this.enableFormControls();
                this.getFunctionQueryConversationList();
                this.showFunctionMaximizeIcon = true;
                this.showBlureSubmitIcon = true;
                this.showFunctionRequestLoadingState = false;
                this.enableFunctionSubmitIcon = false;
                this.showErrorStateIcons = false;
                this.showSuccessStateIcons = false;
                this.snackbar.showSnackbar(`${pollingError.message}: ${pollingError.statusText}`, '', 'error');
              }
            });
          },
          error: (error: HttpErrorResponse) => {
            // 
            this.showFunctionMaximizeIcon = true;
            this.showBlureSubmitIcon = true;
            this.showFunctionRequestLoadingState = false;
            this.showSuccessStateIcons = false;
            this.showErrorStateIcons = false;
            this.enableFunctionSubmitIcon = false;
            this.getFunctionQueryConversationList();
            this.snackbar.showSnackbar(error.error.detail, '', 'error');
            this.enableFormControls(); // Reset form state on error
            // 

          },
        });

        // =======================
        break;
      default:
        break;
    }
  }
  
  enableFormControls = () => {
    this.disableAllInputs = false;
    this.datasetControl.enable();
    this.functionControl.enable();
    this.outputColumnControlForRename.enable();
  }

  disableFormControls = () => {
    this.disableAllInputs = true;
    this.datasetControl.disable();
    this.functionControl.disable();
    this.outputColumnControlForRename.disable();
  // this.functionColumnControl.disable();
  // this.outputHeaderControlForMerge.disable();
  }
  

  pollingFunction = (): Observable<any> => {
    const successSubject = new Subject<any>(); // Subject to emit success value
  
    interval(3000) // 3000 milliseconds = 3 seconds interval
      .pipe(
        switchMap(() => this.jssOutputService.fetchConversationStatus(this.activeOrgID, this.activeConversationUID)),
        takeWhile(response => response.conversation_status === 'in_progress', true) // Continue polling while status is 'in_progress'
      )
      .subscribe({
        next: response => {
          //console.log('polling response: ', response);
          //console.log('Conversation status:', response.conversation_status);
  
          if (response.conversation_status !== 'in_progress') {
            // Polling completed, emit success value
            this.snackbar.showSnackbar(response.conversation_reply, '', 'success');
  
            successSubject.next(response); // Emit success response
            successSubject.complete(); // Complete the subject
          }
        },
        error: error => {
          // Handle polling error
          
  
          console.error('Polling error:', error);
          successSubject.error(error); // Emit error if needed
        }
      });
  
    return successSubject.asObservable(); // Return Observable that the caller can subscribe to
  }
  // 
  
  // addStructure() {
  //   const newStructure = {
  //     showFunctionInput: true,
  //     disableAllInputs: false,
  //     functionControl: new FormControl(''),
  //     isFunctionOptionSelected: false
  //   };
  //   this.structures.push(newStructure);
  //   this.functionAutoRefs.push('functionAuto'); // Push a new reference for matAutocomplete
  //   //console.log(this.structures);
    
  // }

  addStructure() {
    const functionColumnControl = new FormControl('');
    const filteredFunctionList = functionColumnControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter_columns(value || ''))
    );

    const newStructure = {
      showFunctionInput: true,
      disableAllInputs: false,
      functionColumnControl,
      filteredFunctionList,
      isFunctionOptionSelected: false
    };

    this.structures.push(newStructure);
  }

  // =======
  addOutputHeaderStructureForMerge() {
    const outputHeaderControlForMerge = new FormControl('');
    const filteredOutputHeaderListForMerge = outputHeaderControlForMerge.valueChanges.pipe(
      startWith(''),
      map(value => this._filter_columns(value || ''))
    );

    const outputHeaderStructureForMerge = {
      showFunctionInput: true,
      disableAllInputs: false,
      outputHeaderControlForMerge,
      filteredOutputHeaderListForMerge,
      isFunctionOptionSelected: false
    };

    this.outputHeaderStructuresForMerge.push(outputHeaderStructureForMerge);
  }
  // =======

  // private _filterFunction(value: string): any[] {
  //   const filterValue = value.toLowerCase();
  //   return this.functionList.filter(option =>
  //     option.function_title.toLowerCase().includes(filterValue)
  //   );
  // }

  checkSelection(event: any, structure: any, functionField: any) {
    structure.isFunctionOptionSelected = true;
    const selectedValue = event.option.value;
    const columnWidth = `calc(${selectedValue.trim().length}ch + 22px)`;
    // const columnWidth = `${selectedValue.length + 1}ch + 1px`; // +1 for padding
    functionField.style.width = columnWidth;
    //console.log(this.structures);
    this.extractOutputColumnName();
  }

  // 

  checkSelectionForMerge(event: any, structure: any, functionField: any) {
    structure.isFunctionOptionSelected = true;
    const selectedValue = event.option.value;
    const columnWidth = `calc(${selectedValue.trim().length}ch + 22px)`;
    // const columnWidth = `${selectedValue.length + 1}ch + 1px`; // +1 for padding
    functionField.style.width = columnWidth;
    // //console.log(this.structures);
    this.extractOutputHeadersForMerge();
    // 
    if (this.outputHeaderStructuresForMerge.length > 1 && this.mergableNewColumn.name != '' && this.mergableDelimiter.name != '') {
      this.enableFunctionSubmitIcon = true;
      this.showBlureSubmitIcon = false;
      this.showFunctionRequestLoadingState = false;
      this.showSuccessStateIcons = false;
      this.showErrorStateIcons = false;
    } else {
      this.showBlureSubmitIcon = true;
      this.enableFunctionSubmitIcon = false;
      this.showFunctionRequestLoadingState = false;
      this.showSuccessStateIcons = false;
      this.showErrorStateIcons = false;
    }
    // 
  }

  // 
  checkForEmptyValueForRename = () => {
    if (this.outputColumnControlForRename.value !='' && this.newColumnNameForRename.name != '') {
      this.enableFunctionSubmitIcon = true;
      this.showBlureSubmitIcon = false;
      this.showFunctionRequestLoadingState = false;
      this.showSuccessStateIcons = false;
      this.showErrorStateIcons = false;
    } else {
      this.showBlureSubmitIcon = true;
      this.enableFunctionSubmitIcon = false;
      this.showFunctionRequestLoadingState = false;
      this.showSuccessStateIcons = false;
      this.showErrorStateIcons = false;
    }
  }
  checkOutputColumnSelectionForRename(event: any, functionField: any) {
    // structure.isFunctionOptionSelected = true;
    const selectedValue = event.option.value;
    const columnWidth = `calc(${selectedValue.trim().length}ch + 22px)`;
    // const columnWidth = `${selectedValue.length + 1}ch + 1px`; // +1 for padding
    functionField.style.width = columnWidth;
    // //console.log(this.structures);
    // this.extractOutputColumnName();
    // 
    if (selectedValue!='' && this.newColumnNameForRename.name != '') {
      this.enableFunctionSubmitIcon = true;
      this.showBlureSubmitIcon = false;
      this.showFunctionRequestLoadingState = false;
      this.showSuccessStateIcons = false;
      this.showErrorStateIcons = false;
    } else {
      this.showBlureSubmitIcon = true;
      this.enableFunctionSubmitIcon = false;
      this.showFunctionRequestLoadingState = false;
      this.showSuccessStateIcons = false;
      this.showErrorStateIcons = false;
    }
    

    // 
  }
  // 

  extractOutputColumnName = () => {
    this.removableOutputColumns = this.structures.map(el => el.functionColumnControl.value);
    //console.log(this.removableOutputColumns);
    
    if (this.removableOutputColumns.length!=0 && this.removableOutputColumns[0]!='') {
      this.enableFunctionSubmitIcon = true;
      this.showFunctionMaximizeIcon = false;
      this.showBlureSubmitIcon = false;
      this.showFunctionRequestLoadingState = false;
      this.showSuccessStateIcons = false;
      this.showErrorStateIcons = false;
    } else {
      this.showBlureSubmitIcon = true;
      this.showFunctionMaximizeIcon = false;
      this.enableFunctionSubmitIcon = false;
      this.showFunctionRequestLoadingState = false;
      this.showSuccessStateIcons = false;
      this.showErrorStateIcons = false;
    }
  }

  extractOutputHeadersForMerge = () => {
    this.removableOutputHeadersListForMerge = this.outputHeaderStructuresForMerge.map(el => el.outputHeaderControlForMerge.value);
    //console.log(this.removableOutputHeadersListForMerge);
    
    if (this.removableOutputHeadersListForMerge.length!=0) {
      this.enableFunctionSubmitIcon = true;
      this.showFunctionRequestLoadingState = false;
      this.showSuccessStateIcons = false;
      this.showErrorStateIcons = false;
    } else {
      this.enableFunctionSubmitIcon = false;
      this.showFunctionRequestLoadingState = false;
      this.showSuccessStateIcons = false;
      this.showErrorStateIcons = false;
    }
  }

  // 
private _filter_columns(value: any): FunctionColumns[] {
  //console.log('value: ', value);
  let filterColumnValue: string;
  if (typeof value === 'object' && value !== null && 'column_name' in value) {
    filterColumnValue = this._normalizeValue(value.column_name);
  } else {
    filterColumnValue = this._normalizeValue(value);
  }
  // 
  // console.log('check for empty functionBatchColumnsList: ', this.functionBatchColumnsList);
  // if (this.functionBatchColumnsList.length==0) {
  //   this.snackbar.showSnackbar('You have not created any columns yet, please create columns to perform any function operations', '', 'error');

  // }
  
  // 
  return this.functionBatchColumnsList.filter((option: { column_index: any; column_name: any; }) => 
    this._normalizeValue(option.column_index).includes(filterColumnValue) ||
    this._normalizeValue(option.column_name).includes(filterColumnValue) 
  );
}
// private _filter_columns(value: string): FunctionColumns[] {
//   const filterValue = value.toLowerCase();
//   return this.functionBatchColumnsList.filter(option => 
//     option.column_index.toLowerCase().includes(filterValue) ||
//     option.column_name.toLowerCase().includes(filterValue)
//   );
// }
// 

  checkValueChange(event: any, structure: any, index: any, functionField: any) {
    const inputValue = event.target.value;
    // 
    functionField.style.width = '110px';
    // 
    //console.log('inputValue: ', inputValue);
    
    this.structures[index].filteredFunctionList = this.structures[index].functionColumnControl.valueChanges.pipe(
      startWith(inputValue),
      map(value => this._filter_columns(value || ''))
    );
    this.filteredFunctionList = this.structures[index].filteredFunctionList;
  }

  // 
  checkValueChangeForMerge(event: any, structure: any, index: any) {
    const inputValue = event.target.value;
    //console.log('inputValue: ', inputValue);
    
    this.outputHeaderStructuresForMerge[index].filteredOutputHeaderListForMerge = this.outputHeaderStructuresForMerge[index].outputHeaderControlForMerge.valueChanges.pipe(
      startWith(inputValue),
      map(value => this._filter_columns(value || ''))
    );
    this.filteredOutputHeaderListForMerge = this.outputHeaderStructuresForMerge[index].filteredOutputHeaderListForMerge;
  }
  // 

  checkRenameOutputColumnValueChange(event: any) {
    const inputValue = event.target.value;
    //console.log('inputValue: ', inputValue);

    if (inputValue!='' && this.newColumnNameForRename.name != '') {
      this.enableFunctionSubmitIcon = true;
      this.showBlureSubmitIcon = false;
      this.showFunctionRequestLoadingState = false;
      this.showSuccessStateIcons = false;
      this.showErrorStateIcons = false;
    } else {
      this.showBlureSubmitIcon = true;
      this.enableFunctionSubmitIcon = false;
      this.showFunctionRequestLoadingState = false;
      this.showSuccessStateIcons = false;
      this.showErrorStateIcons = false;
    }
    
    this.outputColumnListForRename = this.outputColumnControlForRename.valueChanges.pipe(
      startWith(inputValue),
      map(value => this._filter_columns(value || ''))
    );
    // this.filteredFunctionList = this.structures[index].filteredFunctionList;
  }

  // 

  checkAllParamsForMerge = () => {
    // 
    if (this.outputHeaderStructuresForMerge.length > 1 && this.mergableNewColumn.name != '' && this.mergableDelimiter.name != '') {
      this.enableFunctionSubmitIcon = true;
      this.showBlureSubmitIcon = false;
      this.showFunctionRequestLoadingState = false;
      this.showSuccessStateIcons = false;
      this.showErrorStateIcons = false;
    } else {
      this.showBlureSubmitIcon = true;
      this.enableFunctionSubmitIcon = false;
      this.showFunctionRequestLoadingState = false;
      this.showSuccessStateIcons = false;
      this.showErrorStateIcons = false;
    }
    // 
  }

  preventDefaultActionOnEnter = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  }
  
  onFunctionSelection(event: any, inputElement: HTMLInputElement) {
    this.isFunctionOptionSelected = true;
    this.disableAllInputs = false;
    const testInputElement = this.functionField?.nativeElement;
    const selectedFunction = event.option.value.function_title;
    this.activeFunctionValue = selectedFunction;
    this.functionControl.patchValue(selectedFunction);
    if (testInputElement) {
      testInputElement.value = selectedFunction;
        const width = `calc(${testInputElement.value.length}ch + 2px)`;
        testInputElement.style.width = testInputElement.value.length === 0 ? '4px' : width;
    } else {
        console.error('inputElement is undefined');
    }
    switch (selectedFunction) {
        case 'Create':
          this.showCreateStructure = true;

          this.showRemoveStructure = false;
          this.showRenameStructure = false;
          this.showMergeStructure = false;

          this.columns = [];
          this.removableColumns = [];
          this.structures = [];
          this.mergableOutputHeader = [];
          this.outputHeaderStructuresForMerge = [];

          this.addColumn();
          


          break;
        case 'Remove':
          this.getFunctionBatchColumnsListData('dataset', 'remove');

          this.showRemoveStructure = true;
          this.showCreateStructure = false;
          this.showRenameStructure = false;
          this.showMergeStructure = false;

          this.columns = [];
          this.removableColumns = [];
          this.structures = [];
          this.mergableOutputHeader = [];
          this.outputHeaderStructuresForMerge = [];
          this.addStructure();


          break;
        case 'Rename':
          this.getFunctionBatchColumnsListData('dataset', 'rename');

          this.showRenameStructure = true;
          this.showCreateStructure = false;
          this.showRemoveStructure = false;
          this.showMergeStructure = false;

          this.columns = [];
          this.removableColumns = [];
          this.structures = [];
          this.outputHeaderStructuresForMerge = [];
          this.mergableOutputHeader = [];

          this.oldColumnCreatedByUser.name = '';
          this.oldColumnCreatedByUser.isEditing = true;
          this.newColumnNameForRename.name = '';
          this.newColumnNameForRename.isEditing = true;
          this.outputColumnControlForRename.reset();
          this.outputColumnControlForRename.setValue('');

          break;
        case 'Merge':
          this.getFunctionBatchColumnsListData('dataset', 'merge');

          this.showMergeStructure = true;
          this.showCreateStructure = false;
          this.showRemoveStructure = false;
          this.showRenameStructure = false;

          this.columns = [];
          this.removableColumns = [];
          this.structures = [];
          this.outputHeaderStructuresForMerge = [];
          this.mergableOutputHeader = [];

          this.mergableNewColumn.name = '';
          this.mergableNewColumn.isEditing = true;
          this.mergableDelimiter.name = '';
          this.mergableDelimiter.isEditing = true;
          this.addOutputHeaderStructureForMerge();


          break;
        default:
          this.showFunctionInput = true;
          this.showRemoveStructure = false;
          this.showCreateStructure = false;
          this.showMergeStructure = false;
          this.showRenameStructure = false;


          break;
      }
    // 
}

sendInputValueAsNormalQuery = () => {
  //console.log('check normal query text: ', this.normalQueryText);
  
}
  
adjustWidth(event: Event, selectionType: string): void {
  // 
  //console.log('check selectionType: ', selectionType);
  
  
  // 
  const inputElement = event.target as HTMLInputElement;
  console.log('dataset value: ', inputElement.value);
  console.log('dataset value length: ', inputElement.value.length);


  const width = `calc(${inputElement.value.length}ch + 2px)`;
  // 
  switch (selectionType) {
    case 'category':
      if (inputElement.value.length==0) {
        this.showFunctionInput = false;
        this.showCreateStructure = false;
        this.showRemoveStructure = false;
        this.showMergeStructure = false;
        this.showRenameStructure = false;
        inputElement.style.width = '350px';
        this.enableFunctionSubmitIcon = false;
        this.showSuccessStateIcons = false;
        this.showFunctionRequestLoadingState = false;
        this.showErrorStateIcons = false;
        this.showFunctionMaximizeIcon = true;
        this.showBlureSubmitIcon = false;


      } else {
        // 
        this.showBlureSubmitIcon = true;

        //console.log('check normal query: ', inputElement.value);
        this.normalQueryText = inputElement.value;
        
        // 
        inputElement.style.width = inputElement.value.length === 0 ? '4px' : width;
      }
      break;
    case 'function':
      if (inputElement.value.length==0) {
        this.showCreateStructure = false;
        this.showRemoveStructure = false;
        this.showMergeStructure = false;
        this.showRenameStructure = false;
        inputElement.style.width = '52px';
        this.isFunctionOptionSelected = false;
        this.showBlureSubmitIcon = true;
        this.enableFunctionSubmitIcon = false;


      } else {
      inputElement.style.width = inputElement.value.length === 0 ? '4px' : width;
      }
      break;
  
    default:
      break;
  }
  // 
}

adjustWidthForEmptyInput(inputElement: HTMLInputElement): void {
  inputElement.style.width = '4px'; // Reset to initial width
}

addColumn(): void {
  if (this.columns.length < 5) {
    this.columns.push({ name: '', isEditing: true });
  }
}

addRemovableColumn(): void {
  if (this.removableColumns.length < 5) {
    this.removableColumns.push({ name: '', isEditing: true });
  }
}

addMergableOutputHeader(): void {
  if (this.mergableOutputHeader.length < 5) {
    this.mergableOutputHeader.push({ name: '', isEditing: true });
  }
}



  

  saveThisAsAColumnName(event: any, name: string): void {
    event.preventDefault();
    //console.log('Enter key pressed...');
    // Save the column name and set isEditing to false
    const column = this.columns.find(col => col.name === name);
    if (column) {
      column.isEditing = false;
      // 

      // 
    }
    //console.log(`Column name saved: ${name}`);
    //console.log(this.columns);
    // 
    if (this.columns.length!=0) {
      this.enableFunctionSubmitIcon = true;
      this.showBlureSubmitIcon = false;
      this.showFunctionRequestLoadingState = false;
      this.showSuccessStateIcons = false;
      this.showErrorStateIcons = false;
      this.showFunctionMaximizeIcon = false;
// this.enableFunctionSubmitIcon = true;
    } else {
      this.enableFunctionSubmitIcon = false;
      this.showBlureSubmitIcon = true;

    }
    // 
  }

  saveThisAsARemovableColumnName(event: any, name: string): void {
    event.preventDefault();
    //console.log('Enter key pressed...');
    // Save the column name and set isEditing to false
    const removableColumn = this.removableColumns.find(col => col.name === name);
    if (removableColumn) {
      removableColumn.isEditing = false;
    }
    //console.log(`removableColumn name saved: ${name}`);
    //console.log(this.removableColumns);
  }

  saveThisAsMergableOutputHeader(event: any, name: string): void {
    event.preventDefault();
    //console.log('Enter key pressed...');
    // Save the column name and set isEditing to false
    const outputHeader = this.mergableOutputHeader.find(header => header.name === name);
    if (outputHeader) {
      outputHeader.isEditing = false;
    }
    //console.log(`outputHeader name saved: ${name}`);
    //console.log(this.mergableOutputHeader);
  }

  saveThisAsMergableNewColumn(event: any, name: string): void {
    event.preventDefault();
    //console.log('Enter key pressed...');
    // Save the column name and set isEditing to false
    // const outputHeader = this.mergableOutputHeader.find(header => header.name === name);
    this.mergableNewColumn.name = name;
    if (this.mergableNewColumn.name) {
      this.mergableNewColumn.isEditing = false;
    }
    this.checkAllParamsForMerge();
    //console.log(`mergableNewColumn name saved: ${name}`);
    //console.log(this.mergableNewColumn);
  }

  saveThisAsOldColumnNameCreatedByUser(event: any, name: string): void {
    event.preventDefault();
    //console.log('Enter key pressed...');
    // Save the column name and set isEditing to false
    // const outputHeader = this.mergableOutputHeader.find(header => header.name === name);
    this.oldColumnCreatedByUser.name = name;
    if (this.oldColumnCreatedByUser.name) {
      this.oldColumnCreatedByUser.isEditing = false;
    }
    //console.log(`oldColumnCreatedByUser name saved: ${name}`);
    //console.log(this.oldColumnCreatedByUser);
  }

  saveThisAsNewColumnNameForRename(event: any, name: string): void {
    event.preventDefault();
    //console.log('Enter key pressed...');
    // Save the column name and set isEditing to false
    // const outputHeader = this.mergableOutputHeader.find(header => header.name === name);
    this.newColumnNameForRename.name = name;
    if (this.newColumnNameForRename.name) {
      this.newColumnNameForRename.isEditing = false;
    }
    // 
    if (this.outputColumnControlForRename.value !='' && this.newColumnNameForRename.name != '') {
      this.enableFunctionSubmitIcon = true;
      this.showFunctionRequestLoadingState = false;
      this.showSuccessStateIcons = false;
      this.showErrorStateIcons = false;
    } else {
      this.enableFunctionSubmitIcon = false;
      this.showFunctionRequestLoadingState = false;
      this.showSuccessStateIcons = false;
      this.showErrorStateIcons = false;
    }
    // 
    //console.log(`newColumnNameForRename name saved: ${name}`);
    //console.log(this.newColumnNameForRename);
  }


  saveThisAsMergableDelimiter(event: any, name: string): void {
    event.preventDefault();

    const input = event.target as HTMLInputElement;
    const inputValue = input.value.slice(0, 1);

    if (!this.isSpecialCharacter(inputValue)) {
        this.resetDelimiter(input);
    } else {
        this.mergableDelimiter.name = inputValue;
        input.style.textAlign = 'center';
        this.mergableDelimiter.isEditing = false;
    }
    this.checkAllParamsForMerge();
}

private resetDelimiter(input: HTMLInputElement): void {
    input.value = '';
    this.mergableDelimiter.isEditing = true;
    this.mergableDelimiter.name = '';
    input.style.width = '57px';
}

private isSpecialCharacter(char: string): boolean {
  // /[\{\}!\\"%&'()*+,-./:;=#>?@[$\]^_`|<>~]/
    // const specialCharacterRegex = /^[!@#$%^&*(),.?":;-_={}|<>]$/;
    const specialCharacterRegex = /[\{\}!\\"%&'()*+,-./:;=#>?@[$\]^_`|<>~]/;

    return specialCharacterRegex.test(char);
}



  // private isSpecialCharacter(char: string): boolean {
  //   const specialCharacterRegex = /^[!@#$%^&*(),.?":{}|<>]$/;
  //   return specialCharacterRegex.test(char);
  // }

  // validateDelimiter(event: Event) {
  //   const input = event.target as HTMLInputElement;
  //   if (input.value.length > 1) {
  //     input.value = input.value.slice(0, 1);
  //     this.mergableDelimiter.name = input.value;
  //   }
  // }
  
  editColumn(index: number): void {
    // Set isEditing to true for the selected column
    this.columns[index].isEditing = true;
  }

  editRemovableColumn(index: number): void {
    // Set isEditing to true for the selected column
    this.removableColumns[index].isEditing = true;
  }

  editMergableOutputHeader(index: number): void {
    // Set isEditing to true for the selected column
    this.mergableOutputHeader[index].isEditing = true;
  }

  editMergableNewColumn(): void {
    // Set isEditing to true for the mergable new column
    this.mergableNewColumn.isEditing = true;
  }

  editOldColumnNameCreatedByUser(): void {
    // Set isEditing to true for the mergable new column
    this.oldColumnCreatedByUser.isEditing = true;
  }

  editNewColumnNameForRename(): void {
    // Set isEditing to true for the mergable new column
    this.newColumnNameForRename.isEditing = true;
  }

  editMergableDelimiter(event: any): void {
    // 
    const input = event.target as HTMLInputElement;
    input.style.textAlign = 'left';
    // Set isEditing to true for the mergable new column
    this.mergableDelimiter.isEditing = true;
  }

  editFunctionInput = () => {
    // this.isFunctionOptionSelected = false;
  }

  editCategoryInput = () => {
    this.isCategorySelected = false;
  }
  
  removeColumn(index: number): void {
    // Remove the column from the array
    if (this.columns.length!=1) {
      this.columns.splice(index, 1);
    } 
    // 
    if (this.columns.length!=0) {
      this.enableFunctionSubmitIcon = true;
      this.showBlureSubmitIcon = false;
      this.showFunctionRequestLoadingState = false;
      this.showSuccessStateIcons = false;
      this.showErrorStateIcons = false;
      this.showFunctionMaximizeIcon = false;
// this.enableFunctionSubmitIcon = true;
    } else {
      this.enableFunctionSubmitIcon = false;
      this.showBlureSubmitIcon = true;

    }
    // 
    //console.log(`Column at index ${index} removed.`);
    //console.log(this.columns);
    // 
    // if (this.columns.length!=0) {
    //   this.enableFunctionSubmitIcon = true;
    // } else {
    //   this.enableFunctionSubmitIcon = false;
    // }
    // 
  }

  removeRemovableColumn(index: number): void {
    // Remove the column from the array
    this.structures.splice(index, 1);
    //console.log(`removableColumns at index ${index} removed.`);
    //console.log(this.structures);
    this.extractOutputColumnName();
    //console.log(this.removableOutputColumns);
    
  }

  removableOutputHeadersForMerge(index: number): void {
    // Remove the column from the array
    this.outputHeaderStructuresForMerge.splice(index, 1);
    //console.log(`removableColumns at index ${index} removed.`);
    //console.log(this.outputHeaderStructuresForMerge);
    this.extractOutputColumnName();
    //console.log(this.removableOutputColumns);
    // 
    if (this.outputHeaderStructuresForMerge.length > 1 && this.mergableNewColumn.name != '' && this.mergableDelimiter.name != '') {
      this.enableFunctionSubmitIcon = true;
      this.showBlureSubmitIcon = false;
      this.showFunctionRequestLoadingState = false;
      this.showSuccessStateIcons = false;
      this.showErrorStateIcons = false;
    } else {
      this.showBlureSubmitIcon = true;
      this.enableFunctionSubmitIcon = false;
      this.showFunctionRequestLoadingState = false;
      this.showSuccessStateIcons = false;
      this.showErrorStateIcons = false;
    }
    // 
    
  }

  removeMergableOutputHeader(index: number): void {
    // Remove the column from the array
    this.mergableOutputHeader.splice(index, 1);
    //console.log(`mergableOutputHeader at index ${index} removed.`);
    //console.log(this.mergableOutputHeader);
  }
  

  canAddColumn(): boolean {
    // Check if any column has an empty name
    return this.columns.every(column => column.name.trim().length > 0);
  }

  canAddARemovableColumn(): boolean {
    // Check if any column has an empty name
    return this.removableColumns.every(column => column.name.trim().length > 0);
  }

  canAddMergableOutputHeader(): boolean {
    // Check if any column has an empty name
    return this.mergableOutputHeader.every(outputHeader => outputHeader.name.trim().length > 0);
  }



  // *********************************** end

  // 
  /******* div reference for simultaneous horizontal scrolling ********* */
  @ViewChild('scrollOne') scrollOne: ElementRef;
  @ViewChild('scrollTwo') scrollTwo: ElementRef;

  /**** close the sort menu filter on click outside the menu  ************************************ */
  @ViewChild('sortFilterMenu') sortFilterMenuRef: ElementRef;
  @HostListener('document:click', ['$event']) onDocumentClick(event: any) {
    const clickedInside =
      this.sortFilterMenuRef &&
      this.sortFilterMenuRef.nativeElement.contains(event.target);
    if (
      event.target.id != this.sortActiveForColumnIndex &&
      !clickedInside &&
      this.sortActive == true
    ) {
      this.sortActive = false;
      // reset `sortActiveForColumnIndex` for proper bg highlight
      this.sortActiveForColumnIndex = '';
    }
  }

  /*** close the rename task input on esc key event */
  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(
    event: KeyboardEvent
  ) {
    if (this.renameTask) {
      this.renameTask = false;
    }
  }

  /***** function to scroll the header and content simultaneously */
  updateScroll = (event: any) => {
    if (this.currentElement === 'scrollTwo') {
      this.scrollOne.nativeElement.scrollLeft = event.target.scrollLeft;
    } else if (this.currentElement === 'scrollOne') {
      this.scrollTwo.nativeElement.scrollLeft = event.target.scrollLeft;
    }
  };

  /***** tracking current element being scrolled */
  updateCurrentElement(element: 'scrollOne' | 'scrollTwo') {
    this.currentElement = element;
  }

  openVersionHistory = () => {
    document.getElementById('flexibleDiv')!.style.display = 'block';
    this.showVersionHistory = true;
  };

  selectedSortColumn = '';
  searchQuery: string | null;
  selectedColumns: any = [];
  conditions: any = [
    { label: 'Contains', value: 'contains' },
    { label: 'Does not contain', value: 'does not contain' },
    { label: 'Is blank', value: 'is blank' },
  ];
  selectedCondition = 'contains';

  // Clearing the Sort form field
  clearAll() {
    this.selectedColumns = [];
    this.resetAllFilters();
  }

  // Clear filter
  clearSearchFilter(e: any) {
    e.stopPropagation();
    this.searchQuery = '';
    this.selectedColumns = null;
    this.selectedCondition = 'contains';
    // this.isSearchEntered = false;
  }

  // Reset filters and update output data
  resetAllFilters() {
    this.allFilterColumns.forEach((option: any) => {
      // Disable options if the limit is reached and the option is not selected
      option.disabled = false;
    });
    this.columnsList.forEach((option: any) => {
      // Disable options if the limit is reached and the option is not selected
      option.disabled = false;
    });
    this.selectedColumns = null;
    this.selectedSortColumn = '';
    if (this.searchQuery) {
      this.searchQuery = '';
    }
  }

  // Allow max 5 column selections
  onSelectSearchColumn() {
    this.allFilterColumns.forEach((option: any) => {
      // Disable options if the limit is reached and the option is not selected
      option.disabled =
        this.selectedColumns.length >= 5 &&
        !this.selectedColumns.includes(option.name);
    });
    this.columnsList.forEach((option: any) => {
      // Disable options if the limit is reached and the option is not selected
      option.disabled =
        this.selectedColumns.length >= 5 &&
        !this.selectedColumns.includes(option.name);
    });
  }

  // custom table

  columnsList: any[] = [];

  tableData: any[] = [];

  sorting: { [columnName: string]: 'asc' | 'desc' | null } = {};

  sortedColumnName: string | null;
  // Handle sort - defaults to ascending when active
  onSort(columnName: string) {
    this.sortedColumnName = columnName;
    if (!this.sorting[columnName]) {
      // Sort in ascending order
      this.sorting[columnName] = 'asc';
    } else if (this.sorting[columnName] === 'asc') {
      // Sort in descending order
      this.sorting[columnName] = 'desc';
    } else {
      // Deactivate sorting
      this.sorting[columnName] = null;
      this.sortedColumnName = null;
    }

    // Reset sorting for other columns
    Object.keys(this.sorting)
      .filter((col) => col !== columnName)
      .forEach((col) => (this.sorting[col] = null));
  }

  isSearchEntered: boolean = false;
  // Apply filter on enter press
  onEnterSearch() {
    this.isSearchEntered = true;
  }

  // filter columns
  // filterList(searchText: any) {
  //   const searchKey: string = searchText?.trim();
  //   this.allFilterColumns = this.columnsList.filter((option) =>
  //     option.name.toLowerCase().includes(searchKey.toLowerCase())
  //   );
  // }
  // =======
  filterList(searchText: any) {
    const searchKey: string = searchText?.trim().toLowerCase();

    this.allFilterColumns = this.columnsList.sort((a, b) => {
      // Compare the presence of searchText in each column name
      const aMatches = a.name.toLowerCase().includes(searchKey);
      const bMatches = b.name.toLowerCase().includes(searchKey);

      // Sort based on matches - bring matching columns to the top
      if (aMatches && !bMatches) {
        return -1;
      } else if (!aMatches && bMatches) {
        return 1;
      } else {
        // Preserve the original order for columns that have the same matching status
        return 0;
      }
    });
  }

  // =======

  @ViewChild('columnSelect') columnDropdown: MatSelect;
  openColumnDropdown() {
    if (!this.columnDropdown.panelOpen) this.columnDropdown.open();
  }

  // Apply filter on change of column selections
  onSelectCondition() {
    if (this.searchQuery) {
      this.isSearchEntered = true;
    }
    if (this.selectedCondition == 'is blank') {
      this.searchQuery = null;
      this.isSearchEntered = false;
    }
  }

  columnSearchText: string = '';
  // Apply filter when the panel closes
  onOpenCloseColumnPanel(isOpen: boolean) {
    if (!isOpen) {
      if (this.searchQuery) {
      }
      if (this.selectedCondition == 'is blank') {
      }
      this.columnSearchText = '';
      this.filterList(this.columnSearchText);
    }
  }

  // Switch to edit field
  editSearch() {
    this.isSearchEntered = false;
  }

  /************************************ Revised Output View *************************/

  switchTab = (targetTab: string) => {
    this.selectedCustomTab = targetTab;
    
    if (targetTab == 'inputData') {
      this.inputDataTablePage = 1;
      this.inputDataTableSize = 100;
      this.getInputTableData(this.activeOrgID, this.batchID);
    } else {
      this.dataTablePage = 1;
      this.dataTableSize = 100;
      this.getDataTableHeaders(this.activeOrgID, this.batchID);
      this.resetFunctionParams();
    }
  };

  resetFunctionParams = () => {
    // reset function query params 
    this.functionControl.reset();
    this.functionControl.setValue('');
    this.datasetControl.reset();
    this.datasetControl.setValue('');

    // this.dataSetList = [];
    this.columns = [];
    this.removableColumns = [];
    this.mergableOutputHeader = [];
    this.mergableNewColumn.name = '';
    this.mergableNewColumn.isEditing = true;
    this.oldColumnCreatedByUser.name = '';
    this.oldColumnCreatedByUser.isEditing = true;
    this.newColumnNameForRename.name = '';
    this.newColumnNameForRename.isEditing = true;
    this.showFunctionInput = false;
    this.showCreateStructure = false;
    this.showRemoveStructure = false;
    this.showMergeStructure = false;
    this.showRenameStructure = false;
    this.enableFunctionSubmitIcon = false;
    this.isCategorySelected = false;
    this.isFunctionParamsCleared = true;
    // 
  }
  /************* get batch details  *************/

  getBatchDetails = (orgId: any, batchId: any) => {
    this.jssOutputService.getBatchDetails(orgId, batchId).subscribe({
      next: (resp) => {
        this.batchDetails = resp.batch_details;
        this.taskName = this.batchDetails.task_name;
        this.isSampleTask = this.batchDetails.is_sample;
        this.taskNameValue = this.taskName;
        this.inputFileDownloadUrl = this.batchDetails.input_file_url;
      },
      error: (error: HttpErrorResponse) => {
        this.snackbar.showSnackbar(error.error.detail, '', 'error');
      },
    });
  };

  /*************** download input file  **************/

  downloadInputFile = () => {
    window.open(this.inputFileDownloadUrl, '_blank');
  };

  /*************** open batch details dialog *************/

  openBatchDetailsDialog = () => {
    const dialogRef = this.dialog.open(BatchDetailsComponent, {
      data: { batchDetails: this.batchDetails },
    });

    // dialogRef.afterClosed().subscribe((result) => {
    //   //console.log('The dialog was closed');
    // });
  };

  openRecipeSummaryDialog = () => {
    const dialogRef = this.dialog.open(RecipeSummaryComponent, {
      data: { batchDetails: this.batchDetails },
    });
  };

  /********* get input table data  ******************/

  getInputTableData = (orgId: any, batchId: any) => {
    this.isInputDataTableLoading = true;
    let obj = {
      page: this.inputDataTablePage,
      page_size: this.inputDataTableSize,
    };
    this.jssOutputService.getInputDataTable(orgId, batchId, obj).subscribe({
      next: (resp) => {
        this.inputDataTableContent = resp;
        this.inputDataTableRows = resp.input_data;
        this.isInputDataTableLoading = false;
      },
      error: (error: HttpErrorResponse) => {
        this.snackbar.showSnackbar(error.error.detail, '', 'error');
        this.isInputDataTableLoading = false;
      },
    });
  };

  /*** get table headers **********/

  getDataTableHeaders = (orgId: any, batchId: any) => {
    this.isHeaderLoading = true;
    this.isTableContentLoading = true;
    this.jssOutputService.getDataTableHeaders(orgId, batchId).subscribe({
      next: (resp) => {
        this.isHeaderLoading = false;
        this.inputHeaders = resp.mapping;
        // this.inputHeaders = []
        this.getDataTableContent(this.activeOrgID, this.batchID);
      },
      error: (error: HttpErrorResponse) => {
        this.isHeaderLoading = false;
        this.snackbar.showSnackbar(error.error.detail, '', 'error');
      },
    });
  };

  /*** get table content  *********/

  getDataTableContent = (orgId: any, batchId: any) => {
    let obj = {
      page: this.dataTablePage,
      page_size: this.dataTableSize,
      sort: this.sortObject,
      column_filter: this.filterObject,
      conversation_uid: '',
    };
    this.isTableContentLoading = true;
    this.isBusy = true;
    this.jssOutputService.getDataTableContent(orgId, batchId, obj).subscribe({
      next: (resp) => {
        this.dataTableRows = resp.output;
        this.dataTablePage = resp.page;
        this.currentPageValue = this.dataTablePage;
        this.dataTableSize = resp.page_size;
        this.dataTableTotalRows = resp.total_records;
        // this.dataTableRows = [];
        this.isBusy = false;
        this.isTableContentLoading = false;
      },
      error: (error: HttpErrorResponse) => {
        this.isBusy = false;
        this.snackbar.showSnackbar(error.error.detail, '', 'error');
        this.isTableContentLoading = false;
      },
    });
  };

  /*** on output data table pagination event */

  onPaginationEvent = (e: any) => {
    this.dataTablePage = e.pageIndex + 1;
    this.dataTableSize = e.pageSize;
    // this.batchDetails(this.batchID);
    this.getDataTableContent(this.activeOrgID, this.batchID);
  };

  /*** go to a page directly  */
  goToPage = (e: any) => {
    e.preventDefault();
    // to remove focus
    e.target.blur();
    window.getSelection()?.removeAllRanges();
    this.dataTablePage = this.currentPageValue;
    this.getDataTableContent(this.activeOrgID, this.batchID);
  };

  /***** on input table pagination event  */

  onInputTablePaginationEvent = (e: any) => {
    this.inputDataTablePage = e.page;
    this.inputDataTableSize = e.pageSize;
    this.getInputTableData(this.activeOrgID, this.batchID);
  };

  goToPageInputTable = (e: any) => {
    this.inputDataTablePage = e.page;
    this.inputDataTableSize = e.pageSize;
    this.getInputTableData(this.activeOrgID, this.batchID);
  };

  /*** on cell edit  */

  editCell = (ev: Event) => {
    let cellIndex = (ev.target as HTMLInputElement).dataset;
  };

  /*** set the active column and positions for sort filter menu once `sort` icon clicked  */

  sortAndFilterAppliedFor = (header: any, e: any) => {
    // check if sort btn was reclicked for same column
    if (e.target.id == this.sortActiveOnElement) {
      // toggle
      this.sortActive = !this.sortActive;
    } else {
      // sort menu initialised for new column
      this.sortActive = true;
    }
    // header.sort_active = !header.sort_active;
    // toggle sort & filter menu
    if (this.sortActive) {
      // get the position of the clicked element
      let elm = document.getElementById(header.column_index);
      let rect = elm!.getBoundingClientRect();
      // store the column for which sort menu initiated
      this.sortActiveOnElement = e.target.id;
      // to highlight icon for active sort
      this.sortActiveForColumn = header.output_column;
      this.sortActiveForColumnIndex = header.column_index;
      // prefill menu with previous selections if any
      this.feedSortDataForRespectiveColumn(this.sortActiveForColumn);
      // calculate position for sort & filter menu|245px is the width of the sort filter menu
      // this.topOffset = rect.y + 21;
      this.topOffset = 120;
      if (this.screenInnerWidth < rect.x + 245) {
        this.leftOffset = rect.x - 245 - 60;
      } else {
        this.leftOffset = rect.x - 60;
      }
    } else {
      // reset `sortActiveForColumnIndex` for proper bg highlight
      this.sortActiveForColumnIndex = '';
    }
  };

  /*** prepopulate active selections in the column filters */

  feedSortDataForRespectiveColumn = (col: string) => {
    if (this.sortedColumnList.hasOwnProperty(col)) {
      this.chosenSortOrder = this.sortedColumnList[col].sortOrder;
      this.filterForm.patchValue({
        selectACondition: this.sortedColumnList[col]['condition'],
        text: this.sortedColumnList[col]['text'],
      });
    } else {
      this.chosenSortOrder = null;
      this.filterForm.reset();
    }
  };

  /*** on change of sorting order   */

  getSortingOrder = (e: any) => {
    this.sortObject[this.sortActiveForColumn] = e.value;

    // add the sorted/filtered column to the `sortedColumnList` object
    if (!this.sortedColumnList.hasOwnProperty(this.sortActiveForColumn)) {
      this.sortedColumnList[this.sortActiveForColumn] = {};
    }
    this.sortedColumnList[this.sortActiveForColumn]['sortOrder'] = e.value;
    this.sortedColumnList[this.sortActiveForColumn]['columnIndex'] =
      this.sortActiveForColumnIndex;

    // close the sort filter menu
    this.sortActive = false;
    // get the data table rows
    this.getDataTableContent(this.activeOrgID, this.batchID);
    // highlight the column sort icon if sorting selected and value is not default
    if (
      this.sortObject[this.sortActiveForColumn] &&
      this.sortObject[this.sortActiveForColumn] != ''
    ) {
      (
        document.getElementById(
          this.sortActiveForColumnIndex
        ) as HTMLImageElement
      ).src = '../../../../assets/images/outputDataview/sort-blue.svg';
      // fill the background
      document.getElementById(
        'div' + this.sortActiveForColumnIndex
      )!.style.backgroundColor = '#D0DAFA';
    } else if (!this.filterObject[this.sortActiveForColumn]) {
      (
        document.getElementById(
          this.sortActiveForColumnIndex
        ) as HTMLImageElement
      ).src = '../../../../assets/images/outputDataview/sort.svg';
      // remove background color
      document.getElementById(
        'div' + this.sortActiveForColumnIndex
      )!.style.backgroundColor = '#F1F5F9';
    }
    // reset the `sortActiveForColumnIndex` to get rid of sort icon background
    this.sortActiveForColumnIndex = '';
  };

  /*** on change of filtering (contains/does not contain/is blank) conditions  */

  onChangeOfCondition(e: any) {
    this.dataFilterSelectValue = this.filterForm.get('selectACondition')?.value;
    this.filterForm.get('text')!.reset();
  }

  /*** clear and apply filters for each table column */
  clearColumnFilter = () => {
    delete this.filterObject[this.sortActiveForColumn];
    // delete filter records from `sortedColumnList`
    delete this.sortedColumnList[this.sortActiveForColumn]['condition'];
    delete this.sortedColumnList[this.sortActiveForColumn]['text'];
    // Reset sort order to default
    this.chosenSortOrder = null;
    // clear the form
    this.filterForm.reset();
    this.getDataTableContent(this.activeOrgID, this.batchID);
    // remove highlight from column sort icon if no filtering/sorting active
    if (
      !this.filterObject[this.sortActiveForColumn] &&
      !this.sortObject[this.sortActiveForColumn]
    )
      (
        document.getElementById(
          this.sortedColumnList[this.sortActiveForColumn]['columnIndex']
        ) as HTMLImageElement
      ).src = '../../../../assets/images/outputDataview/sort.svg';
    // remove background color
    document.getElementById(
      'div' + this.sortedColumnList[this.sortActiveForColumn]['columnIndex']
    )!.style.backgroundColor = '#F1F5F9';
    // close the menu
    this.sortActive = false;
  };

  /***** apply filters for column  */

  applyColumnFilter = () => {
    let obj: any = {};
    obj['condition'] = this.filterForm.value.selectACondition;
    obj['values'] =
      this.filterForm.value.selectACondition == 'is blank'
        ? ''
        : this.filterForm.value.text;
    this.filterObject[this.sortActiveForColumn] = obj;
    // add the sorted/filtered column to the `sortedColumnList` object
    if (!this.sortedColumnList.hasOwnProperty(this.sortActiveForColumn)) {
      this.sortedColumnList[this.sortActiveForColumn] = {};
    }
    this.sortedColumnList[this.sortActiveForColumn]['columnIndex'] =
      this.sortActiveForColumnIndex;
    this.sortedColumnList[this.sortActiveForColumn]['condition'] =
      this.filterForm.value.selectACondition;
    this.sortedColumnList[this.sortActiveForColumn]['text'] =
      this.filterForm.value.selectACondition == 'is blank'
        ? ''
        : this.filterForm.value.text;
    // close the sort filter menu
    this.sortActive = false;
    // get the data table rows
    this.getDataTableContent(this.activeOrgID, this.batchID);
    // highlight the column sort icon
    (
      document.getElementById(this.sortActiveForColumnIndex) as HTMLImageElement
    ).src = '../../../../assets/images/outputDataview/sort-blue.svg';
    // fill the background
    document.getElementById(
      'div' + this.sortActiveForColumnIndex
    )!.style.backgroundColor = '#D0DAFA';
  };

  /*** reset sorting for all columns */

  resetSortForAllColumns = () => {
    // reset `sortObject`
    this.sortObject = {};
    this.sortActiveForColumnIndex = '';
    for (const key in this.sortedColumnList) {
      // skip if column does not have sorting applied
      if (
        !this.sortedColumnList[key].hasOwnProperty('sortOrder') &&
        this.sortedColumnList[key].hasOwnProperty('condition')
      ) {
        continue;
      }
      // delete only `sortOrder` key if other filters active
      else if (
        this.sortedColumnList[key].hasOwnProperty('sortOrder') &&
        this.sortedColumnList[key].hasOwnProperty('condition')
      ) {
        delete this.sortedColumnList[key]['sortOrder'];
      } else {
        // remove sort icon highlight
        (
          document.getElementById(
            this.sortedColumnList[key]['columnIndex']
          ) as HTMLImageElement
        ).src = '../../../../assets/images/outputDataview/sort.svg';
        // remove background color
        document.getElementById(
          'div' + this.sortedColumnList[key]['columnIndex']
        )!.style.backgroundColor = '#F1F5F9';
        // delete the column altogether
        delete this.sortedColumnList[key];
      }
    }
    // get the data table rows
    this.getDataTableContent(this.activeOrgID, this.batchID);
  };

  /*** reset filters for all columns */

  resetFilterForAllColumns = () => {
    this.filterObject = {};
    for (const key in this.sortedColumnList) {
      // delete only `condition and text` key if other filters active
      if (
        this.sortedColumnList[key].hasOwnProperty('sortOrder') &&
        !this.sortedColumnList[key].hasOwnProperty('condition')
      ) {
        continue;
      } else if (
        this.sortedColumnList[key].hasOwnProperty('sortOrder') &&
        this.sortedColumnList[key].hasOwnProperty('condition')
      ) {
        delete this.sortedColumnList[key]['condition'];
        delete this.sortedColumnList[key]['text'];
      } else {
        // remove sort icon highlight
        (
          document.getElementById(
            this.sortedColumnList[key]['columnIndex']
          ) as HTMLImageElement
        ).src = '../../../../assets/images/outputDataview/sort.svg';
        document.getElementById(
          'div' + this.sortedColumnList[key]['columnIndex']
        )!.style.backgroundColor = '#F1F5F9';
        // delete the column altogether
        delete this.sortedColumnList[key];
      }
    }
    // get the data table rows
    this.getDataTableContent(this.activeOrgID, this.batchID);
  };

  /****** save task name  *********************************/

  saveTaskName() {
    this.jssOutputService
      .renameTask(this.activeOrgID, this.batchID, this.taskNameValue)
      .subscribe({
        next: (resp) => {
          this.snackbar.showSnackbar(
            'Batch name updated successfully',
            '',
            'success'
          );
          this.renameTask = false;
          this.taskName = this.taskNameValue;
        },
        error: (error: HttpErrorResponse) => {
          this.snackbar.showSnackbar(error.error.detail, '', 'error');
        },
      });
  }

  /************* exit task name edit  */

  exitTaskEdit = () => {
    this.renameTask = false;
    this.taskNameValue = this.taskName;
    this.errorType = null;
  };

  setNewTaskName = (ev: any) => {
    this.taskNameValue = ev.target.textContent.trim();
    this.verifyTaskName();
  };

  /********* on task name input change *******************/

  onTaskNameChange = (name: string, event: any) => {
    if (name.length >= this.maxCharCountTaskName && event.code != 'Backspace') {
      event.preventDefault();
      this.taskNameValue.slice(0, 99);
      return false;
      // disable enter default action in content editable div
      // verify task name on enter
    } else if (event.keyCode == 13) {
      event.preventDefault();
      return false;
    }
  };

  /****** task name verification******** */
  verifyTaskName = () => {
    if (this.taskNameValue.length < this.minCharCountTaskName) {
      this.errorType = 'atleast_3_characters_required';
      return false;
    } else if (this.taskNameValue.length > this.maxCharCountTaskName) {
      this.errorType = 'max_limit_exceeded';
      return false;
    } else if (!/^[a-zA-Z](.*[a-zA-Z0-9])?$/.test(this.taskNameValue)) {
      this.errorType = 'no_special_charcters_in_beginning';
      return false;
    } else if (!/^([a-zA-Z0-9,:\s_]*)$/.test(this.taskNameValue)) {
      this.errorType = 'only_underscore_allowed_in_between';
      return false;
    } else {
      this.errorType = null;
      return true;
    }
  };

  isObjectEmpty = (objectName: any) => {
    // check if object has 0 keys
    // or object has keys with no value
    return (
      Object.keys(objectName).length === 0 ||
      Object.values(objectName).every((x) => x === null || x === '')
    );
  };

  /**** cell hover style */
  changeStyle = (event: any) => {
    let cellIndex: DOMStringMap;
    cellIndex = (event.target as HTMLInputElement).dataset;
    let rowIndex = cellIndex['rowIndex']!.toString();
    let colIndex = cellIndex['columnIndex']!.toString();
    if (event.type == 'mouseover') {
      document.getElementById(rowIndex)!.style.backgroundColor = '#E7ECFE';
      document.getElementById('div' + colIndex)!.style.backgroundColor =
        '#E7ECFE';
    } else {
      document.getElementById(rowIndex)!.style.backgroundColor = '#F1F5F9';
      document.getElementById('div' + colIndex)!.style.backgroundColor =
        '#F1F5F9';
    }
  };

  /******** generate output file  */

  generateOutputFile = () => {
    this.jssOutputService
      .generateOutputFile(this.activeOrgID, this.batchID)
      .subscribe({
        next: (resp: any) => {
          this.isNewVersion
            ? this.router.navigate(['/dashboard-v1'])
            : this.router.navigate(['/dashboard']);
        },
        error: (error: HttpErrorResponse) => {
          this.snackbar.showSnackbar(error.error.detail, '', 'error');
        },
      });
  };
}
