import { Component, OnInit } from '@angular/core';
import {
  ActivatedRoute,
  Router,
  Event as NavigationEvent,
  NavigationEnd,
} from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { UserService } from './_services/user.service';
import { SnackbarService } from './_services/snackbar.service';
import { ENDPOINTS } from './_globals/endpoints';
import { AuthService } from '@auth0/auth0-angular';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { TermsOfServiceComponent } from './_components/user-access/terms-of-service/terms-of-service.component';
import { environment } from 'src/environments/environment';
import { ConnectivityService } from './_services/connectivity.service';
import { PrivacyPolicyService } from './_services/privacy-policy.service';
import { AppConfigService } from './_services/app-config.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  private endpoints: any = ENDPOINTS;
  title = 'dataX';
  moduleName: string;
  userType: string;
  profileJson: any;
  isLowerResolution: boolean | undefined;
  currentRoute: string;
  inviteToken: string;
  prohibitedUrlsForTos: any[] = [
    '/signup',
    '/invite-request',
    'loading',
    '/error',
  ];
  hasDialogOpened: boolean = false;
  isLocal: boolean = false;
  location: Location;
  isOnline: boolean;

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    public auth: AuthService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private userService: UserService,
    private privacyPolicyService: PrivacyPolicyService,
    public dialog: MatDialog,
    private snackbarService: SnackbarService,
    private connectivityService: ConnectivityService,
    private appConfig: AppConfigService
  ) {
    this.matIconRegistry.addSvgIcon(
      'accept-circle',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/images/approved.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'more',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/images/more.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'search',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/images/search.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'mailActive',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/images/MailRead.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'mailInactive',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/images/MailUnread.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'arrowRightPlane',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/images/Arrows/ArrowRightPlane.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'arrowRightActive',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/images/Arrows/Arrow Right.svg'
      )
    );

    this.matIconRegistry.addSvgIcon(
      'successTick',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/images/success-tick.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'successTick',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/images/Check Circle.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'planeCheckCircle',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/images/CheckCirclePlane.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'DangerTriangle',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/images/danger-triangle.svg'
      )
    );

    this.matIconRegistry.addSvgIcon(
      'info',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/images/info.svg'
      )
    );

    this.matIconRegistry.addSvgIcon(
      'DownArrow',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/images/Arrows/ArrowDown.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'arrowRightFilled',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/images/Arrows/Arrow Right Filled.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'download.svg',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/images/Arrows/download.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'userInvitation',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/images/User Plus.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'send-msg',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/images/send-msg.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'calender',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/images/Calendar2.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'edit',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/images/edit.svg'
      )
    );

    // =======
    this.matIconRegistry.addSvgIcon(
      'copy-icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/images/outputDataview/copy.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'version-history-icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/images/outputDataview/history_2.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'verticle-sorting-icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/images/outputDataview/sort_vertical.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'custom-edit-icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/images/outputDataview/sparkle.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'manual-mapping-icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/images/outputDataview/tree_structure.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'dot-six-vertical-icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/images/outputDataview/dots_six_vertical.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'info-circle-icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/images/outputDataview/info_circle.svg'
      )
    );

    this.matIconRegistry.addSvgIcon(
      'taskEditSave',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/images/updated-dashboard/check.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'cancelTaskNameEdit',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/images/updated-dashboard/x.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'downloadFileDownArrow',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/images/updated-dashboard/alt-arrow-down.svg'
      )
    );

    // =======

    this.matIconRegistry.addSvgIcon(
      'logout',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/images/Logout.svg'
      )
    );

    this.matIconRegistry.addSvgIcon(
      'Check',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/images/outputDataview/check.svg'
      )
    );

    this.matIconRegistry.addSvgIcon(
      'checkDisabled',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/images/outputDataview/check-disabled.svg'
      )
    );

    this.matIconRegistry.addSvgIcon(
      'Close',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/images/outputDataview/close.svg'
      )
    );
    // ======== function component
    this.matIconRegistry.addSvgIcon(
      'cancel-this-column',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/images/function/close.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'plusIcon',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/images/function/plus.svg'
      )
    );
    
    this.matIconRegistry.addSvgIcon(
      'maximizeBlured',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/images/function/blured_maximize.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'checkCircle',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/images/function/check_circle_outline.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'functionClose',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/images/function/function_close.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'functionSubmitIcon',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/images/function/function_submit_icon.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'functionBluredSubmitIcon',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/images/function/function_submit_blured.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'functionMaximize',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/images/function/maximize.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'autocompleteOptionArrowUpKey',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/images/function/Arrows/autocomplete-option-arrow-up.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'autocompleteOptionArrowDownKey',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/images/function/Arrows/autocomplete-option-arrow-down.svg'
      )
    );
    // ========

  }

  ngOnInit() {
    this.location = window.location;
    console.log(`UI version ${environment.uiVersion}`);
    // get current route
    this.userService.getIsLocal().subscribe({
      next: (res) => {
        this.isLocal = res;
      },
    });

    this.route.queryParams.subscribe((params) => {
      this.inviteToken = params.invite;
    });

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.currentRoute = event.url;
        // don't show tos if current route exists in prohibitedUrlsForTos list
        if (
          this.prohibitedUrlsForTos.some((url) =>
            this.currentRoute.includes(url)
          )
        ) {
          return;
        } else {
          if (localStorage.getItem('user')) {
            // if user details can be retrieved from local storage
            const userData = JSON.parse(localStorage.getItem('user')!);
            // if the user has not accepted terms of service|privacy policy
            if (!userData.privacy_policy_acceptance) {
              this.openDialog();
            } else {
              console.log(this.appConfig.getConfig().is_new_version);

              if (this.inviteToken) {
                //
                console.log(this.appConfig.getConfig().is_new_version);
                this.appConfig.getConfig().is_new_version
                  ? this.router.navigate(
                      ['/dashboard-v1'],
                      this.isLocal
                        ? {
                            queryParams: {
                              invite: this.inviteToken,
                              local: true,
                            },
                          }
                        : { queryParams: { invite: this.inviteToken } }
                    )
                  : this.router.navigate(
                      ['/dashboard'],
                      this.isLocal
                        ? {
                            queryParams: {
                              invite: this.inviteToken,
                              local: true,
                            },
                          }
                        : { queryParams: { invite: this.inviteToken } }
                    );
                //
              }
            }
          } else {
            // else call me api
            this.userService.me().subscribe({
              next: (resp) => {
                if (!resp.privacy_policy_acceptance) {
                  this.openDialog();
                } else {
                  console.log(this.appConfig.getConfig().is_new_version);

                  if (this.inviteToken) {
                    this.appConfig.getConfig().is_new_version
                      ? this.router.navigate(
                          ['/dashboard-v1'],
                          this.isLocal
                            ? {
                                queryParams: {
                                  invite: this.inviteToken,
                                  local: true,
                                },
                              }
                            : { queryParams: { invite: this.inviteToken } }
                        )
                      : this.router.navigate(
                          ['/dashboard'],
                          this.isLocal
                            ? {
                                queryParams: {
                                  invite: this.inviteToken,
                                  local: true,
                                },
                              }
                            : { queryParams: { invite: this.inviteToken } }
                        );
                    //

                    //
                  } else {
                    console.log(this.appConfig.getConfig().is_new_version);

                    this.appConfig.getConfig().is_new_version
                      ? this.router.navigate(['/dashboard-v1'])
                      : this.router.navigate(['/dashboard']);
                  }
                }
              },
            });
          }
        }
      });
    // auth0 error handling
    this.auth.error$.subscribe((error) => {
      console.log(error);
      this.userService.logUserOut();
    });

    // get user profile json
    this.auth.user$.subscribe((profile) => {
      this.profileJson = profile;
    });

    // check for window width and height to show 'resolution unsupported' msg
    if (window.innerWidth < 1080) {
      this.isLowerResolution = true;
    } else {
      this.isLowerResolution = false;
    }

    // check network status and show no internet template
    this.connectivityService.isOnline().subscribe((online) => {
      if (online == false) {
        console.log('connection', online);
      }
      this.isOnline = online;
      // this.isOnline = true;
    });
  }

  //Opens TOS dialog

  // openDialog() {
  //   const dialogRef = this.dialog
  //     .open(TermsOfServiceComponent, {
  //       width: '100%',
  //       height: '90vh',
  //       disableClose: true,
  //       data: {
  //         code: this.inviteToken,
  //       },
  //     })
  //     .afterClosed()
  //     .subscribe((res) => {
  //       // update user data in local storage
  //       this.userService.me().subscribe({
  //         next: (resp) => {
  //           localStorage.setItem('user', JSON.stringify(resp));
  //           const userData = JSON.parse(localStorage.getItem('user')!);
  //           if (JSON.parse(userData.privacy_policy_acceptance)) {
  //             this.userService.setCloseDialog({
  //               isOpen: true,
  //               token: this.inviteToken,
  //             });
  //           }
  //         },
  //       });
  //     });
  // }

  //
  openDialog() {
    console.log('1');

    this.privacyPolicyService.openPrivacyPolicyDialog(this.inviteToken);
  }
  //

  getChild = (activatedRoute: ActivatedRoute): any => {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  };

  /**
   * Low resolution info
   * @param event
   */
  onResize = (event: any) => {
    if (event.target.innerWidth < 1080) {
      this.isLowerResolution = true;
    } else {
      this.isLowerResolution = false;
    }
  };
}
