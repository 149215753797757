import { Component, Inject, OnInit, HostListener } from '@angular/core';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import { TopNavComponent } from 'src/app/_components/navigation/top-nav/top-nav.component';
import { Router, RouteReuseStrategy } from '@angular/router';
import { NotificationsService } from 'src/app/_services/notifications.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SnackbarService } from 'src/app/_services/snackbar.service';
import { LoadingService } from 'src/app/_services/loading.service';
import { Observable, of, Subscription } from 'rxjs';
import { delay, tap, mergeMap, repeat, takeWhile } from 'rxjs/operators';
@Component({
  selector: 'app-notification-dialog',
  templateUrl: './notification-dialog.component.html',
  styleUrls: ['./notification-dialog.component.scss'],
})
export class NotificationDialogComponent implements OnInit {
  otherNotifications: any = [];
  downloadNotifications: any = [];
  inviteNotifications: any = [];
  dialogData: any = [];
  clicked: number = 1;
  pollSubscription: Subscription;
  isLoading: boolean = true;
  unreadCount: number;
  userInitials: string;
  dialogType: string;
  page: number = 1;
  pageSize: number = 4;
  orgCode: any;
  actionClicked: boolean = false;
  /** click event for highlighted IDs in notifications */
  // @HostListener('click', ['$event'])
  goToRoute = (e: any) => {
    this.dialogRef.close();
    this.router.navigate(['/loading']);
    let value = e.target?.innerHTML;
    // let type = e.target?.nextSibling?.innerHTML;
    let type = e.target?.nextSibling?.childNodes[0]?.data;
    let routeId = e.target?.nextSibling?.childNodes[1]?.innerText;
    if (value && type) {
      // batch id route redirection
      // if (type == 'batch_id') {
      //   this.router.navigate(['/home'], { queryParams: { batch_id: value } });
      //   this.dialogRef.close();
      // }
      // // dist route redirection
      // else if (type == 'distributor') {
      //   this.router.navigate(['/my-network'], {
      //     queryParams: { network_id: routeId },
      //   });
      //   this.dialogRef.close();
      // }
      // // supplier route redirection
      // else if (type == 'supplier') {
      //   this.router.navigate(['/my-network'], {
      //     queryParams: { network_id: routeId },
      //   });
      //   this.dialogRef.close();
      // }
    }
  };

  constructor(
    public dialogRef: MatDialogRef<TopNavComponent>,
    private router: Router,
    private notificationService: NotificationsService,
    private snackbarService: SnackbarService,
    private loadingService: LoadingService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.dialogType = this.data.type; //to get the dialoge type
    this.orgCode = localStorage.getItem('currentOrganizationCode');
    this.getNotificationsForDialog(); // getting notifications
    this.getNotificationsCount(); //getting the unread notification count
    // if (this.data.type === 'notification') {
    //   this.dialogData = this.otherNotifications;
    // } else if (this.data.type === 'inbox') {
    //   this.dialogData = this.inboxNotifications;
    // } else if (this.data.type === 'invite') {
    //   this.dialogData = this.inviteNotifications;
    // }

    // poll notifications list
    // const poll = of({}).pipe(
    //   mergeMap((_) =>
    //     of(
    //       this.getNotificationsForDialog(
    //         this.notificationType,
    //         this.notificationSubType
    //       )
    //     )
    //   ),
    //   delay(300000),
    //   repeat()
    // );
    // this.pollSubscription = poll.subscribe();
    // this.getNotificationsCount();
    // if (this.notifications) {
    // this.getUserInitials();
    // }
  }

  ngOnDestroy() {
    // this.pollSubscription.unsubscribe();
  }

  /****  get notifications */
  getNotificationsForDialog = () => {
    this.dialogData = [];
    this.isLoading = true;
    this.notificationService
      .getNotifications(this.dialogType, this.page, this.pageSize, this.orgCode)
      .subscribe({
        next: (resp: any) => {
          if (resp && resp.results) {
            for (const notification of resp.results) {
              this.dialogData.push(notification);
            }
          }
          // console.log(this.dialogData);
          this.isLoading = false;
          this.actionClicked = false;
        },
        error: (error: HttpErrorResponse) => {
          this.isLoading = false;
          this.snackbarService.showSnackbar(error.error.detail, ' ', 'error');
        },
      });
  };

  handleAction(action: any, read: boolean, id: string) {
    console.log(this.dialogData.length);
    this.isLoading = true;
    this.actionClicked = true;
    if (!read) {
      this.markAsRead(id);
    }
    // Assuming 'redirect_uri' contains the URL to redirect to
    if (action.redirect_uri) {
      this.notificationService.actionButton(action, this.dialogType).subscribe({
        next: (resp: any) => {
          if (this.dialogType == 'inbox') {
            window.open(resp.signed_url); //dowloading file from the signed url
            this.isLoading = false;
          } else if (this.dialogType == 'invite') {
            // Add a delay of 2000 milliseconds (2 second) before getting updated notification list'
            setTimeout(() => {
              this.getNotificationsForDialog();
            }, 2000);
          }
          // this.getNotificationsCount();
          // this.snackbarService.showSnackbar(
          //   resp.detail,
          //   'check_circle',
          //   'success'
          // );
        },
        error: (error: HttpErrorResponse) => {
          this.isLoading = false;
          this.snackbarService.showSnackbar(error.error.detail, ' ', 'error');
        },
      });
    }
  }
  /** mark all notification as read */
  markAllasRead = () => {
    if (this.unreadCount > 0) {
      this.notificationService.markAllasRead(this.dialogType).subscribe({
        next: (resp) => {
          this.getNotificationsForDialog();
          this.getNotificationsCount();
          // this.snackbarService.showSnackbar(
          //   resp.status,
          //   'check_circle',
          //   'success'
          // );
        },
        error: (error: HttpErrorResponse) => {
          this.isLoading = false;
          this.snackbarService.showSnackbar(error.error.detail, ' ', 'error');
        },
      });
    }
  };

  seeAllNotifications = (type: string) => {
    this.router.navigate(['/dashboard/inbox'], {
      queryParams: { type: type },
    });
    this.dialogRef.close();
  };

  /** mark notification as read */
  markAsRead = (id: string) => {
    this.isLoading = true;
    // this.notificationService.getUpdatedNotificationCount(true);
    this.notificationService.markAsRead(id).subscribe({
      next: (resp) => {
        if (!this.actionClicked) {
          this.getNotificationsForDialog();
        }
        // this.snackbarService.showSnackbar(
        //   resp.message,
        //   'check_circle',
        //   'success'
        // );
        // this.notifications[i].unread = false;
        // this.notificationService.getUpdatedNotificationCount(true);
        this.getNotificationsCount();
      },
      error: (error: HttpErrorResponse) => {
        this.isLoading = false;
        this.snackbarService.showSnackbar(error.error.detail, ' ', 'error');
      },
    });
  };

  /** mark notification as Unread */
  markAsUnRead = (id: string) => {
    this.isLoading = true;
    // this.notificationService.getUpdatedNotificationCount(true);
    this.notificationService.markAsUnRead(id).subscribe({
      next: (resp) => {
        this.getNotificationsForDialog();
        this.getNotificationsCount();
      },
      error: (error: HttpErrorResponse) => {
        this.isLoading = false;
        this.snackbarService.showSnackbar(error.error.detail, ' ', 'error');
      },
    });
  };

  getNotificationsCount = () => {
    this.notificationService.getNotificationsCount(this.orgCode).subscribe({
      next: (resp: any) => {
        if (this.dialogType === 'notification') {
          this.unreadCount = resp.notification_unread_count;
        } else if (this.dialogType === 'inbox') {
          this.unreadCount = resp.download_unread_count;
        } else if (this.dialogType === 'invite') {
          this.unreadCount = resp.invite_unread_count;
        }
        // console.log(this.unreadCount);
        // this.allCount = resp;
      },
      error: (error: HttpErrorResponse) => {
        this.loadingService.setLoading(false);
        this.snackbarService.showSnackbar(error.error.detail, ' ', 'error');
      },
    });
  };
}
