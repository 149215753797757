<div class="team-members">
  <div class="teams-form" fxLayout="row" fxLayoutAlign="space-between center">
    <mat-form-field appearance="outline" class="search-bar">
      <input matInput placeholder="Search For Team Members" [(ngModel)]="teamMemberSearch"
        (change)="onSeachOfTeamMembers($event.target.value)" />
      <mat-icon matPrefix svgIcon="search" class="search-icon"></mat-icon>
    </mat-form-field>
    <button color="primary" mat-stroked-button class="invite-members-btn" (click)="openInviteMemberDialog()" index="0"
      [disabled]="
        !hasTeamMembersCreatePermission ||
        !isEmailVerified ||
        featureFlagInviteMembers
      ">
      Invite members
    </button>
  </div>
  <table mat-table [dataSource]="teamdataSource" class="mat-elevation-z8" class="team-members-table">
    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let element">
        <div class="name">
          {{ element.name.charAt(0).toUpperCase() + element.name.slice(1) }}
        </div>
        <div class="email">{{ element.username }}</div>
      </td>
    </ng-container>

    <!-- Role Column -->
    <ng-container matColumnDef="role">
      <th mat-header-cell *matHeaderCellDef>Role</th>
      <td mat-cell *matCellDef="let element" class="role">
        <span *ngIf="element.roles[0]?.length">{{
          element.roles[0]?.charAt(0).toUpperCase() + element.roles[0]?.slice(1)
        }}</span>
      </td>
    </ng-container>

    <!-- Action Column -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let element" [style.color]="
          element?.status == 'Active'
            ? '#057A55'
            : element.status == 'Expire'
            ? '#D94159'
            : '#364861'
        ">
        {{ element.status }}
      </td>
    </ng-container>

    <!-- Action Column -->
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef>Action</th>
      <td mat-cell *matCellDef="let element" style="padding: 4px">
        <button class="action-btn" mat-stroked-button *ngIf="element.roles[0] == 'admin'"
          [disabled]="element.roles[0] == 'admin'" (click)="openExitDialog()">
          Leave
        </button>

        <button class="action-btn" mat-stroked-button *ngIf="element.roles[0] == 'user'" (click)="
            openRemoveUserConformationDialog(element.name, element.username)
          " [disabled]="
            !hasTeamMembersDeletePermission &&
            element.username !== userData.email
          ">
          Remove
        </button>
        <!--     (click)="removeUserFromOrg(element.username)" -->
        <!-- <button
          mat-stroked-button
          *ngIf="element.status == 'Pending'"
          (click)="cancelInvite(element?.invite_code)"
        >
          Cancel
        </button> -->
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <ng-container *ngIf="!teamdataSource?.data.length">
    <tr fxLayout="row" fxLayoutAlign="center center" style="height: 20rem">
      <td colspan="4">No Results Found</td>
    </tr>
  </ng-container>

  <!-- <div class="paginator" fxLayoutAlign="space-between center"> -->
  <!-- <div class="skip-to-paginator" fxLayoutGap="10px"></div> -->
  <mat-paginator [length]="totalItems" [pageSize]="size" [pageIndex]="page - 1"
    [pageSizeOptions]="[5, 10, 12, 20, 25, 50]" (page)="onPaginateChange($event)" [showFirstLastButtons]="false">
  </mat-paginator>
  <!-- </div> -->
</div>
