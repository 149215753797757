import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TermsOfServiceComponent } from '../_components/user-access/terms-of-service/terms-of-service.component';
import { UserService } from '../_services/user.service';

@Injectable({
  providedIn: 'root',
})
export class PrivacyPolicyService {
  saveThisInviteToken: string;

  constructor(public dialog: MatDialog, private userService: UserService) {}
  // ===================
  openPrivacyPolicyDialog(inviteToken: string) {
    console.log('2');

    //
    this.saveThisInviteToken = inviteToken;
    localStorage.setItem('inviteToken', inviteToken);
    //
    const dialogRef = this.dialog
      .open(TermsOfServiceComponent, {
        width: '100%',
        height: '90vh',
        disableClose: true,
        data: {
          code: inviteToken,
        },
      })
      .afterClosed()
      .subscribe((res) => {
        // update user data in local storage
        this.userService.me().subscribe({
          next: (resp) => {
            localStorage.setItem('user', JSON.stringify(resp));
            const userData = JSON.parse(localStorage.getItem('user')!);
            if (JSON.parse(userData.privacy_policy_acceptance)) {
              this.userService.setCloseDialog({
                isOpen: true,
                token: inviteToken,
              });
            }
          },
        });
      });
  }

  closeDialog() {
    //
    this.userService.setCloseDialog({
      isOpen: true,
      token: this.saveThisInviteToken,
    });
    //
    // if (this.dialogRef) {
    //   this.dialogRef.close();
    // }
  }

  // isDialogOpen(): boolean {
  //   return this.dialogRef !== undefined && this.dialogRef.componentInstance instanceof TermsOfServiceComponent;
  // }
  // ===================
}
