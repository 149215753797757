<app-top-nav></app-top-nav>
<div class="page-container">
  <div class="content">
    <!-- breadcrumb -->
    <nav
      class="breadcrumb"
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="5px"
    >
      <span class="link" [routerLink]="isNewVersion ? '/dashboard-v1' : '/dashboard'">Dashboard</span>
      <mat-icon>chevron_right</mat-icon>
      <span class="active">Output</span>
    </nav>
    <!-- Inline actions -->
    <div
      fxLayout="row"
      fxLayoutGap="20px"
      fxLayoutAlign="start stretch"
      class="container"
    >
      <div fxFlex="90%" fxFlex.md="70%">
        <div class="title">
          <h1 class="page-title">
            <b
              ><span matTooltipPosition="above" [matTooltip]="taskName">{{
                taskName | truncate : 30
              }}</span>
              - Output Data</b
            >
          </h1>
          <span class="sub-title">
            Use the Edit option to edit the output. To use this output file
            setting for a new input file, click
          </span>
          <strong>“Apply settings for new task”</strong>
        </div>
      </div>
      <div fxFlex="10%" fxFlex.md="30%" fxLayout="row" fxLayoutAlign="end end">
        <div
          class="button-section"
          fxLayout="row"
          fxLayoutGap="10px"
          fxLayoutAlign="end end"
        >
          <button
            routerLink="/dashboard/interim-output"
            [queryParamsHandling]="'preserve'"
            type="button"
            mat-stroked-button
            color="primary"
            [ngClass]="{'edit-btn': !disableEditFlag, 'faded-edit-btn': disableEditFlag}"
            [disabled]="disableEditFlag"
          >
            <img *ngIf="!disableEditFlag" src="../../../../assets/images/edit.svg" />
            <img *ngIf="disableEditFlag" src="../../../../assets/images/outputDataview/edit_faded.svg" />

            Edit
          </button>
          <button
            routerLink="/dashboard/upload-input-file"
            [queryParamsHandling]="'preserve'"
            class="reuse-btn"
            mat-flat-button
            color="primary"
            [disabled]="disableSave"
          >
            <!-- [disabled]="jssOutputService.isSampleBatch" -->
            Apply settings for new task
          </button>
        </div>
      </div>
    </div>

    <div class="filters-table-wrapper">
      <div
        fxLayout="row"
        fxLayoutGap="8px"
        fxLayoutAlign="space-between center"
        class="filter-section"
      >
        <!-- 1. filters left -->
        <div
          [style]="!selectedColumns?.length ? 'cursor: pointer;' : ''"
          (click)="!selectedColumns?.length ? openColumnDropdown() : ''"
          fxLayout="row"
          fxLayoutGap="8px"
          fxLayoutAlign="start center"
        >
          <div
            style="height: 45px"
            class="appear-out query-wrapper"
            fxLayout="row"
            fxLayoutAlign="space-between center"
          >
            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="80">
              <img
                *ngIf="!selectedColumns?.length"
                class="search-img"
                src="../../../../assets/images/search.svg"
                alt=""
              />
              <!-- <span class="search-placeholder" *ngIf="!selectedColumns?.length">Search here...</span> -->
              <mat-select
                #columnSelect
                placeholder="Search here..."
                class="custom-hide-arrow"
                [class.select-col-active]="selectedColumns?.length"
                disableOptionCentering="true"
                panelClass="drop-down-panel "
                (selectionChange)="onSelectSearchColumn()"
                (openedChange)="onOpenCloseColumnPanel($event)"
                [(ngModel)]="selectedColumns"
                multiple
              >
                <mat-select-trigger>
                  {{ selectedColumns?.[0] | truncate:25 || '' }}
                  <span
                    *ngIf="(selectedColumns?.length || 0) > 1"
                    class="example-additional-selection"
                  >
                    (+{{ (selectedColumns?.length || 0) - 1 }})
                  </span>
                </mat-select-trigger>

                <div
                  fxLayout="column"
                  fxLayoutGap="15px"
                  class="filter-options-container"
                  fxLayoutAlign="space-around stretch"
                >
                  <mat-form-field appearance="outline" class="inner-search">
                    <input
                      matInput
                      placeholder="Search column"
                      #searchVal
                      name="col-search"
                      (keyup)="filterList($event.target.value)"
                      (keydown)="$event.stopPropagation()"
                      [(ngModel)]="columnSearchText"
                    />
                    <mat-icon
                      matPrefix
                      svgIcon="search"
                      style="margin: 0 5px; height: 20px"
                    ></mat-icon>
                  </mat-form-field>
                </div>
                <div
                  class="action-option"
                  fxFlex="row"
                  fxLayoutAlign="space-between center"
                >
                  <button
                    mat-button
                    class="filter-reset-icon"
                    (click)="clearAll()"
                  >
                    <span>Clear all</span>
                  </button>
                </div>

                <mat-option
                  *ngFor="let option of allFilterColumns"
                  [value]="option.name"
                  [disabled]="option.disabled"
                >
                  {{ option.name }}
                </mat-option>
                <div
                  *ngIf="allFilterColumns?.length == 0"
                  class="no-result-message"
                  fxLayout="row"
                  fxLayoutAlign="center center"
                >
                  <span> No Results Found </span>
                </div>
              </mat-select>

              <mat-select
                (selectionChange)="onSelectCondition()"
                class="custom-hide-arrow"
                style="width: unset"
                [class.select-condtn-active]="selectedColumns?.length"
                panelClass="drop-down-panel condition-filter"
                *ngIf="selectedColumns?.length"
                [(ngModel)]="selectedCondition"
                disableOptionCentering="true"
              >
                <mat-option
                  [value]="each.value"
                  *ngFor="let each of conditions"
                  >{{ each.label }}</mat-option
                >
              </mat-select>

              <div
                *ngIf="
                  selectedColumns?.length &&
                  selectedCondition !== 'none' &&
                  !isSearchEntered
                "
                fxLayout="row"
                fxLayoutAlign="space-between center"
                class="search-container-filter"
              >
                <!-- <mat-form-field appearance="none" class="search-filter"> -->
                <input
                  autofocus
                  [(ngModel)]="searchQuery"
                  [disabled]="!selectedColumns"
                  id="search"
                  matInput
                  [placeholder]="selectedColumns ? 'Search...' : ''"
                  name="searchVal"
                  autocomplete="off"
                  (keyup.enter)="searchQuery?.trim() ? onEnterSearch() : ''"
                />
                <!-- <mat-icon matPrefix svgIcon="search"></mat-icon> -->
                <!-- </mat-form-field> -->
              </div>
              <div
                (click)="editSearch()"
                *ngIf="isSearchEntered && searchQuery"
                class="search-input-active"
              >
                <span>{{ searchQuery }}</span>
              </div>
            </div>
            <div style="padding: 5px">
              <img
                src="../../../../assets/images/filled-close.svg"
                (click)="clearSearchFilter($event)"
                *ngIf="selectedColumns?.length"
                class="clear-search"
              />
            </div>
          </div>
        </div>

        <!--2. pagination right-->
        <div class="" fxLayoutAlign="end end">
          <mat-paginator
            class="output-paginator"
            [length]="totalItems"
            [pageSize]="size"
            [pageIndex]="page - 1"
            [pageSizeOptions]="[5, 10, 20, 25, 50, 100]"
            (page)="onPaginateChange($event)"
            [showFirstLastButtons]="true"
          >
          </mat-paginator>
        </div>
      </div>
      <!-- <div fxLayout="column" fxLayoutAlign="center stretch" class="jss-container"> -->
      <!-- <div class="responsive" #sheet></div> -->
      <!-- table.component.html -->

      <!-- table.component.html -->

      <!-- output data table -->
      <div class="table-container">
        <table>
          <!-- Headers with Row Numbers -->
          <thead>
            <tr>
              <th class="fixed-column number-column"></th>
              <th
                class="fixed-column column-headers"
                *ngFor="let column of columnsList"
              >
                <div fxLayout="row" fxLayoutAlign="space-between center">
                  <div>
                    <span
                      [matTooltip]="column.name"
                      matTooltipPosition="above"
                      matTooltipShowDelay="500"
                      >{{ column.name | truncate : 20 }}</span
                    >
                    <span matTooltip="Sort" matTooltipPosition="above">
                      <img
                        class="sort-svg"
                        src="../../../../assets/images/sort-arrow.svg"
                        (click)="onSort(column.name)"
                      />
                    </span>
                  </div>
                  <div
                    matTooltip="Fill rate"
                    matTooltipPosition="above"
                    *ngIf="allFillRates"
                    class="fill-rate"
                    [ngClass]="{
                      'fill-red':
                        allFillRates[column.name] >= 0 &&
                        allFillRates[column.name] < 50,
                      'fill-yellow':
                        allFillRates[column.name] >= 50 &&
                        allFillRates[column.name] < 100,
                      'fill-green': allFillRates[column.name] >= 100
                    }"
                  >
                    {{ allFillRates[column.name] + "%" }}
                  </div>
                </div>
              </th>
            </tr>
          </thead>

          <!-- Data Rows with Row Numbers -->
          <tbody>
            <tr *ngFor="let row of tableData; let i = index">
              <td class="fixed-column number-column row-number">{{ row.row_no }}</td>
              <td
                [matTooltip]="row[column.name]"
                matTooltipPosition="above"
                matTooltipShowDelay="500"
                class="fixed-column"
                *ngFor="let column of columnsList"
              >
                {{ row[column.name] }}
              </td>
            </tr>
          </tbody>
        </table>
        <div
          *ngIf="isBusy; else noResult"
          class="no-result-message"
          fxLayout="row"
          fxLayoutAlign="center center"
        >
          <span>
            {{ "Loading..." }}
          </span>
        </div>
        <ng-template #noResult>
          <div
            *ngIf="tableData?.length == 0"
            class="no-result-message"
            fxLayout="row"
            fxLayoutAlign="center center"
          >
            <span> No Results Found </span>
          </div>
        </ng-template>
      </div>
    </div>

    <!-- </div> -->
  </div>
</div>
