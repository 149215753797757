<!-- title  -->
<div class="notifications-overlay">
  <div fxLayout="row" fxLayoutAlign="space-between" class="dialog-title" fxLayoutAlign="space-between center">
    <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start center" class="sub-text">
      <span *ngIf="dialogType==='notification'" class="title">Notifications</span>
      <span *ngIf="dialogType==='inbox'" class="title">Shared with you</span>
      <span *ngIf="dialogType==='invite'" class="title">Invites</span>
      <div class="dialog-count" fxLayout="row" fxLayoutAlign="center center">
        <div>{{unreadCount}}</div>
      </div>
      <!-- <span class="sub-text">(5 unread notifications)</span> -->
    </div>
    <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end center">
      <span class="mark-all-as-read" (click)="markAllasRead()">Mark all as read</span>
      <!-- <mat-icon (click)="dialogRef.close(false)">close</mat-icon> -->
    </div>
  </div>
  <!-- dialog content list -->
  <mat-dialog-content class="mat-typography notification-dialog-content" fxLayoutAlign="start center" fxLayout="column">
    <ul *ngIf="dialogData?.length > 0 && !isLoading">
      <li [class.unread]="!elem.read" *ngFor="let elem of dialogData; let i=index">
        <div class="notification-list-container" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="20px">
          <div class="avatar" id="user-avatar" fxFlex="none" fxLayout="column" fxLayoutAlign="center center"
            style="background-color: '#24a5ce'">
            <span>{{ elem.initials }}</span>
          </div>
          <div fxLayout="column" fxFlex="98">
            <!-- | highlightText: elem.highlight.text: elem.highlight.user: elem.highlight.entity_code   -->
            <span class="notification-content">{{elem.data}}</span>
            <span class="notification-time">{{elem.created_at | date: 'MMM d, y'}} at
              {{elem.created_at | date: 'h:mm a'}}</span>

            <div class="action-buttons" *ngIf="dialogType==='invite' || dialogType==='inbox'" fxLayoutGap="10px">
              <button [attr.mat-autofocus]="'false'" mat-raised-button color="primary" fxFlex='calc(50% - 5px)'
                *ngIf="elem.actions && elem.actions.length > 0"
                (click)="handleAction(elem.actions[0],elem.read,elem.notification_id)"><img *ngIf="dialogType==='inbox'"
                  src="../../../../assets/images/download.svg" />
                {{ elem.actions[0].label }}
              </button>
              <button [attr.mat-autofocus]="'false'" mat-stroked-button class="change-status-button"
                fxFlex='calc(50% - 5px)' *ngIf="elem.actions && elem.actions.length > 1"
                (click)="handleAction(elem.actions[1],elem.read,elem.notification_id)">
                {{ elem.actions[1].label }}
              </button>
              <!-- for distributor/supplier signup -->
              <!-- <div fxLayout="row"
                  *ngIf="elem.highlight.type == 'distributor_signup' || elem.highlight.type == 'supplier_signup'"
                  fxLayoutGap="10px"> -->
              <!-- Change status to offline -->
              <!-- <button mat-stroked-button class="change-status-button"
                    (click)="changeStatusToOffline(notification.highlight.type, notification.highlight.text)">Change
                    Status to Offline</button> -->
              <!-- Send Reminder -->
              <!-- <button mat-raised-button color="primary"
                    (click)="sendReminder(notification.send_reminder, notification.id, i)"
                    matTooltip="Follow-up on the invite" matTooltipPosition="above">Send
                    Reminder</button>
                </div> -->
              <!-- for accepting/rejecting new requests-->
              <!-- <div *ngIf="elem.highlight.type == 'distributor_request' || elem.highlight.type == 'supplier_request'"
                fxLayoutGap="10px"> -->
              <!-- Change status to offline -->

              <!-- <button [attr.mat-autofocus]="'false'" mat-stroked-button class="change-status-button"
                   matTooltip="Reject this request"
                  matTooltipPosition="above" fxFlex='calc(50% - 5px)'>
                  Reject</button> -->
              <!-- Send Reminder -->
              <!-- Inside your component's template -->

              <!-- <button [attr.mat-autofocus]="'false'" mat-raised-button color="primary"
                  (click)="acceptOrRejectRequest(elem.id, true, elem.id, i)" matTooltip="Accept this request"
                  matTooltipPosition="above" fxFlex='calc(50% - 5px)'>
                  Accept </button>
              </div>
              <div *ngIf="elem.highlight.type == 'download'"> -->

              <!-- Change status to offline -->
              <!-- <button [attr.mat-autofocus]="'false'" mat-raised-button color="primary" fxFlex='100%'
                  matTooltip="Download" matTooltipPosition="above">
                  <span fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
                    <img src="../../../assets/images/download.svg" />
                    <span>Download</span>
                  </span>
                </button> -->
              <!-- </div> -->
            </div>
          </div>
          <!-- mark as read -->
          <!-- <span color="primary" (click)="markAsRead(notification.id, i)"
            class="mark-as-read"></span> -->
          <div *ngIf="!elem.read">
            <mat-icon [matMenuTriggerFor]="menu1" [class.markread]="elem.read" svgIcon="more"></mat-icon>
            <mat-menu #menu1="matMenu">
              <button mat-menu-item (click)="markAsRead(elem.notification_id)">Mark as read</button>
            </mat-menu>
          </div>
          <div *ngIf="elem.read">
            <mat-icon [matMenuTriggerFor]="menu1" [class.markread]="elem.read" svgIcon="more"></mat-icon>
            <mat-menu #menu1="matMenu">
              <button mat-menu-item (click)="markAsUnRead(elem.notification_id)">Mark as unread</button>
            </mat-menu>
          </div>
        </div>
        <!-- <hr *ngIf="i!==dialogData.length-1"> -->
      </li>
    </ul>
    <div fxLayout="column" *ngIf="isLoading" style="min-height: 300px" fxLayoutAlign="center center">
      <mat-spinner strokeWidth="3" diameter="40"></mat-spinner>
    </div>
    <div fxLayout="column" *ngIf="dialogData.length == 0 && !isLoading" style="min-height: 300px"
      fxLayoutAlign="center center">
      <span class="no-notifications" *ngIf="dialogType==='notification'">You don't have any notifications.</span>
      <span class="no-notifications" *ngIf="dialogType==='inbox'">You don't have any files shared with you.</span>
      <span class="no-notifications" *ngIf="dialogType==='invite'">You don't have any invites.</span>
    </div>
  </mat-dialog-content>

  <div class="see-all-notifications" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
    <span *ngIf="dialogType==='notification'" (click)="seeAllNotifications('notification')">See all Notifications</span>
    <span *ngIf="dialogType==='inbox'" (click)="seeAllNotifications('inbox')">See all</span>
    <span *ngIf="dialogType==='invite'" (click)="seeAllNotifications('invite')">See all Invites</span>
    <mat-icon svgIcon="arrowRightFilled"></mat-icon>
  </div>
</div>
