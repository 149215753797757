import { Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[appHighlightFunctionKey]'
})
export class HighlightFunctionKeyDirective {

  @Input() appHighlightFunctionKey: string;
  
  private highlightWords = ['Create', 'Remove', 'Rename', 'Merge'];
  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['appHighlightFunctionKey']) {
      this.applyHighlight();
    }
  }

  private applyHighlight() {
    const text = this.appHighlightFunctionKey || '';
    let highlightedText = text;

    this.highlightWords.forEach(word => {
      const regex = new RegExp(`\\b${word}\\b`, 'gi');
      highlightedText = highlightedText.replace(regex, `<span style="border-radius: 4px;
background: var(--primary-10, #D0DAFA);color: var(--neutral-100, #0C0E13);font-family: 'IBM Plex Sans';
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 21px;padding: 0px 4px;margin:0px 4px;">${word}</span>`);
    });

    this.renderer.setProperty(this.el.nativeElement, 'innerHTML', highlightedText);
  }
  
}
