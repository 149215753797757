export const environment = {
  production: false,
  name: 'dev',
  auth0: {
    domain: 'auth-v3-dev.datax.ai',
    clientId: 'c48WZChHGF9WrYYdwMgR6gxtO4V1tLzk',
    callbackURL: window.location.origin + '/loading',
    useRefreshTokens: true,
    audience: 'https://*.datax.ai',
  },
  default_return_url: 'https://login.v3-app-dev.datax.ai',
  recaptcha: {
    siteKey: '6LeKNNAmAAAAANrjA71VpCpm0obCnmDpvsc1MhAm',
  },
  // outputApiHost: 'https://dev.data-explorer.datax.ai',
  outputApiKey: '1N2O16QqWpdR9ggyfdDNye1YPYUSsdoR',

  feature_flags: {
    SHARE_BATCH: false,
    INVITE_MEMBERS: false,
    LENIENT_EMAIL_VALIDATION: false,
    UPGRADE_PLAN: false,
  },
  uiVersion: 'v3.5.12',
};
