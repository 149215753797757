import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { PrivacyPolicyService } from '../_services/privacy-policy.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorRouteGuardGuard {
  constructor(private privacyPolicyService: PrivacyPolicyService, private router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    // Close dialog if open when navigating to 'error' route
    this.privacyPolicyService.closeDialog();
    console.log('closing privacy policy dialog 1');
    
    // if (this.privacyPolicyService.isDialogOpen()) {
    //   this.privacyPolicyService.closeDialog(); 
    // }
    return true;
  }
}
