import {
  Component,
  ElementRef,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  of,
  Subject,
  Subscription,
  switchMap,
  takeUntil,
} from 'rxjs';
import { ExistingFilesComponent } from 'src/app/_dialogs/existing-files/existing-files.component';
import { BatchUploadService } from 'src/app/_services/batch-upload.service';
import { LoadingService } from 'src/app/_services/loading.service';
import { SnackbarService } from 'src/app/_services/snackbar.service';
import { ValidationService } from 'src/app/_services/validation.service';

@Component({
  selector: 'app-upload-input-file',
  templateUrl: './upload-input-file.component.html',
  styleUrls: ['./upload-input-file.component.scss'],
})
export class UploadInputFileComponent implements OnInit {
  uploadForm: FormGroup;
  inputFile?: File;
  outputFile?: File;
  showError: boolean;
  organisation_id: string | null;
  batchList: any[] = [];
  inputFileName: string | null = null;
  outputFileName: string | null = null;
  isNameUnique: boolean | null = null;
  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;
  @ViewChild('fileOutput') fileOutput!: ElementRef<HTMLInputElement>;
  private searchTerms$ = new Subject<string>();
  private cancelBatchCreation: Subscription;
  private unsubscribe$: Subject<void> = new Subject<void>();
  moveForward: boolean = true;
  batchId: number | null;
  taskNameQueryParam: string | null;
  // isNetworkOnline: boolean = navigator.onLine;

  constructor(
    private formBuilder: FormBuilder,
    private snackBar: SnackbarService,
    private dialog: MatDialog,
    private batchUploadService: BatchUploadService,
    private router: Router,
    private route: ActivatedRoute,
    private formValidation: ValidationService,
    private loadingService: LoadingService
  ) {
    this.cancelBatchCreation = this.batchUploadService.call$.subscribe(
      (resp: boolean) => {
        this.moveForward = resp;
        if (resp == false) {
          this.unsubscribe$.next();
          this.unsubscribe$.complete();
        }
      }
    );
  }

  ngOnInit(): void {
    this.taskNameQueryParam =
      this.route.snapshot.queryParamMap.get('task_name');
    this.organisation_id = localStorage.getItem('currentOrganizationCode');
    // this.isNetworkOnline = navigator.onLine;

    // window.addEventListener('online', () => {
    //   this.isNetworkOnline = true;
    //   if (this.batchList.length == 0) {
    //     this.getBatchList(this.organisation_id, this.taskNameQueryParam);
    //   }
    // });

    window.addEventListener('offline', () => {
      // this.isNetworkOnline = false;
    });
    this.getBatchList(this.organisation_id, this.taskNameQueryParam);

    this.uploadForm = this.formBuilder.group({
      taskName: new FormControl('', [
        Validators.required,
        Validators.maxLength(100),
        this.formValidation.TaskNamePatternValidation(),
        this.formValidation.minLengthWithoutWhitespace(3),
      ]),
      // description: new FormControl('', Validators.maxLength(1000)),
      batch: [this.batchId],
    });
    // Subscribe to changes in the batch control
    this.uploadForm.get('batch')?.valueChanges.subscribe((batchId) => {
      // Update the batchId property
      this.batchId = batchId;
    });

    // Add this to hide the error message when typing
    this.uploadForm.get('taskName')?.valueChanges.subscribe(() => {
      this.showError = false;
    });
    // These are being set while uplaoding a file with prev setiings and clearing here because user might not reach to interim due to any issue in progress page and these will be remain and cause issue to next batch upload
    localStorage.removeItem('task_name');
    localStorage.removeItem('report');
    localStorage.removeItem('isPrevSetBatch');
  }

  ngAfterViewInit(): void {
    //<--------To check valid task name------->
    // this.uploadForm
    //   .get('taskName')
    //   ?.valueChanges.pipe(
    //     debounceTime(500),
    //     distinctUntilChanged(),
    //     switchMap((value: string) =>
    //       this.batchUploadService
    //         .checkNameUniqueness(value, this.organisation_id)
    //         .pipe(
    //           catchError((error) => {
    //             this.isNameUnique = false;
    //             this.showError = true;
    //             // Handle error as needed
    //             return of(null);
    //           })
    //         )
    //     )
    //   )
    //   .subscribe({
    //     next: (resp) => {
    //       this.trimWhitespace();
    //       if (resp !== null) {
    //         // Only set isNameUnique to true when the response is successful
    //         this.isNameUnique = true;
    //       }
    //     },
    //   });
  }

  ngOnDestroy() {}

  // onKeyUp(event: any) {
  //   const taskName = event.target.value;
  //   this.searchTerms$.next(taskName);
  // }

  async taskNameUniqueness(taskName: string): Promise<void> {
    try {
      const resp = await this.batchUploadService
        .checkNameUniqueness(taskName, this.organisation_id)
        .toPromise();

      // Only set isNameUnique to true when the response is successful
      if (resp !== null) {
        this.isNameUnique = true;
      }
    } catch (error) {
      this.isNameUnique = false;
      this.showError = true;
      this.snackBar.showSnackbar('Batch name is not unique', '', 'error');
    } finally {
      this.loadingService.setLoading(false);
    }
  }

  // Method to be called on button click to trim whitespace from both start and end
  trimWhitespace() {
    const taskNameControl = this.uploadForm.get('taskName') as FormControl;

    if (taskNameControl.value && typeof taskNameControl.value === 'string') {
      const trimmedValue = taskNameControl.value.trim(); // Trim from both start and end

      if (trimmedValue !== taskNameControl.value) {
        // Update the control value with the trimmed value
        taskNameControl.setValue(trimmedValue);
      }
    }
  }

  onInputFileSelect(event: Event) {
    this.inputFile = (event.target as HTMLInputElement).files?.[0];

    if (this.inputFile) {
      const allowedExtensions = ['csv', 'xlsx'];
      const fileExtension = this.inputFile.name.split('.').pop()?.toLowerCase();
      const maxSizeInBytes = 100 * 1024 * 1024; // 100MB

      if (!fileExtension || allowedExtensions.indexOf(fileExtension) === -1) {
        this.snackBar.showSnackbar(
          'Please select a CSV or Excel file',
          '',
          'error'
        );
        this.clearInputFile();
      } else if (this.isFileEmpty(this.inputFile.size)) {
        this.snackBar.showSnackbar(`File can't be empty`, '', 'error');
        this.clearInputFile();
      } else if (this.inputFile.size > maxSizeInBytes) {
        this.snackBar.showSnackbar(
          'Input file size should not exceed 100MB',
          '',
          'error'
        );
        this.clearInputFile();
      } else {
        this.inputFileName = this.inputFile.name; // Display selected file name
      }
    }
  }
  clearInputFile() {
    this.fileInput.nativeElement.value = '';
    this.inputFileName = null;
  }

  onOutputFileSelect(event: Event) {
    this.outputFile = (event.target as HTMLInputElement).files?.[0];

    if (this.outputFile) {
      const allowedExtensions = ['csv', 'xlsx'];
      const fileExtension = this.outputFile.name
        .split('.')
        .pop()
        ?.toLowerCase();
      const maxSizeInBytes = 10 * 1024 * 1024; // 10MB

      if (!fileExtension || allowedExtensions.indexOf(fileExtension) === -1) {
        this.snackBar.showSnackbar(
          'Please select a CSV or Excel file',
          '',
          'error'
        );
        this.clearOutputFile();
      } else if (this.isFileEmpty(this.outputFile.size)) {
        this.snackBar.showSnackbar(`File can't be empty`, '', 'error');
        this.clearOutputFile();
      } else if (this.outputFile.size > maxSizeInBytes) {
        this.snackBar.showSnackbar(
          'Sample output file size should not exceed 10MB',
          '',
          'error'
        );
        this.clearOutputFile();
      } else {
        this.outputFileName = this.outputFile.name; // Display selected file name
      }
    }
  }

  clearOutputFile() {
    this.fileOutput.nativeElement.value = '';
    this.outputFileName = null;
  }

  isFileEmpty(size: number) {
    if (size === 0) {
      return true;
    }
  }

  truncateFileName(fileName: string, maxLength: number): string {
    if (fileName.length <= maxLength) {
      return fileName;
    } else {
      const truncatedName = fileName.substring(0, maxLength - 3);
      const extension = fileName.substring(fileName.lastIndexOf('.'));
      return truncatedName + '..' + extension;
    }
  }

  //dialog
  openFileSelector(type: string) {
    const dialogRef = this.dialog.open(ExistingFilesComponent, {
      width: '826px',
      autoFocus: false,
      disableClose: true,
      data: {
        title:
          type === 'ip' ? 'Input File - Upload' : 'Sample Output File - Upload',
        from: type,
      },
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res.data != null) {
        if (type === 'ip') {
          this.inputFileId = res.data.id;
          this.inputFileName = res.data.file_name;
          // console.log(this.inputFileId, 'in');
        } else {
          this.outputFileId = res.data.id;
          this.outputFileName = res.data.file_name;
          // console.log(res.data.file_name, 'out');
        }
      }
    });
  }
  //to clear the id of previously selected files
  clearPreviousSelection(from: string) {
    if (from == 'ip') {
      this.inputFileId = null;
    } else {
      this.outputFileId = null;
    }
  }
  // ======
  trimFileName(fileName: string, maxLength: number): string {
    // Extract the file extension from the fileName
    const extensionIndex = fileName.lastIndexOf('.');
    
    // Handle cases where there is no extension
    if (extensionIndex === -1) {
        return fileName.length > maxLength ? fileName.substring(0, maxLength) + '...' : fileName;
    }
    
    const extension = fileName.substring(extensionIndex);
    const nameWithoutExtension = fileName.substring(0, extensionIndex);
    
    // Calculate the maximum length for the base name
    const maxLengthForBaseName = maxLength;

    // Trim the base name if necessary and add ellipsis
    const trimmedBaseName = nameWithoutExtension.length > maxLengthForBaseName
        ? nameWithoutExtension.substring(0, maxLengthForBaseName) + '...'
        : nameWithoutExtension;

    // Return the trimmed base name with the ellipsis (if added) and extension
    return trimmedBaseName + extension;
}




  // ======

  fileCount: number;
  async onSubmit(): Promise<void> {
    this.loadingService.setLoading(true);
    this.trimWhitespace();
    // Wait for the result of taskNameUniqueness before proceeding
    await this.taskNameUniqueness(this.uploadForm.value.taskName);
    if (this.isNameUnique) {
      this.loadingService.setLoading(false);
      this.moveForward = true;
      if (this.uploadForm.valid) {
        this.moveForward = true;
        if (this.inputFileId == null && this.outputFileId != null) {
          //when input file is selected from previous upload
          this.uploadInputFile((this.fileCount = 1));
        } else if (this.outputFileId == null && this.inputFileId != null) {
          //when output file is selected from previous upload
          this.uploadOutputFile((this.fileCount = 1));
        } else if (this.inputFileId != null && this.outputFileId != null) {
          //when no input and output files are selected from previous upload
          this.batchUploadComplete(this.uploadForm.value);
        } else if (this.inputFileId == null && this.outputFileId == null) {
          this.uploadInputFile((this.fileCount = 2));
        }
        this.router.navigate(['/dashboard/upload-progress']);
        // Do something with the form data, e.g., save to backend
        // console.log(this.uploadForm.value);
      }
    }
  }
  outputFileId: number | null = null;
  inputFileId: number | null = null;
  mainFileProgress: number = 0;

  // main file upload
  uploadInputFile(fileCount: number) {
    this.batchUploadService
      .uploadFile(this.inputFile, 'input_file', this.organisation_id, fileCount)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (resp) => {
          this.inputFileId = resp.inputFileId;
          this.mainFileProgress = Math.round(resp.progress);
          // console.log(
          //   this.mainFileProgress,
          //   '=inputProgress',
          //   this.inputFileId
          // );
          if (this.mainFileProgress === 100) {
            if (this.outputFileId == null) {
              this.uploadOutputFile((this.fileCount = 2));
            } else {
              this.batchUploadComplete(this.uploadForm.value);
            }
          }
        },
        error: (error) => {
          this.snackBar.showSnackbar(
            'Previous task creation failed',
            '',
            'error'
          );
          this.router.navigate(['/dashboard/upload-input-file']);
        },
      });
  }

  uploadOutputFile(fileCount: number) {
    this.batchUploadService
      .uploadFile(
        this.outputFile,
        'template_output_file',
        this.organisation_id,
        fileCount
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (resp) => {
          this.outputFileId = resp.outputFileId;
          this.mainFileProgress = Math.round(resp.progress);
          // console.log(
          //   this.mainFileProgress,
          //   '=OutputProgress',
          //   this.outputFileId
          // );
          if (this.mainFileProgress === 100) {
            this.batchUploadComplete(this.uploadForm.value);
          }
        },
        error: (error) => {
          this.snackBar.showSnackbar(
            'Previous task creation failed',
            '',
            'error'
          );
          this.router.navigate(['/dashboard/upload-input-file']);
        },
      });
  }

  batchUploadComplete(batchData: any) {
    if (this.moveForward) {
      if (batchData.batch) {
        localStorage.setItem('report', 'default');
        localStorage.setItem('isPrevSetBatch', 'true');
      }
      localStorage.setItem('task_name', batchData.taskName);
      this.batchUploadService
        .batchUploadComplete(
          this.organisation_id,
          batchData,
          this.inputFileId,
          this.outputFileId
        )
        .subscribe({
          next: (resp) => {
            this.onReset();
            // this.snackBar.showSnackbar(
            //   'Files uploaded successfully.',
            //   null,
            //   'success'
            // );
            this.router.navigate(['/select-header']);
          },

          error: (error) => {
            this.snackBar.showSnackbar(
              'Previous task creation failed',
              '',
              'error'
            );
            this.router.navigate(['/dashboard/upload-input-file']);
          },
        });
    }
  }

  onReset(): void {
    this.uploadForm.reset();
    if (this.inputFileName) {
      this.fileInput.nativeElement.value = '';
      this.inputFileName = null;
    }
    if (this.outputFileName) {
      this.fileOutput.nativeElement.value = '';
      this.outputFileName = null;
    }
  }

  //<-----getting the batch list for previous batch setting--->

  getBatchList(
    organisation_id: string | null,
    taskNameQueryParam: string | null
  ) {
    this.batchUploadService.getBatchList(organisation_id).subscribe({
      next: (data: any) => {
        this.batchList = data.batch_list;
        // Find the batch with a matching task_name
        const matchingBatch = this.batchList.find(
          (batch) => batch.task_name === taskNameQueryParam
        );
        if (matchingBatch) {
          this.batchId = matchingBatch.id;
          this.uploadForm.get('batch')?.setValue(this.batchId);
        } else {
          // Handle the case where no match is found, you can set a default value if needed
          this.batchId = null; // Set a default value or handle the case as per your requirements
        }
      },
      error: (error: any) => {
        console.log('error', error);
      },
    });
  }
}
