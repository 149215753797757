import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'functionQueryListSorting'
})
export class FunctionQueryListSortingPipe implements PipeTransform {

  transform(array: any[], field: string, order: 'asc' | 'desc' = 'desc'): any[] {
    if (!Array.isArray(array)) {
      return array;
    }
    return array.sort((a, b) => {
      const dateA = new Date(a[field]).getTime();
      const dateB = new Date(b[field]).getTime();
      return order === 'desc' ? dateB - dateA : dateA - dateB;
    });
  }

}