import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, map, startWith } from 'rxjs';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-version-history',
  templateUrl: './version-history.component.html',
  styleUrls: ['./version-history.component.scss'],
})
export class VersionHistoryComponent implements OnInit {
  constructor() {}
  enableSubTitleInfoIcon: boolean = false;
  flexibleDiv: HTMLElement | null;
  resizableHandle: HTMLElement | null;
  resizeButton: HTMLElement | null;
  initialWidth: any;
  startX: number;
  maxWidth: any;
  newWidth: any;
  selectedInputHeadersContainer: any = [];
  inputHeader = new FormControl('');
  inputHeaderList: string[] = [
    'UPC',
    'MPN',
    'Quantity',
    'Assembly Required',
    'Item Model Number',
    'California Prop 65 Warning Short Message Compliancy',
  ];
  outputHeaderControl = new FormControl();
  filteredOutputHeaders: Observable<string[]>;
  outputHeaders: string[] = [
    'AIC/Supplier SKU',
    'BIC/Supplier SKU',
    'CIC/Supplier SKU',
    'MIC/Supplier SKU',
    'ffjdsjfn dsjfbjdbsf sdfjdsnfjn sdjfnjdsnfjnds fdsjnfjdsnfjndsf dsjfndsjnf djsnfjdsnf dsfjdsnfjdsnf',
  ];
  selectedInputHeaders: any = [];

  ngOnInit(): void {
    this.openOverlay();
    // output header function========
    this.filteredOutputHeaders = this.outputHeaderControl.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value || ''))
    );
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.selectedInputHeadersContainer,
      event.previousIndex,
      event.currentIndex
    );
  }

  //
  private _filter(value: string): string[] {
    const filterValue = this._normalizeValue(value);
    return this.outputHeaders.filter((outputHeader) =>
      this._normalizeValue(outputHeader).includes(filterValue)
    );
  }

  private _normalizeValue(value: string): string {
    return value.toLowerCase().replace(/\s/g, '');
  }

  checkInputHeaderChange = (inputHeader: any) => {
    console.log(inputHeader.value);
    this.selectedInputHeadersContainer = inputHeader.value;
    console.log(this.selectedInputHeadersContainer.length);

    // this.selectedInputHeaders = inputHeader.value;
    // console.log('selectedInputHeaders: ', this.selectedInputHeaders);
  };

  onOpenCloseInputHeaderOptionPanel = (e: any) => {
    console.log('check ');
  };

  closeVersionHistory = () => {
    document.getElementById('flexibleDiv')!.style.display = 'none';
  };

  showSubTitleIconInfo = () => {
    this.enableSubTitleInfoIcon = !this.enableSubTitleInfoIcon;
  };

  openOverlay = () => {
    this.flexibleDiv = document.getElementById('flexibleDiv');
    this.resizableHandle = document.getElementById('resizableHandle');
    this.resizeButton = document.getElementById('resizeButton');

    if (this.flexibleDiv && this.resizableHandle && this.resizeButton) {
      this.resizableHandle.addEventListener('mousedown', this.startResize);
      this.resizeButton.addEventListener('click', this.resizeToFivePercent);
    }
  };

  startResize = (event: MouseEvent) => {
    event.preventDefault();
    if (this.flexibleDiv) {
      this.initialWidth = this.flexibleDiv.offsetWidth;
      this.startX = event.clientX;
      this.maxWidth = window.innerWidth - this.startX + this.initialWidth;
    }
    window.addEventListener('mousemove', this.resize);
    window.addEventListener('mouseup', this.stopResize);
  };
  resizeToFivePercent = () => {
    if (this.flexibleDiv) {
      this.flexibleDiv.style.minWidth = 'auto';
      this.flexibleDiv.style.width = '60px';
    }
  };
  resize = (event: MouseEvent) => {
    this.newWidth = this.initialWidth - (event.clientX - this.startX);
    this.newWidth = Math.min(this.newWidth, this.maxWidth);
    if (this.flexibleDiv) {
      this.flexibleDiv.style.width = this.newWidth + 'px';
    }
  };
  stopResize = () => {
    window.removeEventListener('mousemove', this.resize);
    window.removeEventListener('mouseup', this.stopResize);
  };
}
