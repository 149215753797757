  <app-top-nav></app-top-nav>
  <div class="page-container">
    <div class="content">
      <div class="upload-area">
        <form fxLayout="column" [formGroup]="uploadForm" (ngSubmit)="onSubmit()">
          <mat-label style="margin-bottom: 4px"><strong>Batch name</strong>
            <span class="required"> *</span></mat-label>
          <mat-form-field appearance="outline">
            <!-- <input matInput formControlName="taskName" placeholder="Enter the task name" (input)="showError = true"
            (blur)="showError = true"> -->
            <input matInput formControlName="taskName" placeholder="Enter the batch name" />
            <mat-error *ngIf="uploadForm.get('taskName')?.hasError('required')">
              Batch Name is required.
            </mat-error>
            <mat-error *ngIf="uploadForm.get('taskName')?.hasError('maxlength')">
              Batch name can't be more than 100 characters.
            </mat-error>
            <mat-error *ngIf="uploadForm.get('taskName')?.hasError('minLengthWithoutWhitespace')">
              Batch name needs to have at least 3 characters.
            </mat-error>
            <mat-error *ngIf="uploadForm.get('taskName')?.hasError('invalidCharacters')">
              Batch name can't have any special characters except for '-'.
            </mat-error>
            <mat-error *ngIf="uploadForm.get('taskName')?.hasError('invalidStart')">
              Batch name can't start or end with a special character.</mat-error>
          </mat-form-field>
          <div style="
            color: #f44336;
            font-size: 14px;
            margin: -15px 0px 15px 14px;
            animation: example2;
            animation-duration: 0.3s;
          " *ngIf="
            !isNameUnique && this.uploadForm.get('taskName')?.valid && showError
          ">
            Please enter a batch name which is unique to this workspace
          </div>

          <!-- <mat-label><strong>Short Description (Optional)</strong></mat-label>
        <mat-form-field appearance="outline">
          <textarea rows="5" matInput formControlName="description"
            placeholder="Write a short description of the transformation task." (input)="showError = true"
            (blur)="showError = true"></textarea>
          <mat-error *ngIf="uploadForm.get('description')?.hasError('maxlength') && showError">
            Description should not exceed 1000 characters.
          </mat-error>
        </mat-form-field> -->

          <mat-label style="margin-bottom: 4px; margin-top: 8px"><strong>Apply settings from previous batch</strong>
          </mat-label>
          <mat-form-field style="margin-bottom: 10px" appearance="outline" class="app-dropdown">
            <mat-select placeholder="{{
                taskNameQueryParam ? taskNameQueryParam : 'Select a completed batch'
              }}" [disabled]="taskNameQueryParam !== null" panelClass="drop-down-panel" formControlName="batch"
              disableOptionCentering>
              <mat-option *ngIf="batchId != null" (click)="uploadForm.get('batch').reset(null)">None</mat-option>
              <!-- Check if batchList is empty -->
              <mat-option *ngIf="batchList.length === 0" [value]="'no-batch'" disabled>No batches created</mat-option>
              <!-- Iterate over batchList if it's not empty -->
              <mat-option *ngFor="let batch of batchList" [value]="batch.id">
                {{ batch.task_name }}
              </mat-option>
            </mat-select>
            <mat-icon matSuffix svgIcon="DownArrow" class="selection-arrow-icon"></mat-icon>
          </mat-form-field>
          <!-- ========== test ================ -->
          <mat-label>
            <strong>Input file</strong>
            <span class="required"> *</span>
          </mat-label>
          <span class="info">
            File which needs to be transformed. Supported file formats are .csv and .xlsx. Maximum upload file size 100MB.
          </span>
          
          <input #fileInput (change)="onInputFileSelect($event)" type="file" id="fileInput" accept=".csv, .xlsx" hidden />
          <div *ngIf="!inputFileName" class="file-selection" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="25px">
            <div (click)="fileInput.click()" fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="space-between center" class="new-file">
              <img src="../../../../assets/images/upload-screen/Monitor.svg" />
              <span style="font-weight: bold;">Upload new file</span>
            </div>
            <div (click)="openFileSelector('ip')" fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="space-between center" class="prev-file">
              <img src="../../../../assets/images/upload-screen/previous-file.svg" />
              <span style="font-weight: bold;">Previously uploaded</span>
            </div>
            <div fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="space-between center" class="gdrive">
              <img src="../../../../assets/images/upload-screen/gdrive.svg" />
              <span style="font-weight: bold;">Google drive (coming soon)</span>
            </div>
          </div>
          
          <div *ngIf="inputFileName" class="chip" matTooltip="{{inputFileName}}" matTooltipPosition="above" matTooltipClass="custom-tooltip">
            <div class="text">
              {{ trimFileName(inputFileName, 20) }}
            </div>
            <span (click)="clearInputFile()" class="closebtn">
              <mat-icon (click)="clearPreviousSelection('ip')">close</mat-icon>
            </span>
          </div>
          
          
          
          
          
          
          <!-- ========================== -->

          <mat-label><strong>Sample output file</strong>
            <span class="required"> *</span></mat-label>
          <span class="info">Example of the file into which you want your data transformed. 5-10
            rows of sample data would be ideal to have. Supported file formats
            are .csv and .xlsx. Maximum upload file size 10MB.</span>

          <input #fileOutput (change)="onOutputFileSelect($event)" type="file" id="fileInput" accept=".csv, .xlsx"
            hidden />
          <div *ngIf="!outputFileName" class="file-selection" fxLayout="row" fxLayoutAlign="space-between center"
            fxLayoutGap="25px">
            <div (click)="fileOutput.click()" fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="space-between center"
              class="new-file">
              <img src="../../../../assets/images/upload-screen/Monitor.svg" />
              <span><strong>Upload new file</strong></span>
            </div>

            <div (click)="openFileSelector('op')" fxLayout="column" fxLayoutGap="10px"
              fxLayoutAlign="space-between center" class="prev-file">
              <img src="../../../../assets/images/upload-screen/previous-file.svg" />
              <span><strong>Previously uploaded</strong></span>
            </div>
            <div fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="space-between center" class="gdrive">
              <img src="../../../../assets/images/upload-screen/gdrive.svg" />
              <span><strong>Google drive (coming soon)</strong></span>
            </div>
          </div>

          <div *ngIf="outputFileName" class="chip" [matTooltip]="outputFileName" matTooltipPosition="above" matTooltipClass="custom-tooltip">
            <!--  -->
            <div class="text">
              {{ trimFileName(outputFileName, 20) }}
            </div>
            <!--  -->
            <!-- <div [ngStyle]="{
              minWidth: (outputFileName | midTruncate: 18 : 18)[1] ? '400px' : 'fit-content'
            }" class="text">{{ (outputFileName | midTruncate: 18 : 18) }}</div> -->
            <span (click)="clearOutputFile()" class="closebtn">
              <mat-icon (click)="clearPreviousSelection('op')">close</mat-icon>
            </span>
          </div>

          <div class="actions" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px" class="buttons">
            <button class="btn" type="button" mat-stroked-button (click)="onReset()">
              Reset
            </button>
            <button [disabled]="
              !uploadForm.valid ||
              !inputFileName ||
              !outputFileName " class="btn" type="submit" mat-flat-button color="primary">
              Upload
            </button>
          </div>
        </form>
      </div>
      <div class="all-fields-text">All fields marked with an asterisk (<span style="color: red;">*</span>) are required
      </div>

    </div>
  </div>
