import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/_services/user.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NotificationDialogComponent } from '../../../_dialogs/notification-dialog/notification-dialog.component';
import { NotificationsService } from 'src/app/_services/notifications.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SnackbarService } from 'src/app/_services/snackbar.service';
import { LoadingService } from 'src/app/_services/loading.service';
import { AccountSettingsService } from 'src/app/_services/account-settings.service';
import { HomeComponent } from '../../dashboard/home/home.component';
import { AuthService } from '@auth0/auth0-angular';
import markerSDK from '@marker.io/browser';
import { AppConfigService } from 'src/app/_services/app-config.service';

@Component({
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.scss'],
})
export class TopNavComponent implements OnInit {
  notifications: any[] = [];
  downloads: any[] = [];
  invites: any[] = [];
  home: any;
  accountSettings: any;
  myNetwork: string;
  myNetworkLabel: string;
  currentUrl: any;
  dialogRef: MatDialogRef<NotificationDialogComponent>;
  s: number;
  user: any;
  unreads: number;
  orgCode: any;
  userImageColors: any[] = [
    '#ffe4c4',
    '#f0f8ff',
    '#f5f5dc',
    '#6495ed',
    '#bdb76b',
    '#d8bfd8',
    '#008080',
    '#d2b48c',
    '#87ceeb',
    '#b0e0e6',
  ];
  selectedBgColor: string;
  userInitials: string;
  notificationCount: number;
  inviteCount: number;
  inboxCount: number;
  orgList: any[] = [];
  currentUserOrg: any;
  currentUserOrgCode: any;
  profileJson: any;
  emailVerify: boolean;
  osName: string;
  isNewVersion: any;

  constructor(
    private userService: UserService,
    private router: Router,
    private dialog: MatDialog,
    private notificationsService: NotificationsService,
    private snackbarService: SnackbarService,
    private loadingService: LoadingService,
    private accountSettingsService: AccountSettingsService,
    private route: ActivatedRoute,
    public auth: AuthService,
    private appConfig: AppConfigService
  ) {}
  ngOnInit(): void {
    // 
    this.isNewVersion = this.appConfig.getConfig().is_new_version;
    // 
    this.osName =
      navigator.userAgent.indexOf('Mac') != -1 ? 'Macintosh' : 'Others';

    this.currentUrl = this.router.url;
    this.user = this.userService.getUser();

    this.orgCode = localStorage.getItem('currentOrganizationCode');
    this.getNotificationsCount();

    this.currentUserOrgCode = localStorage.getItem('currentOrganizationCode');
    if (!this.currentUserOrgCode) {
      this.getMemberships();
    }
    this.getUserInitials();
    // Subscribe to trigger from service
    this.userService.userInitials$.subscribe(() => {
      this.getUserInitials();
    });
    // Subscribing to user profile changes and updating local variables accordingly
    this.auth.user$.subscribe((profile) => {
      this.profileJson = profile;
      this.emailVerify = this.profileJson.email_verified;
    });
  }

  redirectToHomepage() {
    // 
    this.appConfig.getConfig().is_new_version ? this.router.navigate(['/dashboard-v1']) : this.router.navigate(['/dashboard']);
    // 
  }

  /*** get user initials */
  getUserInitials = () => {
    const userData = JSON.parse(localStorage.getItem('user')!);
    this.userInitials = userData.initials;
  };

  /****  get notifications */
  getNotificationsCount = () => {
    this.notificationsService.getNotificationsCount(this.orgCode).subscribe({
      next: (resp: any) => {
        // this.loadingService.setLoading(false);
        this.notificationCount = resp.notification_unread_count;
        this.inboxCount = resp.download_unread_count;
        this.inviteCount = resp.invite_unread_count;
      },
      error: (error: HttpErrorResponse) => {
        this.snackbarService.showSnackbar(error.error.detail, ' ', 'error');
      },
    });
  };

  /****  get notifications */
  // getNotificationsForDialog = () => {
  //   let obj = {
  //     page: 1,
  //     page_size: 5,
  //     type: 'NOTIFICATION',
  //     sub_type: 'UNREAD',
  //   };
  //   this.notificationsService.getNotifications(obj).subscribe({
  //     next: (resp) => {
  //       // console.log(resp);
  //       this.notifications = resp.data.result;
  //       this.loadingService.setLoading(false);
  //     },
  //     error: (error: HttpErrorResponse) => {
  //       this.loadingService.setLoading(false);
  //       this.snackbarService.showSnackbar(error.error.detail, null, 'error');
  //     },
  //   });
  // };

  /*** log the user out */
  logout() {
    // localStorage.clear();
    // this.loadingService.setLoading(true);
    // this.userService.logout().subscribe({
    //   next: (resp) => {
    //     this.loadingService.setLoading(false);
    //     this.router.navigate(['/signin']);
    //   },
    // });
    this.userService.logUserOut();
  }

  /** open notification dialog */
  openNotificationsDialog = (from: string) => {
    if (from == 'notification') {
      this.dialogRef = this.dialog.open(NotificationDialogComponent, {
        data: {
          content: this.notifications,
          type: from,
          PrimaryTrigger: (action: any) => {},
          secondaryTrigger: (action: any) => {},
        },
        position: { right: '140px', top: '80px' },
        width: '450px',
        autoFocus: false,
      });
    } else if (from == 'inbox') {
      this.dialogRef = this.dialog.open(NotificationDialogComponent, {
        data: {
          content: this.downloads,
          type: from,
          PrimaryTrigger: (action: any) => {},
          secondaryTrigger: (action: any) => {},
        },
        position: { right: '170px', top: '80px' },
        width: '450px',
        autoFocus: false,
      });
    } else {
      this.dialogRef = this.dialog.open(NotificationDialogComponent, {
        data: {
          content: this.invites,
          type: from,
          PrimaryTrigger: (action: any) => {},
          secondaryTrigger: (action: any) => {},
        },
        position: { right: '210px', top: '80px' },
        width: '450px',
        autoFocus: false,
      });
    }
  };
  /**
   * Here in top-nav, call membership API to populate drop down, but read currentUserOrgCode from local storage. Change 'currentUserOrgCode' only when user selects another organization from drop down
   */
  getMemberships = () => {
    this.accountSettingsService.listMemberships().subscribe({
      next: (resp: any) => {
        this.orgList = resp.result;

        this.currentUserOrgCode = localStorage.getItem(
          'currentOrganizationCode'
        );
      },
      error: (error: HttpErrorResponse) => {
        // console.log(error);
      },
    });
  };

  onClickOfMatMenu = () => {
    this.getMemberships();
    // this.currentUserOrgCode = localStorage.getItem('currentOrganizationCode');
  };

  onChangeOfUser = (org: any) => {
    //Onchange of user, clear the  previous user's details
    localStorage.removeItem('currentOrganizationCode');
    localStorage.removeItem('currentUserRole');
    localStorage.removeItem('permissions');
    //read value from selected dropdown, save new  organisation_code to LS
    this.currentUserOrgCode = org.organisation_code;
    localStorage.setItem('currentOrganizationCode', this.currentUserOrgCode);
    //Call permissions API  to refresh the page with current Organization code
    this.getPermissions();
  };

  getPermissions = () => {
    this.accountSettingsService
      .getPermissions(this.currentUserOrgCode)
      .subscribe({
        next: (resp: any) => {
          localStorage.setItem('permissions', resp.permissions);
          localStorage.setItem('currentUserRole', resp.role);
          window.location.reload();
        },
        error: (error: HttpErrorResponse) => {
          // console.log(error);
        },
      });
  };

  /*** marker io trigger */
  triggerMarkerIo = async () => {
    console.log('marker io triggered');
    const widget = await markerSDK.loadWidget({
      project: '6540dd1bc497eb385cc3b459',
    });
    widget.capture('fullscreen');
  };

  openHelpCenter = () => {
    window.open(
      'https://help.datax.ai/digital-transformation-v3/secrets/N2Y5aGRuY2hybmplY2xqb3',
      '_blank'
    );
  };
}
