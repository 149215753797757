import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './_shared/shared/shared.module';
import { HttpConfigInterceptor } from './_interceptors/http-config.interceptor';
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClientXsrfModule,
} from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TermsOfServiceComponent } from './_components/user-access/terms-of-service/terms-of-service.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { SignupComponent } from './_components/user-access/signup/signup.component';
import { SnackbarComponent } from './_components/reused-components/snackbar/snackbar.component';
import { CommmaSeperatorPipe } from './_pipes/commma-seperator.pipe';
import { SafeContentPipe } from './_pipes/safe-content.pipe';
import { PdfViewerComponent } from './_components/user-access/terms-of-service/pdf-viewer/pdf-viewer.component';
import { NotificationDialogComponent } from './_dialogs/notification-dialog/notification-dialog.component';
import { PasswordStrengthMeterModule } from 'angular-password-strength-meter';
import {
  DEFAULT_PSM_OPTIONS,
  ZXCVBN_CONFIG,
} from 'angular-password-strength-meter/zxcvbn';

import { FileNamePipe } from './_pipes/file-name.pipe';
// Import the module from the SDK
import { AuthModule } from '@auth0/auth0-angular';
import { environment } from '../environments/environment';
import { LoadingComponent } from './_components/loading/loading.component';
import { AuthHttpInterceptor } from '@auth0/auth0-angular';
import { HomeComponent } from './_components/dashboard/home/home.component';
import { AccountSettingsComponent } from './_components/account-settings/account-settings.component';
import { OrganizationInfoComponent } from './_components/account-settings/organization-info/organization-info.component';
import { HeaderRowsComponent } from './_components/dashboard/header-rows/header-rows.component';
import { TopNavComponent } from './_components/navigation/top-nav/top-nav.component';
import { UploadInputFileComponent } from './_components/dashboard/upload-input-file/upload-input-file.component';
import { ExistingFilesComponent } from './_dialogs/existing-files/existing-files.component';
import { ShareDialogComponent } from './_dialogs/share-dialog/share-dialog.component';
import { UploadProgressComponent } from './_components/dashboard/upload-progress/upload-progress.component';
import { ConfirmationDialogComponent } from './_dialogs/confirmation-dialog/confirmation-dialog.component';
// For Recaptcha
import { FormsModule } from '@angular/forms';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { TeamMembersComponent } from './_components/account-settings/team-members/team-members.component';
import { InviteMembersComponent } from './_dialogs/invite-members/invite-members.component';
import { BillingComponent } from './_components/account-settings/billing/billing.component';
import { UsageComponent } from './_components/account-settings/usage/usage.component';
import { ViewOutputNewComponent } from './_components/dashboard/view-output-new/view-output-new.component';
import { ViewOutputComponent } from './_components/dashboard/view-output/view-output.component';
import { MyProfileComponent } from './_components/my-profile/my-profile.component';
import { InterimOutputComponent } from './_components/dashboard/interim-output/interim-output.component';
import { InviteRequestComponent } from './_components/account-settings/invite-request/invite-request.component';
import { NotificationsComponent } from './_components/notifications/notifications.component';
import { SpinnerComponent } from './_components/reused-components/spinner/spinner.component';
import { InboxComponent } from './_components/inbox/inbox.component';
import { ShowInvitesComponent } from './_components/show-invites/show-invites.component';
import { InviteRequestDialogComponent } from './_dialogs/invite-request-dialog/invite-request-dialog/invite-request-dialog.component';
import {
  MAT_LEGACY_TOOLTIP_DEFAULT_OPTIONS as MAT_TOOLTIP_DEFAULT_OPTIONS,
  MatLegacyTooltipDefaultOptions as MatTooltipDefaultOptions,
} from '@angular/material/legacy-tooltip';
import { RemoveUserDialogComponent } from './_dialogs/remove-user-dialog/remove-user-dialog.component';
import { SelectCheckAllComponent } from './_components/dashboard/interim-output/selecte-all-option/select-all-option';
import { ScrollToBottomDirective } from './_directives/scroll-to-bottom.directive';
import { EditTagsComponent } from './_dialogs/edit-tags/edit-tags.component';
import { UpgradePlanComponent } from './_dialogs/upgrade-plan/upgrade-plan.component';
import { CharPreventDirective } from './_directives/char-prevent.directive';
import { NoWhitespaceDirective } from './_directives/no-whitespace.directive';
import { JoinStringsPipe } from './_pipes/join-strings.pipe';
import { VersionHistoryComponent } from './_components/dashboard/view-output-new/version-history/version-history.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { DataTableSkeletonLoaderComponent } from './_components/dashboard/view-output-new/data-table-skeleton-loader/data-table-skeleton-loader.component';
import { AppConfigService } from './_services/app-config.service';
import { ContentOverflowTooltipDirective } from './_directives/content-overflow-tooltip.directive';
import { Auth0ErrorComponent } from './_components/auth0-error/auth0-error-component';
import { BatchDetailsComponent } from './_dialogs/batch-details/batch-details.component';
import { InputDataTableComponent } from './_components/dashboard/view-output-new/input-data-table/input-data-table.component';
import { RecipeSummaryComponent } from './_dialogs/recipe-summary/recipe-summary/recipe-summary.component';
import { MidTruncatePipe } from './_pipes/mid-truncate.pipe';
import { HomeUpdateV1Component } from './_components/dashboard/home-update-v1/home-update-v1.component';
import { MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { HighlightFunctionKeyDirective } from './_directives/highlight-function-key.directive';
import { FunctionQueryListSortingPipe } from './_pipes/function-query-list-sorting.pipe';


export const myCustomTooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 0,
  hideDelay: 0,
  touchendHideDelay: 1500,
  disableTooltipInteractivity: true,
};

export function initConfig(appConfig: AppConfigService) {
  return () => appConfig.loadConfig();
}

@NgModule({
  declarations: [
    AppComponent,
    TermsOfServiceComponent,
    CommmaSeperatorPipe,
    SafeContentPipe,
    SignupComponent,
    SnackbarComponent,
    PdfViewerComponent,
    ShareDialogComponent,
    NotificationDialogComponent,
    LoadingComponent,
    HomeComponent,
    AccountSettingsComponent,
    OrganizationInfoComponent,
    HeaderRowsComponent,
    TopNavComponent,
    UploadInputFileComponent,
    ExistingFilesComponent,
    ShareDialogComponent,
    SpinnerComponent,
    UploadProgressComponent,
    ConfirmationDialogComponent,
    TeamMembersComponent,
    InviteMembersComponent,
    BillingComponent,
    UsageComponent,
    ViewOutputComponent,
    ViewOutputNewComponent,
    MyProfileComponent,
    InterimOutputComponent,
    // EditFormatComponent,
    InviteRequestComponent,
    NotificationsComponent,
    InboxComponent,
    ShowInvitesComponent,
    InviteRequestDialogComponent,
    RemoveUserDialogComponent,
    SelectCheckAllComponent,
    ScrollToBottomDirective,
    EditTagsComponent,
    UpgradePlanComponent,
    CharPreventDirective,
    NoWhitespaceDirective,
    JoinStringsPipe,
    VersionHistoryComponent,
    DataTableSkeletonLoaderComponent,
    ContentOverflowTooltipDirective,
    Auth0ErrorComponent,
    BatchDetailsComponent,
    InputDataTableComponent,
    RecipeSummaryComponent,
    MidTruncatePipe,
    HomeUpdateV1Component,
    HighlightFunctionKeyDirective,
    FunctionQueryListSortingPipe,
  ],
  imports: [
    BrowserModule,
    MatLegacyDialogModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    PdfViewerModule,
    HttpClientModule,
    PasswordStrengthMeterModule.forRoot(DEFAULT_PSM_OPTIONS),
    HttpClientXsrfModule.withOptions({
      cookieName: 'csrftoken',
      headerName: 'x-csrf',
    }),
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    RecaptchaV3Module,
    NgxSkeletonLoaderModule,
    AuthModule.forRoot({
      domain: environment.auth0.domain,
      clientId: environment.auth0.clientId,
      authorizationParams: {
        redirect_uri: environment.auth0.callbackURL,
        audience: environment.auth0.audience,
      },
      httpInterceptor: {
        allowedList: [
          {
            uriMatcher: (uri) => {
              // console.log(uri);

              /* send `bearer token` to all APIs except google storage & signup, account settings - get_invite_details **/
              if (
                uri == '/api/auth/users/' ||
                uri.includes('storage.googleapis.com') ||
                uri.includes('/assets/config/config.json') ||
                new RegExp('/api/auth/organisations/invites/.*$').test(uri)
              ) {
                return false;
              }
              return true;
              // var prohibited = ['/auth/users'];
              // for (var i = 0; i < prohibited.length; i++) {
              //   if (
              //     uri.startsWith('/api/') &&
              //     uri.indexOf(prohibited[i]) == -1
              //   ) {
              //     return true;
              //   }
              // }
              // return false;
              /** hardcoding for time being */
            },
          },
        ],
      },
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true,
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptcha.siteKey,
    },
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: myCustomTooltipDefaults,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initConfig,
      deps: [AppConfigService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
